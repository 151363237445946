// Custom hook to manage inputs... validation, state change, reset...
import { useCallback, useState } from "react";
import { isValidDate } from "../functions/validation";

// expects a validation function (returns boolean value) as a parameter for the specific input field
const useInput = (validateInput, callback) => {
  const [inputValue, setInputValue] = useState("");
  const [inputIsTouched, setInputIsTouched] = useState(false);

  // call the validation function and assign the inputIsValid variable accordingly
  const inputIsValid = validateInput(inputValue);
  // input is invalid if the input is blured and if the validateInput function return false
  const inputIsInValid = !inputIsValid && inputIsTouched;

  const inputChangeHandler = useCallback((event) => {
    if (typeof event === "object") {
      // for dates
      if (isValidDate(event)) {
        setInputValue(event);
      } else {
        // for normal inputs
        setInputValue(
          event?.target?.value || event?.target?.value === ""
            ? event?.target?.value
            : event
        );
      }
    } else if (typeof event === "string" || typeof event === "number") {
      // for treeselect
      setInputValue(event);
    }
  }, []);
  const inputBlurHandler = () => {
    setInputIsTouched(true);
    callback && callback();
  };
  const reset = () => {
    setInputValue("");
    setInputIsTouched(false);
  };
  return {
    value: inputValue,
    isValid: inputIsValid,
    isInvalid: inputIsInValid,
    inputChangeHandler,
    inputBlurHandler,
    reset,
  };
};
export default useInput;
