import React, { useRef } from "react";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import Input from "./Input";
const libraries = ["places"];
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API;
const PlaceComponent = (props) => {
  const searchBoxRef = useRef();
  const inputRef = useRef();
  const handlePlaceChanged = (e) => {
    // console.log(e);
    // console.log(searchBoxRef.current.getPlaces());
    const [place] = searchBoxRef.current.getPlaces();
    if (place) {
      props.onChange(place.formatted_address);
      inputRef.current.value = place.formatted_address;
      props.setCoordinates({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey,
    libraries,
  });

  return (
    isLoaded && (
      <StandaloneSearchBox
        onLoad={(ref) => (searchBoxRef.current = ref)}
        onPlacesChanged={(e) => {
          handlePlaceChanged(e);
        }}
      >
        <Input
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={props.value}
          error={props.error}
          id="location"
          label={props.label}
          helperText={<>{props.helperText}</>}
          placeholder={props.placeholder}
          required
          innerRef={inputRef}
          type="text"
        />
      </StandaloneSearchBox>
    )
  );
};
export default PlaceComponent;
