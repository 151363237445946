import React, { Fragment, useState } from "react";
import IconButton from "./IconButton";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ChatIcon from "@mui/icons-material/Chat";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Link } from "react-router-dom";

const UserCard = ({ client }) => {
  const [loaded, setLoaded] = useState(false);
  console.log(client);
  return (
    <Fragment>
      <img
        style={loaded ? {} : { display: "none" }}
        className="w-24 h-24 rounded-full object-cover"
        src={
          client?.photoURL ||
          "https://cdn.pixabay.com/photo/2017/11/10/05/48/client-2935527_1280.png"
        }
        alt="Client profile"
        onLoad={() => setLoaded(true)}
      />
      {loaded ? null : (
        <div className="w-24 h-24 flex p-2 items-center mt-4 space-x-3">
          <svg
            className="text-gray-200 w-24 h-24 dark:text-gray-700"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
      )}
      <h5 className="my-1 w-full text-center text-xl text-gray-900 dark:text-white">
        {client?.fullName}
      </h5>
      <div className="flex p-2 space-x-2">
        <Link to={`/messaging`} state={{ userId: client.id }}>
          <IconButton
            type="rounded"
            className="border-none bg-light-gray text-primary"
          >
            <ChatIcon />
          </IconButton>
        </Link>
        <Link to={`mailto:${client?.email}`}>
          <IconButton
            type="rounded"
            className="border-none bg-light-gray text-primary"
          >
            <MailOutlineIcon />
          </IconButton>
        </Link>
        <Link to={`tel:${client?.phone}`}>
          <IconButton
            type="rounded"
            className="border-none bg-light-gray text-primary"
          >
            <LocalPhoneIcon />
          </IconButton>
        </Link>
      </div>
    </Fragment>
  );
};

export default UserCard;
