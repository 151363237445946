import React, { useEffect, useState } from "react";
import Input from "../UI/Input";
import Modal from "../UI/Modal";
import Button from "../UI/Button";
import useInput from "../../hooks/use-input";
import { gql, useMutation } from "@apollo/client";
import { useProject } from "../../store/project-context";
import { isObjectEmpty } from "../../functions/validation";
import Dropdown from "../UI/Dropdown";
import Radio from "../UI/Radio";
import moment from "moment";
import { Calendar3 } from "react-bootstrap-icons";
import DatePicker from "../UI/DatePicker";
import Textarea from "../UI/Textarea";

const INSERT_STORAGE = gql`
  mutation MyMutation($object: projects_storage_insert_input!) {
    insert_projects_storage_one(object: $object) {
      id
    }
  }
`;

const UPDATE_STORAGE = gql`
  mutation MyMutation($id: uuid!, $_set: projects_storage_set_input!) {
    update_projects_storage_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;

const NewStorage = (props) => {
  const { currentProject } = useProject();
  const [status, setStatus] = useState("INCOMING");
  const [milestone, setMilestone] = useState(null);
  const [date, setDate] = useState(new Date());
  const {
    allMaterials,
    milestones,
    selectedStorage,
    editMode,
    setError,
    setSuccess,
    refetch,
  } = props;
  console.log(allMaterials);
  console.log(milestones);
  // const resources = data?.projects_projects_by_pk;

  const [
    updateStorage,
    { loading: isStorageUpdating, error: updateStorageError },
  ] = useMutation(UPDATE_STORAGE);
  const [
    addStorage,
    { loading: isStorageSubmitting, error: insertStorageError },
  ] = useMutation(INSERT_STORAGE);

  const {
    value: material,
    isValid: materialIsValid,
    // isInvalid: materialIsInValid,
    inputChangeHandler: materialChangeHandler,
    inputBlurHandler: materialBlurHandler,
    reset: resetMaterial,
  } = useInput((value) => !isObjectEmpty(value));

  const {
    value: quantity,
    isValid: quantityIsValid,
    // isInvalid: quantityIsInValid,
    inputChangeHandler: quantityChangeHandler,
    inputBlurHandler: quantityBlurHandler,
    reset: resetQuantity,
  } = useInput((value) => value !== "");

  const {
    value: unitPrice,
    isValid: unitPriceIsValid,
    isInvalid: unitPriceIsInValid,
    inputChangeHandler: unitPriceChangeHandler,
    inputBlurHandler: unitPriceBlurHandler,
    reset: resetUnitPrice,
  } = useInput((value) => value !== "");

  const { value: notes, inputChangeHandler: notesChangeHandler } = useInput(
    (value) => value !== ""
  );

  let formIsValid = false;
  if (status === "INCOMING") {
    formIsValid = materialIsValid && quantityIsValid && unitPriceIsValid;
  } else {
    formIsValid = materialIsValid && quantityIsValid && milestone;
  }

  const submitHandler = async (e) => {
    if (!currentProject?.id) {
      setError("No project selected");
      return;
    }
    let storageData = {};
    if (status === "INCOMING") {
      storageData = {
        materialId: material.id,
        quantity,
        date,
        unitPrice,
        status,
        original_quantity: quantity,
        notes,
        projectId: currentProject.id,
      };
    } else if (status === "OUTGOING") {
      storageData = {
        materialId: material.id,
        quantity,
        date,
        unitPrice: 0,
        status,
        milestoneId: milestone.id,
        original_quantity: quantity,
        notes,
        projectId: currentProject.id,
      };
    } else {
      return;
    }
    try {
      if (selectedStorage) {
        await updateStorage({
          variables: {
            id: selectedStorage.id,
            _set: storageData,
          },
        });
      } else {
        await addStorage({
          variables: { object: storageData },
        });
      }
      console.log(insertStorageError, editMode);
      if (!insertStorageError && !editMode) {
        setSuccess("Item added to storage successfully");
      } else if (!updateStorageError) {
        setSuccess("Storage updated successfully");
      }
      hideModal();
      refetch();
    } catch (e) {
      setError(e?.message);
      console.log(e);
    }
  };
  useEffect(() => {
    if (selectedStorage && editMode) {
      console.log(selectedStorage);
      materialChangeHandler(selectedStorage?.material);
      quantityChangeHandler(selectedStorage?.quantity);
      unitPriceChangeHandler(selectedStorage?.unitPrice);
    }
  }, [
    editMode,
    selectedStorage,
    materialChangeHandler,
    quantityChangeHandler,
    unitPriceChangeHandler,
  ]);
  const milestoneChangeHandler = (item) => {
    // if (selectedMilestones.indexOf(item) !== -1) {
    //   return;
    // }
    setMilestone(item);
  };
  const hideModal = () => {
    resetMaterial();
    resetQuantity();
    resetUnitPrice();
    props.hideModal();
  };
  const dateChangeHandler = (date) => {
    const now = new Date();
    date.setHours(now.getHours());
    date.setMinutes(now.getMinutes());
    setDate(date);
  };
  console.log(props.isShown);
  if (props.isShown) {
    return (
      <>
        <Modal
          headerIsShown={true}
          modalHeight="h-[700px]"
          isShown={true} //props.isShown
          hideModal={hideModal}
          modalTitle={editMode ? "Edit storage" : "Add item to storage"}
          modalBottom={
            <div className="flex justify-end mr-4">
              <Button className="mr-3" type="light" onClick={hideModal}>
                Cancel
              </Button>
              <Button
                disabled={!formIsValid}
                isLoading={isStorageSubmitting || isStorageUpdating}
                onClick={submitHandler}
                type="submit"
              >
                {editMode ? "Update" : "Save"}
              </Button>
            </div>
          }
        >
          <p className="text-dark-gray text-sm ">
            {editMode
              ? "Edit this storage"
              : "Add storages into this project and use them to track your phases"}
          </p>
          <form className="space-y-3 mt-5 flex flex-col justify-between h-fit">
            <div className="flex space-x-4">
              <Radio
                checked={status === "INCOMING"}
                id="ch_incoming"
                label="Incoming"
                onChange={() => setStatus("INCOMING")}
              />
              <Radio
                checked={status === "OUTGOING"}
                id="ch_outgoing"
                label="Outgoing"
                onChange={() => setStatus("OUTGOING")}
              />
            </div>
            <DatePicker
              value={date}
              bgColor="bg-gray-100"
              className="w-full pb-5 border-b"
              icon={<Calendar3 />}
              onChange={dateChangeHandler}
              dateFormat="dd/MM/yyyy"
              id="start-date"
              placeholderText={moment().format("L")}
            />
            <Dropdown
              selectHandler={materialChangeHandler}
              onBlur={materialBlurHandler}
              value={material.name}
              // error={materialIsInValid}
              label="Material name"
              helperText={<>Material is required</>}
              placeholder="Eg. Cement"
              items={allMaterials}
              propertyName="name"
              required={true}
              name="material"
            />
            <Input
              value={quantity}
              onChange={quantityChangeHandler}
              onBlur={quantityBlurHandler}
              // error={quantityIsInValid}
              helperText={<>Quantity is required</>}
              label="Quantity"
              placeholder="15"
              type="number"
            />
            {status === "INCOMING" ? (
              <Input
                value={unitPrice}
                onChange={unitPriceChangeHandler}
                onBlur={unitPriceBlurHandler}
                error={unitPriceIsInValid}
                helperText={<>Unit price is required</>}
                label="Unit price"
                name="unit-price"
                placeholder="Eg. 1000"
                type="number"
              />
            ) : (
              <Dropdown
                className="w-full"
                selectHandler={milestoneChangeHandler}
                // onBlur={phaseBlurHandler}
                value={milestone?.name}
                // error={getMilestonesError}
                label="Milestone"
                helperText={<>Milestone is required</>}
                // loading={getMilestonesLoading}
                // errorMessage={getMilestonesError?.message}
                items={milestones}
                propertyName="name"
              />
            )}
            <Textarea
              label="Notes(optional)"
              onChange={notesChangeHandler}
              value={notes}
              placeholder="Additional notes..."
            />
          </form>
        </Modal>
      </>
    );
  } else {
    return <></>;
  }
};

export default NewStorage;
