import { gql, useSubscription } from "@apollo/client";
import { createContext, useReducer } from "react";
import { useAuthUser } from "react-auth-kit";

const GET_MESSAGES_COUNT = gql`
  subscription MyQuery($user_id: uuid!) {
    users_user_chats_aggregate(
      where: {
        _and: [{ seen: { _eq: false } }, { reciever_id: { _eq: $user_id } }]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
export const ChatContext = createContext();

export const ChatContextProvider = ({ children }) => {
  const auth = useAuthUser();
  const user = auth();
  const INITIAL_STATE = {
    combined_id: "null",
    user: {},
  };

  const { data: userChats, loading: userChatsLoading } = useSubscription(
    GET_MESSAGES_COUNT,
    {
      variables: {
        user_id: user.id,
      },
    }
  );

  const chatReducer = (state, action) => {
    switch (action.type) {
      case "CHANGE_USER":
        return {
          user: action.payload,
          combined_id:
            user.id > action.payload.id
              ? user.id + action.payload.id
              : action.payload.id + user.id,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(chatReducer, INITIAL_STATE);

  return (
    <ChatContext.Provider
      value={{
        data: state,
        dispatch,
        message_count:
          !userChatsLoading &&
          userChats.users_user_chats_aggregate.aggregate.count,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
