import React, { useEffect } from "react";
import Input from "../UI/Input";
import Modal from "../UI/Modal";
import Button from "../UI/Button";
import useInput from "../../hooks/use-input";
import { gql, useMutation } from "@apollo/client";
import { useProject } from "../../store/project-context";
import { useAuthUser } from "react-auth-kit";
// import Dropdown from "../UI/Dropdown";

const INSERT_LABOR = gql`
  mutation MyMutation($object: projects_labor_insert_input!) {
    insert_projects_labor_one(object: $object) {
      id
    }
  }
`;

const UPDATE_LABOR = gql`
  mutation MyMutation($id: uuid!, $_set: projects_labor_set_input!) {
    update_projects_labor_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;

const NewLabor = (props) => {
  const { currentProject, company } = useProject();
  const { selectedLabor, editMode, setError, setSuccess, refetch } = props;

  const [updateLabor, { loading: isLaborUpdating, error: updateLaborError }] =
    useMutation(UPDATE_LABOR);
  const [addLabor, { loading: isLaborSubmitting, error: insertLaborError }] =
    useMutation(INSERT_LABOR);
  const {
    value: type,
    isValid: typeIsValid,
    isInvalid: typeIsInValid,
    inputChangeHandler: typeChangeHandler,
    inputBlurHandler: typeBlurHandler,
    reset: resetType,
  } = useInput((value) => value.trim() !== "");

  const {
    value: payPerWorker,
    isValid: payPerWorkerIsValid,
    isInvalid: payPerWorkerIsInValid,
    inputChangeHandler: payPerWorkerChangeHandler,
    inputBlurHandler: payPerWorkerBlurHandler,
    reset: resetpayPerWorker,
  } = useInput((value) => value !== "");

  // const {
  //   value: payPerWorkerPrice,
  //   isValid: payPerWorkerPriceIsValid,
  //   isInvalid: payPerWorkerPriceIsInValid,
  //   inputChangeHandler: payPerWorkerPriceChangeHandler,
  //   inputBlurHandler: payPerWorkerPriceBlurHandler,
  //   reset: resetpayPerWorkerPrice,
  // } = useInput((value) => value.trim() !== "");

  const formIsValid = typeIsValid && payPerWorkerIsValid;

  const auth = useAuthUser();
  const user = auth();
  const submitHandler = async (e) => {
    if (!currentProject?.id) {
      setError("No project selected");
      return;
    }
    let laborData = {};
    if (user?.roles?.map((role) => role.code).includes("SU")) {
      laborData = {
        type,
        payPerWorker,
      };
    } else {
      laborData = {
        type,
        payPerWorker,
        companyId: company.id,
      };
    }
    try {
      if (selectedLabor) {
        await updateLabor({
          variables: {
            id: selectedLabor.id,
            _set: laborData,
          },
        });
      } else {
        const response = await addLabor({
          variables: { object: laborData },
        });
      }
      if (!insertLaborError && !editMode) {
        setSuccess("Labor added successfully");
      } else if (!updateLaborError) {
        setSuccess("Labor updated successfully");
      }
      hideModal();
      refetch();
    } catch (e) {
      setError(e?.message);
      console.log(e);
    }
  };
  useEffect(() => {
    if (selectedLabor && editMode) {
      typeChangeHandler(selectedLabor?.type);
      payPerWorkerChangeHandler(selectedLabor?.payPerWorker);
      // payPerWorkerPriceChangeHandler(new Date(selectedLabor?.payPerWorkerPrice));
    }
  }, [editMode, selectedLabor, typeChangeHandler, payPerWorkerChangeHandler]);
  const hideModal = () => {
    resetType();
    resetpayPerWorker();
    props.hideModal();
  };
  if (props.isShown) {
    return (
      <>
        <Modal
          headerIsShown={true}
          modalHeight="h-[400px]"
          isShown={true} //props.isShown
          hideModal={hideModal}
          modalTitle={editMode ? "Edit Labor" : "Create New Labor"}
          modalBottom={
            <div className="flex justify-end mr-4">
              <Button className="mr-3" type="light" onClick={hideModal}>
                Cancel
              </Button>
              <Button
                disabled={!formIsValid}
                isLoading={isLaborSubmitting || isLaborUpdating}
                onClick={submitHandler}
                type="submit"
              >
                {editMode ? "Update" : "Save"}
              </Button>
            </div>
          }
        >
          <p className="text-dark-gray text-sm ">
            {editMode
              ? "Edit this labor"
              : "Add Labor into this project and use them to track your phases"}
          </p>
          <form className="space-y-3 mt-5 flex flex-col justify-between h-fit">
            <Input
              onChange={typeChangeHandler}
              onBlur={typeBlurHandler}
              value={type}
              error={typeIsInValid}
              label="Labor type"
              helperText={<>Labor type is required</>}
              placeholder="Eg. Electrician"
              required={true}
              name="type"
              type="text"
            />
            <Input
              value={payPerWorker}
              onChange={payPerWorkerChangeHandler}
              onBlur={payPerWorkerBlurHandler}
              error={payPerWorkerIsInValid}
              helperText={<>Pay per worker is required</>}
              type="number"
              label="Pay per worker"
              placeholder="Eg. 1000"
            />
          </form>
        </Modal>
      </>
    );
  } else {
    return <></>;
  }
};

export default NewLabor;
