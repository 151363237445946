import React, { useContext, useEffect, useState } from "react";

const PhaseContext = React.createContext();

export function usePhase() {
  return useContext(PhaseContext);
}

export function PhaseContextProvider(props) {
  const [phaseData, setPhaseData] = useState(null);
  const [totalCost, setTotalCost] = useState(0);

  const calculateTotalCost = (data) => {
    let materialCost = 0,
      equipmentCost = 0,
      laborCost = 0;
    console.log("data");
    console.log(data);

    data.materials?.map((material) => {
      materialCost += parseInt(material.quantity) * material.unitPrice;
    });
    data.equipment?.map((equipment) => {
      if (equipment.type === "RENTED") {
        equipmentCost +=
          parseInt(equipment.noOfHours) *
          parseInt(equipment.quantity) *
          equipment.unitPrice;
      } else if (equipment.type === "PURCHASED") {
        equipmentCost += parseInt(equipment.quantity) * equipment.unitPrice;
      }
    });
    data.labor?.map((labor) => {
      laborCost +=
        parseInt(labor.noOfHours) * parseInt(labor.quantity) * labor.unitPrice;
    });
    const total = materialCost + equipmentCost + laborCost;
    console.log("PREVIOUS COST:" + totalCost);
    console.log("TOTAL COST:" + total);

    setTotalCost(total);
  };
  useEffect(() => {
    if (phaseData) {
      calculateTotalCost(phaseData);
    }
    // else {
    //   setTotalCost(0);
    // }
  }, [phaseData]);
  const storePhaseData = (newData) => {
    console.log("STORING...");
    console.log(newData);
    setPhaseData({
      ...phaseData,
      ...newData,
    });
  };

  const contextValue = {
    totalCost: totalCost.toLocaleString(),
    calculateTotalCost,
    phaseData,
    setPhaseData,
    storePhaseData,
    setTotalCost,
  };
  return (
    <PhaseContext.Provider value={contextValue}>
      {props.children}
    </PhaseContext.Provider>
  );
}

export default PhaseContext;
