import React, { Fragment, useRef, useState } from "react";
import Button from "../components/UI/Button";
import AddIcon from "@mui/icons-material/Add";
import { Tabs, Tab } from "../components/UI/Tabs";
import Card from "../components/UI/Card";
import CompanyCard from "../components/UI/CompanyCard";
import NewCompany from "../components/Forms/NewCompany";
import Table from "../components/UI/Table";
import ListSkeleton from "../components/UI/skeleton/ListSkeleton";
import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import { Warning } from "@mui/icons-material";
import Toast from "../components/UI/Toast";
// import { useUser } from "../store/user-context";

const GET_COMPANIES = gql`
  query MyQuery {
    companies(order_by: { created_at: desc }) {
      name
      logo
      id
      created_at
      sites_aggregate {
        aggregate {
          count
        }
      }
      admin: users(
        where: { user_roles: { role: { code: { _eq: "ADM" } } } }
        order_by: { created_at: asc }
        limit: 1
      ) {
        fullName
        email
        phone
        photoURL
      }
    }
  }
`;

const CompaniesPage = () => {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [newCompanyModalShown, setNewCompanyModalShown] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const cardRef = useRef();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  // const users = useUser();

  const {
    data,
    loading: companiesLoading,
    error: companiesError,
    refetch,
  } = useQuery(GET_COMPANIES);
  const selectCompany = (id) => {
    if (id === null) {
      setSelectedCompany(null);
      return;
    }
    data?.companies.map((company) => {
      if (company.id === id) {
        setSelectedCompany(company);
        return company;
      } else {
        return;
      }
    });
    cardRef.current && cardRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const headers = ["Name", "Registered at", "Sites", "Admin"];

  const hideModal = () => {
    setSelectedCompany(null);
    setEditMode(false);
    setNewCompanyModalShown(false);
  };
  const tableData = data?.companies.map((company) => {
    return {
      id: company.id,
      name: company.name,
      created_at: moment(company.created_at).format("LL"),
      sites: company.sites_aggregate.aggregate.count,
      admin: company.admin[0]?.fullName || "-",
    };
  });
  return (
    <div ref={cardRef} className="m-3 grid grid-cols-12">
      <Tabs
        className={`${selectedCompany ? "col-span-9" : "col-span-12"}`}
        otherElements={
          <Button
            onClick={() => {
              setNewCompanyModalShown(true);
              setSelectedCompany(null);
            }}
          >
            <AddIcon />
            Add New Company
          </Button>
        }
      >
        <Tab label="Companies">
          <div className="">
            {companiesLoading && !companiesError ? (
              <ListSkeleton />
            ) : companiesError ? (
              <p className="flex justify-center items-center">
                <Warning className="!w-4 !h-4 mr-1" />
                {companiesError?.message}
              </p>
            ) : (
              <Table
                title={`Companies(${tableData?.length || 0})`}
                search={true}
                searchList={tableData}
                searchPropertiesKey="name"
                searchPlaceholder="Search a company"
                // delete={true}
                // checkbox={true}
                // showDeleteManyConfirmation={showDeleteManyConfirmation}
                checkboxClickHandler={selectCompany}
                headers={headers}
                rows={tableData}
                errorMessage={
                  companiesError && (
                    <p className="flex justify-center items-center">
                      <Warning className="!w-4 !h-4 mr-1" />
                      {companiesError?.message}
                    </p>
                  )
                }
              />
            )}
          </div>
        </Tab>
        <Tab label=""></Tab>
      </Tabs>
      <Card
        // cardRef={cardRef}
        className={`sticky vertical-scrollbar top-0 right-0 flex flex-col items-center overflow-x-hidden overflow-y-auto max-h-screen ml-3 space-y-3 transition-all duration-200 ${
          selectedCompany
            ? "translate-x-0 col-span-3"
            : "translate-x-full !bg-transparent"
        }`}
      >
        {selectedCompany ? (
          <Fragment>
            <h1 className="text-start w-full text-lg font-medium">
              Company details
            </h1>
            <CompanyCard company={selectedCompany} />
            <ul className="w-full text-xs space-y-3 p-2">
              <li className="flex justify-between">
                <span className="text-dark-gray">Date registered</span>
                <span>{moment(selectedCompany.created_at).format("LL")}</span>
              </li>
              <li className="flex justify-between">
                <span className="text-dark-gray">Number of sites</span>
                <span>{selectedCompany.sites_aggregate.aggregate.count}</span>
              </li>

              {selectedCompany.admin.length > 0 && (
                <>
                  <h4 className="text-gray-700 text-sm font-semibold">
                    Company Admin Info
                  </h4>
                  <li className="flex justify-between">
                    <span className="text-dark-gray">Name</span>
                    <span>{selectedCompany.admin[0].fullName}</span>
                  </li>
                  <li className="flex justify-between">
                    <span className="text-dark-gray">Email</span>
                    <span>{selectedCompany.admin[0].email}</span>
                  </li>
                  <li className="flex justify-between">
                    <span className="text-dark-gray">Phone</span>
                    <span>{selectedCompany.admin[0].phone}</span>
                  </li>
                </>
              )}
              {/* <li className="flex justify-between">
                <span className="text-dark-gray">Title</span>
                <span>
                  {selectedCompany.roles.map((el) => el.name).join(", ")}
                </span>
              </li> */}
              <div className="flex flex-col space-y-2 ">
                <Button
                  className="shadow-sm hover:shadow-lg !bg-green-100/60 hover:!bg-green-200/80 !text-green-600 focus:!ring-0"
                  onClick={() => {
                    setNewCompanyModalShown(true);
                    setEditMode(true);
                  }}
                >
                  Edit company
                </Button>
                {/* <Button
                  onClick={showDeleteOneConfirmation}
                  className="shadow-sm hover:shadow-lg !bg-red-100/60 hover:!bg-red-200/80 !text-dark-red focus:!ring-0"
                >
                  Delete company
                </Button> */}
              </div>
            </ul>
          </Fragment>
        ) : (
          <Fragment>
            {/* <img src={userDetails} alt="User details illustration" />
            <p className="text-dark-gray text-sm">
              Select a user to see details
            </p> */}
          </Fragment>
        )}
      </Card>
      {newCompanyModalShown && (
        <NewCompany
          refetch={refetch}
          setSuccess={setSuccess}
          setError={setError}
          isShown={newCompanyModalShown}
          hideModal={hideModal}
          selectedCompany={selectedCompany}
          editMode={editMode}
        />
      )}
      <Toast
        type="error"
        show={error !== null}
        callback={() => {
          setError(null);
        }}
        message={error}
      />
      <Toast
        type="success"
        show={success !== null}
        callback={() => {
          setSuccess(null);
        }}
        message={success}
      />
    </div>
  );
};

export default CompaniesPage;
