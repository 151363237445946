import React, { useEffect, useState } from "react";
import { ChevronRight } from "react-bootstrap-icons";
import { Link, useLocation } from "react-router-dom";
import Stepper from "../../components/UI/Stepper";
import Button from "../../components/UI/Button";
import NameAndDate from "../../components/Forms/Phases/NameAndDate";
import QuantifiableTasks from "../../components/Forms/Phases/QuantifiableTasks";
import { useNavigate } from "react-router-dom";
import { isValidDate } from "../../functions/validation";
import useInput from "../../hooks/use-input";
import Materials from "../../components/Forms/Phases/Materials";
import Equipments from "../../components/Forms/Phases/Equipments";
import Labor from "../../components/Forms/Phases/Labor";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useProject } from "../../store/project-context";
import Toast from "../../components/UI/Toast";
import Tests from "../../components/Forms/Phases/Tests";
import InfoIcon from "@mui/icons-material/Info";
import NoProject from "../../components/UI/NoProject";
import { usePhase } from "../../store/phase-context";
import { useAuthUser } from "react-auth-kit";
// import moment from "moment";

const GET_RESOURCES = gql`
  query MyQuery($companyId: uuid!) {
    projects_materials(
      where: {
        _or: [
          { companyId: { _eq: $companyId } }
          { companyId: { _is_null: true } }
        ]
      }
      order_by: { name: asc }
    ) {
      milestone_materials {
        quantity
      }
      name
      id
      unitPrice
      unit
      storages {
        unitPrice
        quantity
      }
    }
    projects_equipments(
      where: {
        _or: [
          { companyId: { _eq: $companyId } }
          { companyId: { _is_null: true } }
        ]
      }
      order_by: { name: asc }
    ) {
      name
      unit
      unitPrice
      status
      id
      milestone_equipments {
        quantity
      }
    }
    projects_labor(
      where: {
        _or: [
          { companyId: { _eq: $companyId } }
          { companyId: { _is_null: true } }
        ]
      }
      order_by: { type: asc }
    ) {
      id
      milestone_labors {
        quantity
      }
      payPerWorker
      type
    }
  }
`;
const GET_RESOURCES_ALL = gql`
  query MyQuery {
    projects_materials(
      where: { companyId: { _is_null: true } }
      order_by: { name: asc }
    ) {
      milestone_materials {
        quantity
      }
      name
      id
      unitPrice
      unit
      companyId
      storages {
        quantity
      }
    }
    projects_equipments(
      where: { companyId: { _is_null: true } }
      order_by: { name: asc }
    ) {
      name
      unit
      unitPrice
      status
      id
      companyId
      milestone_equipments {
        noOfHours
        quantity
      }
    }
    projects_labor(
      where: { companyId: { _is_null: true } }
      order_by: { type: asc }
    ) {
      id
      companyId
      milestone_labors {
        quantity
      }
      payPerWorker
      type
    }
  }
`;
const GET_COMMON_TASKS = gql`
  query MyQuery {
    projects_common_tasks(where: {}, order_by: { name: asc }) {
      id
      name
      unitRate
      unit
    }
  }
`;
const INSERT_MILESTONE = gql`
  mutation MyMutation($object: projects_milestones_insert_input!) {
    insert_projects_milestones_one(object: $object) {
      id
    }
  }
`;
const INSERT_TASKS_MATERIALS_EQUIPMENTS_LABOR_TESTS = gql`
  mutation MyMutation(
    $tasks: [projects_quantifiable_tasks_insert_input!]!
    $materials: [projects_milestone_materials_insert_input!]!
    $equipments: [projects_milestone_equipment_insert_input!]!
    $labor: [projects_milestone_labor_insert_input!]!
    $checklist: [projects_checklist_insert_input!]!
    $tests: [projects_tests_insert_input!]!
  ) {
    insert_projects_quantifiable_tasks(objects: $tasks) {
      affected_rows
    }
    insert_projects_milestone_materials(objects: $materials) {
      affected_rows
    }
    insert_projects_milestone_equipment(objects: $equipments) {
      affected_rows
    }
    insert_projects_milestone_labor(objects: $labor) {
      affected_rows
    }
    insert_projects_checklist(objects: $checklist) {
      affected_rows
    }
    insert_projects_tests(objects: $tests) {
      affected_rows
    }
  }
`;

const UPDATE_MILESTONE = gql`
  mutation myMutation(
    $milestoneId: uuid!
    $_set_milestones: projects_milestones_set_input!
    $checklist_objects: [projects_checklist_insert_input!]!
    $quantifiable_tasks_objects: [projects_quantifiable_tasks_insert_input!]!
    $materials_objects: [projects_milestone_materials_insert_input!]!
    $equipments_objects: [projects_milestone_equipment_insert_input!]!
    $labor_objects: [projects_milestone_labor_insert_input!]!
    $tests_objects: [projects_tests_insert_input!]!
    $deletedChecklist: [uuid!]!
    $deletedTasks: [uuid!]!
    $deletedMaterials: [uuid!]!
    $deletedEquipments: [uuid!]!
    $deletedLabor: [uuid!]!
    $deletedTests: [uuid!]!
  ) {
    update_projects_milestones_by_pk(
      pk_columns: { id: $milestoneId }
      _set: $_set_milestones
    ) {
      id
    }
    insert_projects_checklist(
      objects: $checklist_objects
      on_conflict: {
        constraint: checklist_pkey
        update_columns: [title]
        where: { milestoneId: { _eq: $milestoneId } }
      }
    ) {
      affected_rows
    }
    insert_projects_quantifiable_tasks(
      objects: $quantifiable_tasks_objects
      on_conflict: {
        constraint: quantifiable_tasks_pkey
        update_columns: [name, unit, quantity, unitRate]
        where: { milestoneId: { _eq: $milestoneId } }
      }
    ) {
      affected_rows
    }
    insert_projects_milestone_materials(
      objects: $materials_objects
      on_conflict: {
        constraint: milestone_materials_pkey
        update_columns: [materialId, quantity]
        where: { milestoneId: { _eq: $milestoneId } }
      }
    ) {
      affected_rows
    }
    insert_projects_milestone_equipment(
      objects: $equipments_objects
      on_conflict: {
        constraint: milestone_equipments_pkey
        update_columns: [equipmentId, quantity, type, noOfHours, unitPrice]
        where: { milestoneId: { _eq: $milestoneId } }
      }
    ) {
      affected_rows
    }
    insert_projects_milestone_labor(
      objects: $labor_objects
      on_conflict: {
        constraint: milestone_labor_pkey
        update_columns: [laborId, quantity, unitPrice, noOfHours]
        where: { milestoneId: { _eq: $milestoneId } }
      }
    ) {
      affected_rows
    }
    insert_projects_tests(
      objects: $tests_objects
      on_conflict: {
        constraint: tests_pkey
        update_columns: [name, isChecked]
        where: { milestoneId: { _eq: $milestoneId } }
      }
    ) {
      affected_rows
    }
    delete_projects_checklist(where: { id: { _in: $deletedChecklist } }) {
      affected_rows
    }
    delete_projects_quantifiable_tasks(where: { id: { _in: $deletedTasks } }) {
      affected_rows
    }
    delete_projects_milestone_materials(
      where: { id: { _in: $deletedMaterials } }
    ) {
      affected_rows
    }
    delete_projects_milestone_equipment(
      where: { id: { _in: $deletedEquipments } }
    ) {
      affected_rows
    }
    delete_projects_milestone_labor(where: { id: { _in: $deletedLabor } }) {
      affected_rows
    }
    delete_projects_tests(where: { id: { _in: $deletedTests } }) {
      affected_rows
    }
  }
`;

const DELETE_MILESTONE = gql`
  mutation MyMutation($milestoneId: uuid!) {
    delete_projects_milestones_by_pk(id: $milestoneId) {
      id
    }
  }
`;
const NewPhase = () => {
  const [
    insertMilestone,
    { loading: isMilestoneSubmitting, error: insertMilestoneError },
  ] = useMutation(INSERT_MILESTONE);
  const [insertRest, { error: insertRestError }] = useMutation(
    INSERT_TASKS_MATERIALS_EQUIPMENTS_LABOR_TESTS
  );
  const [
    updateMilestone,
    { loading: isMilestoneUpdating, error: updateMilestoneError },
  ] = useMutation(UPDATE_MILESTONE);
  const [
    deleteMilestone,
    // { loading: isMilestoneDelete, error: deleteMilestoneError },
  ] = useMutation(DELETE_MILESTONE);

  const { currentProject, refetchProjects, company } = useProject();

  const { data: commonTasks } = useQuery(GET_COMMON_TASKS);

  const auth = useAuthUser();
  const user = auth();
  const isSuperAdmin = user?.roles?.map((role) => role.code).includes("SU");
  let variables = {};
  const [getResources, { data }] = useLazyQuery(
    isSuperAdmin ? GET_RESOURCES_ALL : GET_RESOURCES
  );
  useEffect(() => {
    if (!isSuperAdmin && company?.id) {
      console.log(company.id);
      variables = {
        companyId: company?.id,
      };
      getResources({ variables });
    }
    if (isSuperAdmin) {
      getResources();
    }
  }, [company]);

  const resources_materials = data?.projects_materials;
  const resources_equipment = data?.projects_equipments;
  const resources_labor = data?.projects_labor;
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    phaseData,
    setPhaseData,
    storePhaseData,
    calculateTotalCost,
    totalCost,
    setTotalCost,
  } = usePhase();
  const [step, setStep] = useState(1);
  const [steps, setSteps] = useState([
    {
      name: "Name and date",
      isValid:
        !!state?.editMode ||
        (phaseData?.name &&
          phaseData?.startDate &&
          phaseData?.endDate &&
          phaseData?.checklist?.length > 0),
    },
    {
      name: "Quantifiable tasks",
      isValid: !!state?.editMode || phaseData?.tasks?.length > 0,
    },
    {
      name: "Materials",
      isValid: true,
      // !!state?.editMode || phaseData?.materials?.length > 0,
    },
    {
      name: "Equipment",
      isValid: true,
      // !!state?.editMode || phaseData?.equipment?.length > 0,
    },
    {
      name: "Labor",
      isValid: true,
      // !!state?.editMode || phaseData?.labor?.length > 0,
    },
    {
      name: "Tests",
      isValid: true,
      // !!state?.editMode || phaseData?.tests?.length > 0,
    },
  ]);

  const [tasks, setTasks] = useState([]);
  const [milestone, setMilestone] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [checklist, setChecklist] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [tests, setTests] = useState([]);
  const [labor, setLabor] = useState([]);
  const [error, setError] = useState(null);
  const [deletedChecklist, setDeletedChecklist] = useState([]);
  const [deletedMaterials, setDeletedMaterials] = useState([]);
  const [deletedEquipments, setDeletedEquipments] = useState([]);
  const [deletedTasks, setdeletedTasks] = useState([]);
  const [deletedTests, setDeletedTests] = useState([]);
  const [deletedLabor, setDeletedLabor] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [changesMade, setChangesMade] = useState(false);
  const onNameBlurCallback = () => {
    if (!editMode) {
      storePhaseData({
        name: name.value,
      });
    }
  };
  const name = useInput((value) => value.trim() !== "", onNameBlurCallback);
  const startDate = useInput((value) => isValidDate(value));
  const endDate = useInput((value) => isValidDate(value));
  const handleBeforeUnload = (event) => {
    // Perform actions before the component unloads
    event.preventDefault();
    event.returnValue = "";
  };
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleBeforeUnload]);
  useEffect(() => {
    if (state) {
      const { editMode, milestone } = state;
      console.log(milestone);
      setEditMode(editMode);
      setMilestone(milestone);
    }
    console.log("phaseData");
    console.log(phaseData);
    if (editMode) {
      // setPhaseData(null);
      name.inputChangeHandler(milestone.name);
      startDate.inputChangeHandler(milestone.start);
      endDate.inputChangeHandler(milestone.end);
      setChecklist(milestone.checklists);
      setMaterials(milestone.materials);
      setEquipments(milestone.equipments);
      setTasks(milestone.quantifiable_tasks);
      setLabor(milestone.labors);
      setTests(milestone.tests);
      calculateTotalCost({
        materials: milestone.materials,
        equipment: milestone.equipments,
        labor: milestone.labors,
      });
    } else if (phaseData) {
      name.inputChangeHandler(phaseData.name);
      startDate.inputChangeHandler(phaseData.startDate);
      endDate.inputChangeHandler(phaseData.endDate);
      setChecklist(phaseData.checklist || checklist);
      setMaterials(phaseData.materials || materials);
      setEquipments(phaseData.equipment || equipments);
      setTasks(phaseData.tasks || tasks);
      setLabor(phaseData.labor || labor);
      setTests(phaseData.tests || tests);
      calculateTotalCost(phaseData);
    }
  }, [milestone, editMode, phaseData]);
  // useEffect(() => {
  //   if (editMode) {
  //     setPhaseData({
  //       materials: milestone.materials,
  //       equipment: milestone.equipments,
  //       labor: milestone.labors,
  //     });
  //   }
  // }, [editMode]);
  const handleSaveMilestone = async () => {
    // console.log("equipments");
    // console.log(equipments);
    // return;
    if (!currentProject?.id) {
      setError("No project selected");
      return;
    }
    if (!name.value) {
      setError("Please enter a valid name for the milestone");
      return;
    }
    if (!startDate.value) {
      setError("Please enter a start date for the milestone");
      return;
    }
    if (!endDate.value) {
      setError("Please enter an end date for the milestone");
      return;
    }
    if (new Date(startDate.value) > new Date(endDate.value)) {
      setError("Milestone end date must be later than the start date");
      return;
    }
    if (new Date(startDate.value) < new Date(currentProject.startDate)) {
      setError("Milestone should start after project's start date");
      return;
    }
    if (new Date(endDate.value) > new Date(currentProject.endDate)) {
      setError("Milestone should end before project's end date");
      return;
    }
    if (!checklist.length) {
      setError("Please one or more checklist for the milestone");
      return;
    }
    if (!tasks.length) {
      setError("Please enter one or more tasks for the milestone");
      return;
    }
    // if (!materials.length) {
    //   setError("Please one or more materials for the milestone");
    //   return;
    // }
    // if (!equipments.length) {
    //   setError("Please enter one or more equipment for the milestone");
    //   return;
    // }
    // if (!labor.length) {
    //   setError("Please one or more labor for the milestone");
    //   return;
    // }
    setLoading(true);
    console.log(startDate.value);
    const date = new Date();
    let start = new Date(startDate.value);
    let end = new Date(endDate.value);
    start.setHours(date.getHours());
    start.setMinutes(date.getMinutes());
    end.setHours(date.getHours());
    end.setMinutes(date.getMinutes());
    try {
      if (editMode) {
        const phaseData = {
          name: name.value,
          startDate: start,
          endDate: end,
        };
        console.log({
          deletedMaterials,
          deletedLabor,
        });
        const response = await updateMilestone({
          variables: {
            milestoneId: milestone.milestoneId,
            _set_milestones: phaseData,
            checklist_objects: checklist.map((el) => {
              return { ...el, milestoneId: milestone.milestoneId };
            }),
            quantifiable_tasks_objects: tasks.map((el) => {
              return { ...el, milestoneId: milestone.milestoneId };
            }),
            equipments_objects: equipments.map((el) => {
              return {
                id: el.id,
                equipmentId: el.equipment.id,
                unitPrice: el.unitPrice,
                quantity: el.quantity,
                noOfHours: el.noOfHours,
                type: el.type,
                milestoneId: milestone.milestoneId,
              };
            }),
            materials_objects: materials.map((el) => {
              return {
                id: el.id,
                materialId: el.material.id,
                unitPrice: el.unitPrice,
                quantity: el.quantity,
                milestoneId: milestone.milestoneId,
              };
            }),
            labor_objects: labor.map((el) => {
              return {
                id: el.id,
                laborId: el.labor.id,
                unitPrice: el.unitPrice,
                quantity: el.quantity,
                noOfHours: el.noOfHours,
                milestoneId: milestone.milestoneId,
              };
            }),
            tests_objects: tests.map((el) => {
              return { ...el, milestoneId: milestone.milestoneId };
            }),
            deletedChecklist,
            deletedTasks,
            deletedEquipments,
            deletedMaterials,
            deletedLabor,
            deletedTests,
          },
        });
        console.log(
          !updateMilestoneError,
          !isMilestoneUpdating,
          response.data.update_projects_milestones_by_pk.id
        );
        if (response.data.update_projects_milestones_by_pk.id) {
          setPhaseData(null);
          refetchProjects();
          navigate("/phases", {
            state: { success: "Milestone updated successfully" },
          });
        }
      } else {
        console.log(tasks);
        console.log(materials);
        console.log(equipments);
        console.log(labor);
        const phaseData = {
          name: name.value,
          startDate: start,
          endDate: end,
          projectId: currentProject.id,
        };
        const response = await insertMilestone({
          variables: { object: { ...phaseData } },
        });
        if (
          !insertMilestoneError &&
          !isMilestoneSubmitting &&
          response.data.insert_projects_milestones_one.id
        ) {
          const milestoneId = response.data.insert_projects_milestones_one.id;
          try {
            const response2 = await insertRest({
              variables: {
                tasks: tasks.map((el) => {
                  return { ...el, milestoneId };
                }),
                materials: materials.map((el) => {
                  return {
                    materialId: el.material.id,
                    unitPrice: el.unitPrice,
                    quantity: el.quantity,
                    milestoneId,
                  };
                }),
                equipments: equipments.map((el) => {
                  return {
                    equipmentId: el.equipment.id,
                    unitPrice: el.unitPrice,
                    quantity: el.quantity,
                    noOfHours: el.noOfHours,
                    type: el.type,
                    milestoneId,
                  };
                }),
                labor: labor.map((el) => {
                  return {
                    laborId: el.labor.id,
                    unitPrice: el.unitPrice,
                    quantity: el.quantity,
                    noOfHours: el.noOfHours,
                    milestoneId,
                  };
                }),
                tests: tests.map((el) => {
                  return { ...el, milestoneId, isChecked: false };
                }),
                checklist: checklist.map((el) => {
                  return { ...el, milestoneId, isChecked: false };
                }),
              },
            });
            console.log(response2?.data, insertRestError);
            if (response2?.data && !insertRestError) {
              setPhaseData(null);
              refetchProjects();
              navigate("/phases", {
                state: { success: "New milestone created successfully" },
              });
            } else {
              deleteMilestone({ variables: { milestoneId } });
            }
          } catch (error) {
            setError(error?.message);
            deleteMilestone({ variables: { milestoneId } });
          }
        } else {
          setError("An error occured. Please try again.");
          deleteMilestone({
            variables: {
              milestoneId: response.data.insert_projects_milestones_one.id,
            },
          });
        }
      }
    } catch (error) {
      setError(error?.message);
    }
    setLoading(false);
  };
  const scrollToBottom = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 100);
  };
  useEffect(() => {
    // if (!editMode) {
    //   setTotalCost(0);
    // }
    scrollToBottom();
  }, []);
  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };
  if (currentProject) {
    return (
      <div className="m-3 p-5 px-10 rounded-sm bg-white">
        <div className="w-full flex justify-between">
          <div>
            <div className="text-xs w-fit flex justify-center items-center space-x-2 text-dark-gray">
              <Link className="hover:underline" to="/phases">
                Milestones
              </Link>
              <ChevronRight className="text-[10px]" />
              <span>{editMode ? "Update milestone" : "Add new milestone"}</span>
            </div>
            <h1 className="font-bold text-lg mt-3">
              {editMode ? "Update milestone" : "Add new milestone"}
            </h1>
          </div>
          <span className="text-xl font-semibold">
            Total estimated cost: {totalCost}
          </span>
        </div>
        <div className="mt-10 w-[90%] space-x-20 flex justify-center items-center">
          <Stepper
            onClick={(index) => setStep(index)}
            activeStep={step}
            items={steps}
          />
          <div className="ml-8 w-[70%]">
            <div className="flex justify-between">
              <span className="w-full">
                <h1 className="font-bold text-lg leading-none">
                  {editMode ? "Update milestone" : "Create a new milestone"}
                </h1>
                <span className="w-full mt-2 flex justify-between items-center text-dark-gray text-xs">
                  {editMode ? (
                    // <span className="">
                    //   <InfoIcon className="!w-4 !h-4 mr-1 text-orange-400" />
                    //   Changes are not applied
                    // </span>
                    <span>
                      Change the following form and update the milestone
                    </span>
                  ) : (
                    "Fill in the following form and create a new milestone"
                  )}
                  {editMode && (
                    <Button
                      disabled={!steps[step - 1]?.isValid}
                      isLoading={loading}
                      onClick={() => {
                        handleSaveMilestone();
                      }}
                      type="submit"
                    >
                      Apply
                    </Button>
                  )}
                </span>
              </span>
            </div>
            <form className="mt-4">
              <div className="min-h-[300px] min-w-[600px]">
                {step === 1 ? (
                  <NameAndDate
                    setSteps={setSteps}
                    steps={steps}
                    step={step}
                    inputs={{ name, startDate, endDate }}
                    setChecklist={setChecklist}
                    checklist={checklist}
                    scrollToBottom={scrollToBottom}
                    editMode={editMode}
                    deletedItems={deletedChecklist}
                    setDeletedItems={setDeletedChecklist}
                  />
                ) : step === 2 ? (
                  <QuantifiableTasks
                    setSteps={setSteps}
                    steps={steps}
                    step={step}
                    setTasks={setTasks}
                    tasks={tasks}
                    scrollToBottom={scrollToBottom}
                    editMode={editMode}
                    deletedItems={deletedTasks}
                    setDeletedItems={setdeletedTasks}
                    commonTasks={commonTasks?.projects_common_tasks}
                  />
                ) : step === 3 ? (
                  <Materials
                    setSteps={setSteps}
                    steps={steps}
                    step={step}
                    setMaterials={setMaterials}
                    materials={materials}
                    otherData={{ equipment: equipments, labor }}
                    refetch={getResources}
                    allMaterials={resources_materials}
                    scrollToBottom={scrollToBottom}
                    editMode={editMode}
                    deletedItems={deletedMaterials}
                    setDeletedItems={setDeletedMaterials}
                  />
                ) : step === 4 ? (
                  <Equipments
                    setSteps={setSteps}
                    steps={steps}
                    step={step}
                    setEquipments={setEquipments}
                    equipments={equipments}
                    otherData={{ materials, labor }}
                    refetch={getResources}
                    allEquipment={resources_equipment}
                    scrollToBottom={scrollToBottom}
                    editMode={editMode}
                    deletedItems={deletedEquipments}
                    setDeletedItems={setDeletedEquipments}
                  />
                ) : step === 5 ? (
                  <Labor
                    setSteps={setSteps}
                    steps={steps}
                    step={step}
                    setLabor={setLabor}
                    labors={labor}
                    otherData={{ materials, equipment: equipments }}
                    refetch={getResources}
                    allLabor={resources_labor}
                    scrollToBottom={scrollToBottom}
                    editMode={editMode}
                    deletedItems={deletedLabor}
                    setDeletedItems={setDeletedLabor}
                  />
                ) : step === 6 ? (
                  <Tests
                    setSteps={setSteps}
                    steps={steps}
                    step={step}
                    setTests={setTests}
                    tests={tests}
                    scrollToBottom={scrollToBottom}
                    editMode={editMode}
                    deletedItems={deletedTests}
                    setDeletedItems={setDeletedTests}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="flex justify-between mt-8">
                <Button
                  className="mr-3 !text-gray-600 !bg-gray-200 hover:!bg-gray-300"
                  type="light"
                  onClick={() => {
                    navigate("/phases");
                  }}
                >
                  Cancel
                </Button>
                <div>
                  {step > 1 && (
                    <Button
                      className="mr-3 !text-gray-600 !bg-gray-200 hover:!bg-gray-300"
                      type="light"
                      onClick={() => {
                        handleBack();
                      }}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    disabled={
                      !steps[step - 1]?.isValid ||
                      (step === 6 && steps.find((step) => !step.isValid))
                    }
                    isLoading={loading && step === 6}
                    onClick={() => {
                      step === 6 ? handleSaveMilestone() : handleNext();
                    }}
                    type="submit"
                  >
                    {step === 6 ? (editMode ? "Apply" : "Save") : "Next"}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Toast
          type="error"
          show={error !== null}
          callback={() => {
            setError(null);
          }}
          message={error}
        />
      </div>
    );
  } else {
    return <NoProject />;
  }
};

export default NewPhase;
