import { Datepicker } from "flowbite-react";
// import type { CustomFlowbiteTheme } from 'flowbite-react';
import React from "react";
// import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./datePicker.module.css";
import moment from "moment";

const DatePicker = (props) => {
  // const styles = {
  //   datePicker: {
  //     input: {
  //       border: "none",
  //     }
  //   },
  // };
  const customTheme = {
    root: {
      base: "relative",
    },
    popup: {
      root: {
        base: "absolute top-10 z-50 block pt-2",
        inline: "relative top-0 z-auto",
        inner:
          "inline-block rounded-lg bg-white p-4 shadow-lg dark:bg-gray-700",
      },
      header: {
        base: "",
        title:
          "px-2 py-3 text-center font-semibold text-gray-900 dark:text-white",
        selectors: {
          base: "flex justify-between mb-2",
          button: {
            base: "text-sm rounded-lg text-gray-900 dark:text-white bg-white dark:bg-gray-700 font-semibold py-2.5 px-5 hover:bg-gray-100 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-200 view-switch",
            prev: "",
            next: "",
            view: "",
          },
        },
      },
      view: {
        base: "p-1",
      },
      footer: {
        base: "flex mt-2 space-x-2",
        button: {
          base: "w-full rounded-lg px-5 py-2 text-center text-sm font-medium focus:ring-4 focus:ring-primary/20",
          today:
            "bg-primary text-white hover:bg-primary/80 dark:bg-primary/80 dark:hover:bg-primary/80",
          clear:
            "border border-gray-300 bg-white text-gray-900 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600",
        },
      },
    },
    views: {
      days: {
        header: {
          base: "grid grid-cols-7 mb-1",
          title:
            "dow h-6 text-center text-sm font-medium leading-6 text-gray-500 dark:text-gray-400",
        },
        items: {
          base: "grid w-64 grid-cols-7",
          item: {
            base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600 ",
            selected: "bg-primary text-white hover:bg-primary/80",
            disabled: "text-gray-500",
          },
        },
      },
      months: {
        items: {
          base: "grid w-64 grid-cols-4",
          item: {
            base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600",
            selected: "bg-primary text-white hover:bg-primary/80",
            disabled: "text-gray-500",
          },
        },
      },
      years: {
        items: {
          base: "grid w-64 grid-cols-4",
          item: {
            base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600 text-gray-900",
            selected: "bg-primary text-white hover:bg-primary/80",
            disabled: "text-gray-500",
          },
        },
      },
      decades: {
        items: {
          base: "grid w-64 grid-cols-4",
          item: {
            base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9  hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600 text-gray-900",
            selected: "bg-primary text-white hover:bg-primary/80",
            disabled: "text-gray-500",
          },
        },
      },
    },
  };
  const successInputClasses =
    "bg-green-50 border border-green-500 text-green-900 placeholder-green-700 text-sm rounded-lg focus:ring-green-500 block w-full p-2.5 dark:bg-green-100 dark:border-green-400";
  const errorInputClasses =
    "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-300 block w-full p-2.5 dark:bg-red-100 dark:border-red-400";
  const successLabelClasses =
    "block mb-2 text-sm font-medium text-green-600 dark:text-green-500";
  const errorLabelClasses =
    "block mb-2 text-sm font-medium text-red-600 dark:text-red-500";
  return (
    <div className={`w-56 ${props.className}`}>
      {props.label && (
        <label
          htmlFor={props.id}
          className={`block mb-2 text-sm font-medium text-gray-600 dark:text-white ${
            props.success && successLabelClasses
          } ${props.error && errorLabelClasses}`}
        >
          {props.label}
        </label>
      )}
      {/* <div className="relative"> */}
      {/* {props.icon && (
          <div className="z-[1] text-dark-gray absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            {props.icon}
          </div>
        )} */}
      {/* <ReactDatePicker
          id={props.id}
          selected={props.value}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          onBlur={props.onBlur}
          dateFormat={props.dateFormat}
          className={`border-none text-gray-900 text-sm rounded-lg focus:ring-4 focus:ring-primary/20 block w-full ${
            props.icon && "pl-10"
          } p-2.5 placeholder:text-gray-400 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary ${
            props.success && successInputClasses
          } ${props.error && errorInputClasses} ${props.bgColor || "bg-gray-50"}`}
          placeholderText={props.placeholderText}
        /> */}
      <Datepicker
        theme={customTheme}
        id={props.id}
        minDate={props.minDate}
        maxDate={props.maxDate}
        defaultDate={
          props.value && props.value !== "" ? props.value : undefined
        }
        selected={
          props.value && props.value !== ""
            ? moment(props.value).format("LL")
            : null
        }
        value={
          props.value && props.value !== ""
            ? moment(props.value).format("LL")
            : null
        }
        onSelectedDateChanged={props.onChange}
        onKeyDown={props.onKeyDown}
        onBlur={props.onBlur}
        placeholder={props.placeholderText}
        dateFormat={props.dateFormat}
        className={`${styles.datePicker} placeholder:text-gray-400  focus:ring focus:ring-primary/20 rounded-lg !text-gray-200 ${props.otherClasses}`}
        // className={`border-none text-gray-900 text-sm rounded-lg focus:ring-4 focus:ring-primary/20 block w-full ${
        //   props.icon && "pl-10"
        // } p-2.5dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary ${
        //   props.success && successInputClasses
        // } ${props.error && errorInputClasses} ${
        //   props.bgColor || "bg-gray-50"
        // }`}
      />
      {/* </div> */}
      {props.success && (
        <p className="mt-2 text-sm text-green-600 dark:text-green-500">
          {props.success}
        </p>
      )}
      {props.error && (
        <p className="mt-2 text-xs tracking-wide text-red-600 dark:text-red-500">
          {props.helperText}
        </p>
      )}
    </div>
  );
};

export default DatePicker;
