import React from "react";
import { FolderFill, PencilFill, Trash3 } from "react-bootstrap-icons";
import { bytesToSize } from "../../../functions/extra";
import IconButton from "../IconButton";

const FilesCard = ({
  folder,
  className,
  onClick,
  deleteFolder,
  editFolder,
}) => {
  return (
    <div
      onClick={onClick}
      className={`group h-32 col-span-3 relative rounded-lg p-2 border cursor-pointer ${className}`}
    >
      <span className="flex items-center">
        <FolderFill className="text-3xl text-dark-orange" />
        <span className="ml-2 font-medium">{folder.name}</span>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            editFolder(folder);
          }}
          type="rounded"
          className="border-none ml-2 hidden group-hover:block"
        >
          <PencilFill />
        </IconButton>
      </span>
      <div className="text-xs mt-8">
        <p>
          <span className="font-medium">
            {folder.files_aggregate.aggregate.count}
          </span>
          <span className="ml-1">files</span>
        </p>
        <p className="flex">
          <span className="font-medium">
            {bytesToSize(folder.files_aggregate.aggregate.sum.size || 0)}
          </span>
        </p>
      </div>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          deleteFolder(folder);
        }}
        type="rounded"
        className="border-none hidden group-hover:block absolute right-1 bottom-1 hover:text-dark-red"
      >
        <Trash3 />
      </IconButton>
    </div>
  );
};

export default FilesCard;
