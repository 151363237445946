import React, { useEffect, useState } from "react";
import Dropdown from "../UI/Dropdown";
import Input from "../UI/Input";
import Modal from "../UI/Modal";
import Button from "../UI/Button";
import useInput from "../../hooks/use-input";
import { X } from "react-bootstrap-icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useProject } from "../../store/project-context";
import { isObjectEmpty } from "../../functions/validation";
import Compressor from "compressorjs";
import DragAndDrop from "../UI/DragAndDrop";
import Progress from "../UI/Progress";
import Image from "../UI/Image";
// import axios from "axios";
import IconButton from "../UI/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import PhoneInput from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { generatePassword } from "../../functions/extra";
import { uploadImage } from "../../functions/api";
import { useAuthUser } from "react-auth-kit";

const INSERT_USER = gql`
  mutation MyMutation($object: addUserInput!) {
    addUser(object: $object) {
      status
    }
  }
`;

const UPDATE_CLIENT = gql`
  mutation MyMutation(
    $id: uuid!
    $_set: users_users_set_input!
    $roleId: uuid!
    $objects: [users_user_projects_insert_input!]!
  ) {
    update_users_users_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
    update_users_user_roles(
      _set: { roleId: $roleId }
      where: { userId: { _eq: $id } }
    ) {
      returning {
        roleId
      }
    }
    delete_users_user_projects(where: { userId: { _eq: $id } }) {
      affected_rows
    }
    insert_users_user_projects(objects: $objects) {
      affected_rows
    }
  }
`;

const GET_ROLES = gql`
  query MyQuery($where: users_roles_bool_exp) {
    users_roles(where: $where) {
      code
      description
      id
      name
    }
  }
`;
const NewUser = (props) => {
  const [selectedProjects, setSelectedProjects] = useState([]);
  // const [selectedRoles, setSelectedRoles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [image, setImage] = useState(null);
  const { refetchProjects } = useProject();
  const [updateUser, { loading: isUserUpdating, error: updateUserError }] =
    useMutation(UPDATE_CLIENT);
  const [addUser, { loading: isUserSubmitting, error: insertUserError }] =
    useMutation(INSERT_USER);
  const auth = useAuthUser();
  const user = auth();
  const { selectedUser, editMode, setSuccess, setError } = props;
  const projects = useProject();
  let variables = {};
  if (!user?.roles?.map((role) => role.code).includes("SU")) {
    variables = {
      where: {
        code: { _nin: "SU" },
      },
    };
  }
  const { data: roles } = useQuery(GET_ROLES, {
    variables,
  });
  const {
    value: fullName,
    isValid: fullNameIsValid,
    isInvalid: fullNameIsInValid,
    inputChangeHandler: fullNameChangeHandler,
    inputBlurHandler: fullNameBlurHandler,
  } = useInput((value) => value.trim() !== "");

  const {
    value: phone,
    isValid: phoneIsValid,
    isInvalid: phoneIsInValid,
    inputChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
  } = useInput((value) => value.trim() !== "" && isPossiblePhoneNumber(value));

  const {
    value: email,
    isValid: emailIsValid,
    isInvalid: emailIsInValid,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput(
    (value) => value.trim() !== "" && value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
  );

  const {
    value: role,
    isValid: roleIsValid,
    isInvalid: roleIsInValid,
    inputChangeHandler: roleChangeHandler,
    inputBlurHandler: roleBlurHandler,
  } = useInput((value) => !isObjectEmpty(value));

  const formIsValid =
    fullNameIsValid &&
    // titleIsValid &&
    phoneIsValid &&
    emailIsValid &&
    roleIsValid &&
    (role?.code === "CLNT" ? selectedProjects.length : true);
  const submitHandler = async (e) => {
    let userData = {};
    if (selectedUser) {
      userData = {
        fullName,
        phone,
        email,
        // projects: JSON.stringify(selectedProjects),
      };
    } else {
      userData = {
        fullName,
        phone,
        email,
        password: generatePassword(),
        company_id: user.company_id,
        roles: JSON.stringify([role]), // change to multiple
        // projectId: currentProject?.id,
        projects: JSON.stringify(selectedProjects),
      };
    }
    if (uploadedImage) {
      userData.photoURL = await uploadImage({
        image: uploadedImage,
        setUploadProgress,
        setError,
      });
    } else if (selectedUser && selectedUser.photoURL) {
      if (image) {
        userData.photoURL = selectedUser.photoURL;
      } else {
        userData.photoURL = null;
      }
    }
    console.log(userData);
    try {
      if (selectedUser && editMode) {
        const user_projects_objects = selectedProjects.map((project) => {
          return {
            userId: selectedUser.id,
            projectId: project.id,
          };
        });
        await updateUser({
          variables: {
            id: selectedUser.id,
            roleId: role?.id,
            _set: userData,
            objects: user_projects_objects,
          },
        });
        if (!updateUserError) {
          setSuccess("User updated successfully");
        }
      } else {
        const response = await addUser({ variables: { object: userData } });
        if (response?.data?.addUser) {
          if (response.data.addUser.status === "success" && !insertUserError) {
            setSuccess("User added successfully");
          }
        } else {
          setError("An error occurred while registering user");
        }
      }
      props.hideModal();
      props.refetch();
      refetchProjects();
    } catch (e) {
      setError(e?.message);
      console.log(e);
    }
  };
  const deleteImage = () => {
    setUploadedImage(null);
    setImage(null);
  };
  function handleImageUpload(files) {
    Object.values(files).map(async (file) => {
      const formData = new FormData();

      const compressedBlob = await new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          success(result) {
            resolve(result);
          },
          error(error) {
            reject(error);
          },
        });
      });
      setImage(URL.createObjectURL(compressedBlob));
      formData.append("image", compressedBlob, compressedBlob.name);
      setUploadedImage(formData);
    });
  }
  const addProject = (item, index) => {
    console.log(item);
    if (selectedProjects.indexOf(item.projectName) > -1) {
      return;
    }
    let found = false;
    selectedProjects.map((project) => {
      if (item.id === project.id) {
        found = true;
        return;
      }
    });
    if (found) {
      return;
    }
    setSelectedProjects((selectedProjects) => [
      { id: item.id, projectName: item.projectName },
      ...selectedProjects,
    ]);
  };
  const removeProject = (i) => {
    setSelectedProjects(
      selectedProjects.filter((project, index) => i !== index)
    );
  };
  // const addRole = (item, index) => {
  //   console.log(item);
  //   setSelectedRoles((selectedRoles) => [item, ...selectedRoles]);
  // };
  // const removeRole = (i) => {
  //   setSelectedRoles(selectedRoles.filter((role, index) => i !== index));
  // };
  useEffect(() => {
    if (selectedUser && editMode) {
      fullNameChangeHandler(selectedUser?.fullName);
      roleChangeHandler(selectedUser?.roles[0]);
      phoneChangeHandler(selectedUser?.phone);
      emailChangeHandler(selectedUser?.email);
      setImage(selectedUser?.photoURL);
      if (selectedUser.user_projects) {
        const projects = selectedUser.user_projects.map((user_project) => {
          return user_project.project;
        });
        setSelectedProjects(projects);
      }
    }
  }, [
    editMode,
    selectedUser,
    fullNameChangeHandler,
    roleChangeHandler,
    phoneChangeHandler,
    emailChangeHandler,
    setImage,
  ]);

  if (props.isShown) {
    return (
      <Modal
        headerIsShown={true}
        modalHeight="h-[85%] sm:h-[97%]"
        isShown={true} //props.isShown
        hideModal={props.hideModal}
        modalTitle={editMode ? "Edit User" : "Add New User"}
        modalBottom={
          <div className="flex justify-end mr-4">
            <Button
              className="mr-3"
              type="light"
              onClick={props.hideModal}
              isLoading={isUserSubmitting}
            >
              Cancel
            </Button>
            <Button
              disabled={!formIsValid}
              isLoading={
                isUserSubmitting || isUserUpdating || uploadProgress !== 0
              }
              onClick={submitHandler}
              type="submit"
            >
              {editMode ? "Update" : "Save"}
            </Button>
          </div>
        }
      >
        <p className="text-dark-gray text-sm ">
          Create accounts to your colleagues and collaborate.
        </p>
        <form className="space-y-3 mt-5 flex flex-col justify-between h-fit">
          <Input
            onChange={fullNameChangeHandler}
            onBlur={fullNameBlurHandler}
            value={fullName}
            error={fullNameIsInValid}
            id="fullName"
            label="Full name"
            helperText={<>Full name is required</>}
            placeholder="Adam sandler"
            required
            name="name"
            type="text"
          />
          <Input
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
            value={email}
            error={emailIsInValid}
            id="email"
            label="Email"
            fullName
            helperText={<>A valid email is required</>}
            placeholder="youremail@gmail.com"
            required
            type="email"
          />
          {/* <Input
            onChange={phoneChangeHandler}
            onBlur={phoneBlurHandler}
            value={phone}
            error={phoneIsInValid}
            id="phone"
            label="Phone"
            helperText={<>A valid phone is required</>}
            placeholder="9 00000000"
            required
            type="tel"
          /> */}
          <>
            <label
              htmlFor="input-group-1"
              className={`block mb-2 w-fit text-sm font-medium text-gray-600 dark:text-white ${
                phoneIsInValid &&
                "block mb-2 text-sm font-medium text-red-600 dark:text-red-500"
              }`}
            >
              Phone
            </label>
            <PhoneInput
              countryCallingCodeEditable={false}
              international
              onChange={phoneChangeHandler}
              onBlur={phoneBlurHandler}
              defaultCountry="ET"
              value={phone}
              id="phone"
              label="Phone"
              placeholder="9 00000000"
              error={phoneIsInValid}
              required
              inputComponent={Input}
            />
          </>
          <Dropdown
            selectHandler={roleChangeHandler} // addRole for multiple
            onBlur={roleBlurHandler}
            value={role?.name}
            error={roleIsInValid}
            id="role"
            label="Role"
            optionsTop={true}
            placeholder="Select a role for the user"
            helperText={<>User role is required</>}
            items={roles?.users_roles}
            loading={roles?.loading}
            errorMessage={roles?.error?.message}
            propertyName="name"
          />
          {/* <div className="w-first flex space-x-3">
            {selectedRoles.length !== 0 &&
              selectedRoles.map((role, index) => (
                <span
                  key={index}
                  className="bg-primary/10 flex items-center text-primary text-xs rounded-md pl-3 pr-1 py-1"
                >
                  {role.name}
                  <button
                    onClick={() => removeRole(index)}
                    type="button"
                    className="text-lg ml-2 hover:text-red-500"
                  >
                    <X />
                  </button>
                </span>
              ))}
          </div> */}
          {role?.code === "CLNT" && (
            <Dropdown
              selectHandler={addProject}
              id="projects"
              label="Project"
              optionsTop={true}
              helperText={<>At least project is required</>}
              items={projects?.projects}
              // onBlur={clientBlurHandler}
              // value={client?.fullName}
              // error={projectsError}
              loading={projects?.loading}
              errorMessage={projects?.error?.message}
              propertyName="projectName"
            />
          )}
          <div className="w-full flex flex-wrap justify-start">
            {selectedProjects.length !== 0 &&
              selectedProjects.map((project, index) => (
                <span
                  key={index}
                  className="bg-primary/10 mb-2 mr-3 whitespace-nowrap flex items-center text-primary text-xs rounded-md px-3 py-1"
                >
                  {project.projectName}
                  {selectedUser?.roles[0]?.code === "CLNT" &&
                    role?.code === "CLNT" && (
                      <button
                        onClick={() => removeProject(index)}
                        type="button"
                        className="text-lg ml-2 text-red-500 hover:text-red-4 00"
                      >
                        <X />
                      </button>
                    )}
                </span>
              ))}
          </div>
          <label
            htmlFor="input-group-1"
            className={`flex flex-col justify-between text-start w-full text-sm font-medium text-gray-600 dark:text-white`}
          >
            Profile picture(optional)
            {uploadProgress !== 0 && (
              <Progress className="w-full mt-2" progress={uploadProgress} />
            )}
          </label>
          {!image ? (
            <div className="flex flex-col pb-2 justify-center w-full mb-2">
              <DragAndDrop
                onDrop={handleImageUpload}
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-[10rem] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    JPG, PNG or TIFF (MAX. 10MB)
                  </p>
                </div>
                <input
                  accept="image/jpeg, image/jpg, image/png, image/tiff"
                  onChange={(event) => {
                    handleImageUpload(event.target.files);
                  }}
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                />
              </DragAndDrop>
            </div>
          ) : (
            <div className="flex justify-center items-center space-x-2 mb-2 h-[10rem] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer">
              <div className="group relative">
                <Image
                  width="w-24"
                  height="h-24"
                  skeletonWidth="w-10"
                  skeletonHeight="h-10"
                  src={image}
                  alt="Project"
                  className="object-cover rounded-md"
                />
                <IconButton
                  className="hidden group-hover:flex absolute top-0 bottom-0 left-0 right-0 m-auto border-none bg-white/50 hover:bg-white/70 text-red-500 w-6 h-6"
                  type="rounded"
                  onClick={deleteImage}
                >
                  <DeleteIcon className="!w-4 !h-4" />
                </IconButton>
              </div>
            </div>
          )}
        </form>
      </Modal>
    );
  } else {
    return <></>;
  }
};

export default NewUser;
