import moment from "moment";
import React from "react";
import { Check2All } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import NotificationMessage from "./NotificationMessage";
import { Tab, Tabs } from "./Tabs";

const Notifications = ({
  onClick,
  markAllAsReadLoading,
  markAllAsReadHandler,
  notifications,
  notificationsLoading,
  notificationsError,
}) => {
  return (
    <div className="relative rounded-lg shadow-md text-sm flex flex-col min-w-[350px] max-h-96 bg-white z-[9]">
      <div className="flex justify-between p-3">
        <h1 className="font-semibold">Notifications</h1>
        <button
          disabled={markAllAsReadLoading}
          onClick={markAllAsReadHandler}
          className="text-xs disabled:text-primary/50 disabled:cursor-not-allowed text-primary flex items-center"
        >
          <Check2All className="mr-1" />
          Mark all as read
        </button>
      </div>
      <Tabs className="mt-4" customStyles="!p-0">
        <Tab label="All">
          {notificationsLoading ? (
            <p className="text-dark-gray text-center">Loading...</p>
          ) : notificationsError ? (
            <p className="text-dark-gray text-center">
              {notificationsError.message}
            </p>
          ) : notifications.users_notifications.length === 0 ? (
            <p className="text-dark-gray text-center">No new notifications</p>
          ) : (
            notifications.users_notifications.map((notification, index) => (
              <NotificationMessage
                id={notification.id}
                onClick={onClick}
                key={index}
                title={notification.title}
                link={notification.link}
                description={notification.description}
                time={moment(notification.created_at).fromNow()}
                read={notification.read}
                // actions={
                //   <Link className="text-dark-blue" to="">
                //     View details
                //   </Link>
                // }
                isRead={true}
              />
            ))
          )}
        </Tab>
        <Tab label="Unread">
          {notificationsLoading ? (
            <p className="text-dark-gray text-center">Loading...</p>
          ) : notificationsError ? (
            <p className="text-dark-gray text-center">
              {notificationsError.message}
            </p>
          ) : notifications.users_notifications.filter(
              (notification) => !notification.read
            ).length === 0 ? (
            <p className="text-dark-gray text-center">
              No unread notifications
            </p>
          ) : (
            notifications.users_notifications.map(
              (notification, index) =>
                !notification.read && (
                  <NotificationMessage
                    id={notification.id}
                    onClick={onClick}
                    key={index}
                    title={notification.title}
                    link={notification.link}
                    description={notification.description}
                    time={moment(notification.created_at).fromNow()}
                    read={notification.read}
                    tag={notification.tag}
                    // actions={
                    //   <Link className="text-dark-blue" to="">
                    //     View details
                    //   </Link>
                    // }
                    isRead={true}
                  />
                )
            )
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default Notifications;
