import React, { useEffect, useRef } from "react";
import Gallery from "../components/UI/Gallery";
import IconButton from "../components/UI/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Button from "../components/UI/Button";
import EditIcon from "@mui/icons-material/Edit";
import Toast from "../components/UI/Toast";
import NewImage from "../components/Forms/NewImage";
import { Fragment, useState } from "react";
import Card from "../components/UI/Card";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
// import userDetails from "../assets/illustrations/images.svg";
import { Tabs, Tab } from "../components/UI/Tabs";
import { useProject } from "../store/project-context";
import noImage from "../assets/illustrations/no-image.svg";
import Image from "../components/UI/Image";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import ShareIcon from "@mui/icons-material/Share";
import DeleteIcon from "@mui/icons-material/Delete";
import Confirm from "../components/UI/Confirm";
import FullScreenSlideshow from "../components/UI/Full screen slideshow";
import { Warning } from "@mui/icons-material";
import NoProject from "../components/UI/NoProject";

const GET_IMAGES = gql`
  query MyQuery($projectId: uuid!, $to: timestamptz!, $from: timestamptz!) {
    allImages: projects_images(
      where: { projectId: { _eq: $projectId } }
      order_by: { sequence: desc }
    ) {
      description
      created_at
      groupId
      id
      sequence
      imageName
      projectId
      url
    }
    thisWeekImages: projects_images(
      where: {
        projectId: { _eq: $projectId }
        created_at: { _lte: $to, _gte: $from }
      }
      order_by: { sequence: desc }
    ) {
      description
      created_at
      groupId
      id
      imageName
      projectId
      url
    }
  }
`;

const DELETE_IMAGE = gql`
  mutation MyMutation($id: uuid!) {
    delete_projects_images_by_pk(id: $id) {
      id
    }
  }
`;

const DELETE_MANY_IMAGES = gql`
  mutation MyMutation($_in: [uuid!]!) {
    delete_projects_images(where: { id: { _in: $_in } }) {
      affected_rows
    }
  }
`;

const from = new Date(new Date().setDate(new Date().getDate() - 7))
  .toISOString()
  .substring(0, 10);
const to = new Date().toISOString().substring(0, 10) + "T23:59:59";

const ImagesPage = () => {
  const [openSlideShow, setOpenSlideShow] = useState(false);
  const [enableMultipleSelect, setEnableMultipleSelect] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [newImageModalShown, setNewImageModalShown] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isConfirmDeleteOneShown, setIsConfirmDeleteOneShown] = useState(false);
  const [isConfirmDeleteManyShown, setIsConfirmDeleteManyShown] =
    useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [images, setImages] = useState([]);
  const cardRef = useRef();
  const tabRef = useRef();
  const { currentProject, refetchProjects } = useProject();

  const [deleteImage, { loading: isDeleting, error: deleteError }] =
    useMutation(DELETE_IMAGE);

  const [
    deleteManyImages,
    { loading: isDeletingMany, error: deleteManyError },
  ] = useMutation(DELETE_MANY_IMAGES);

  const {
    data,
    loading: imagesLoading,
    error: imagesError,
    refetch: refetchImages,
  } = useQuery(GET_IMAGES, {
    variables: {
      projectId: currentProject?.id,
      from,
      to,
    },
  });

  useEffect(() => {
    setSelectedImage(null);
    if (!imagesLoading && !imagesError) {
      setImages(data);
    }
  }, [data]);

  const hideModal = () => {
    setSelectedImage(null);
    setEditMode(false);
    setNewImageModalShown(false);
  };

  const deleteManyHandler = async () => {
    if (selectedImages.length === 0) {
      return;
    }
    try {
      await deleteManyImages({ variables: { _in: selectedImages } });
      if (!deleteManyError) {
        refetchImages();
        refetchProjects();
        setIsConfirmDeleteManyShown(false);
        setSelectedImage(null);
        setSelectedImages([]);
        setEnableMultipleSelect(false);
        setSuccess(
          selectedImages.length +
            `${selectedImages.length > 1 ? " images" : " image"}` +
            " deleted successfully"
        );
      }
    } catch (err) {
      setError(err.message);
      console.log(err);
    }
  };
  const deleteOneHandler = async () => {
    if (selectedImage === null) {
      return;
    }
    try {
      await deleteImage({ variables: { id: selectedImage.id } });
      if (!deleteError) {
        refetchImages();
        refetchProjects();
        setIsConfirmDeleteOneShown(false);
        setSelectedImage(null);
        setSelectedImages([]);
        setSuccess("Image deleted successfully");
      }
    } catch (err) {
      setError(err.message);
      console.log(err);
    }
  };
  const showDeleteOneConfirmation = () => {
    setIsConfirmDeleteOneShown(true);
  };
  const showDeleteManyConfirmation = (selectedList) => {
    if (selectedList.length === 0) {
      return;
    }
    setSelectedImages(selectedList);
    setIsConfirmDeleteManyShown(true);
  };
  const checkboxClickHandler = (checked, id) => {
    if (checked) {
      setSelectedImages([...selectedImages, id]);
    } else {
      setSelectedImages(
        selectedImages.filter((selectedId) => selectedId !== id)
      );
    }
  };
  const selectImage = (e) => {
    if (e === null || e === selectedImage) {
      setSelectedImage(null);
      return;
    }
    setSelectedImage(e);
    cardRef.current && cardRef.current.scrollIntoView({ behavior: "smooth" });
  };

  if (currentProject) {
    return (
      <div ref={cardRef} className="m-3 grid grid-cols-12">
        {isConfirmDeleteOneShown && (
          <Confirm
            confirmButtonText="Delete"
            isLoading={isDeleting}
            confirmTitle="Delete"
            onClick={deleteOneHandler}
            onCancel={() => {
              setIsConfirmDeleteOneShown(false);
            }}
          >
            Are you sure you want to delete this image?
          </Confirm>
        )}
        {isConfirmDeleteManyShown && (
          <Confirm
            confirmButtonText="Delete"
            isLoading={isDeletingMany}
            confirmTitle="Delete"
            onClick={deleteManyHandler}
            onCancel={() => {
              setIsConfirmDeleteManyShown(false);
            }}
          >
            Are you sure you want to delete {selectedImages.length}
            {selectedImages.length === 1 ? " image" : " images"}?
          </Confirm>
        )}
        <Tabs
          tabRef={tabRef}
          className={`${selectedImage ? "col-span-9" : "col-span-12"}`}
          otherElements={
            <div className="flex items-center">
              {enableMultipleSelect ? (
                <div className="flex items-center space-x-5 mr-5">
                  <button
                    onClick={() => {
                      setEnableMultipleSelect(false);
                      setSelectedImages([]);
                    }}
                    className="text-sm font-semibold text-primary hover:text-secondary"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => showDeleteManyConfirmation(selectedImages)}
                    disabled={selectedImages.length === 0}
                    className="text-sm font-semibold disabled:text-red-400 text-red-600 hover:text-red-400"
                  >
                    Remove({selectedImages.length})
                  </button>
                </div>
              ) : (
                <IconButton
                  onClick={() => {
                    setEnableMultipleSelect(true);
                  }}
                  type="rounded"
                  className="text-primary hover:text-secondary mr-5 border-none"
                >
                  <EditIcon className="text-xs" />
                </IconButton>
              )}
              <Button
                onClick={() => {
                  setNewImageModalShown(true);
                  setSelectedImage(null);
                }}
              >
                <AddIcon />
                Add New Image
              </Button>
            </div>
          }
        >
          <Tab label="All Images">
            <div className="">
              {imagesLoading && !imagesError ? (
                <p className="w-full text-center mt-5">Loading...</p>
              ) : imagesError ? (
                <p className="col-span-full mt-5 flex justify-center items-center">
                  <Warning className="!w-4 !h-4 mr-1" /> {imagesError?.message}
                </p>
              ) : images?.allImages?.length > 0 ? (
                <Gallery
                  enableMultipleSelect={enableMultipleSelect}
                  onImageClick={selectImage}
                  images={images?.allImages}
                  checkbox={enableMultipleSelect}
                  checkboxClickHandler={checkboxClickHandler}
                  selectedImage={selectedImage}
                />
              ) : (
                <div className="mt-5 flex flex-col items-center justify-center">
                  <img
                    className="w-72 mt-4 object-cover"
                    alt=""
                    src={noImage}
                  />
                  <p className="text-gray-700 text-2xl mt-5">No image found</p>
                  <p className="text-dark-gray mt-5 text-center text-sm">
                    Images will appear here once uploaded
                  </p>
                </div>
              )}
              {openSlideShow && (
                <FullScreenSlideshow
                  onClose={() => setOpenSlideShow(false)}
                  items={images?.allImages}
                  selectedImage={selectedImage}
                />
              )}
            </div>
          </Tab>
          <Tab label="This week">
            <div className="">
              {imagesLoading && !imagesError ? (
                <p className="w-full text-center mt-5">Loading...</p>
              ) : imagesError ? (
                <p className="col-span-full flex justify-center items-center">
                  <Warning className="!w-4 !h-4 mr-1" /> {imagesError?.message}
                </p>
              ) : images?.thisWeekImages?.length > 0 ? (
                <Gallery
                  onImageClick={selectImage}
                  images={images?.thisWeekImages}
                  checkbox={enableMultipleSelect}
                  checkboxClickHandler={checkboxClickHandler}
                  selectedImage={selectedImage}
                />
              ) : (
                <div className="mt-5 flex flex-col items-center justify-center">
                  <img
                    className="w-72 mt-4 object-cover"
                    alt=""
                    src={noImage}
                  />
                  <p className="text-gray-700 text-2xl mt-5">No image found</p>
                  <p className="text-dark-gray mt-5 text-center text-sm">
                    Images will appear here once uploaded
                  </p>
                </div>
              )}
              {openSlideShow && (
                <FullScreenSlideshow
                  onClose={() => setOpenSlideShow(false)}
                  items={images?.thisWeekImages}
                  selectedImage={selectedImage}
                />
              )}
            </div>
          </Tab>
        </Tabs>
        {!imagesError && (
          <Card
            // cardRef={cardRef}
            className={`sticky vertical-scrollbar top-0 right-0 flex flex-col items-center overflow-x-hidden overflow-y-auto max-h-screen ml-3 space-y-3 transition-all duration-200 ${
              selectedImage
                ? "translate-x-0 col-span-3"
                : "translate-x-full !bg-transparent"
            }`}
          >
            {selectedImage ? (
              <Fragment>
                {/* <h1 className="text-start w-full text-lg font-medium">
                  Details
                </h1> */}
                <div
                  className="w-full h-48 rounded-lg "
                  style={{
                    backgroundImage: `url(${selectedImage.url})`,
                    backgroundSize: "cover",
                  }}
                >
                  <div
                    className="h-full w-full rounded-lg flex items-center justify-center bg-clip-padding bg-opacity-20"
                    style={{ backdropFilter: `blur(5px)` }}
                  >
                    <Image
                      width="w-4/5"
                      height="h-4/5"
                      skeletonWidth="w-54"
                      skeletonHeight="h-54"
                      src={selectedImage.url}
                      alt="Project"
                      className="object-cover rounded-lg"
                    />
                  </div>
                </div>
                <div className="flex space-x-3">
                  <IconButton
                    onClick={() => setOpenSlideShow(true)}
                    type="rounded"
                    className="group bg-gray-50 border-none"
                  >
                    <OpenInFullIcon className="group-hover:text-secondary text-primary !w-5 !h-5" />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setNewImageModalShown(true);
                      setEditMode(true);
                    }}
                    type="rounded"
                    className="group bg-gray-50 border-none"
                  >
                    <EditIcon className="group-hover:text-secondary text-primary !w-5 !h-5" />
                  </IconButton>
                  {/* <IconButton
                    type="rounded"
                    className="group bg-gray-50 border-none"
                  >
                    <ShareIcon className="group-hover:text-secondary text-primary !w-5 !h-5" />
                  </IconButton> */}
                  <IconButton
                    onClick={showDeleteOneConfirmation}
                    type="rounded"
                    className="group bg-gray-50 border-none"
                  >
                    <DeleteIcon className="group-hover:text-red-400 text-red-500 !w-5 !h-5" />
                  </IconButton>
                </div>
                <ul className="w-full text-xs space-y-3 p-2">
                  <li className="flex justify-between">
                    <span className="text-dark-gray">Name</span>
                    <span>{selectedImage.imageName}</span>
                  </li>
                  <li className="flex justify-between">
                    <span className="text-dark-gray">Type</span>
                    <span>{selectedImage.url.slice(-3)} file</span>
                  </li>
                  <li className="flex justify-between">
                    <span className="text-dark-gray">Date</span>
                    <span>{moment(selectedImage.created_at).format("LL")}</span>
                  </li>
                  <li className="flex flex-col !mt-8">
                    <span className="">Description</span>
                    <span className="text-dark-gray">
                      {selectedImage.description}
                    </span>
                  </li>
                </ul>
              </Fragment>
            ) : (
              <Fragment>
                {/* <img src={userDetails} alt="User details illustration" />
              <p className="text-dark-gray text-sm">
                Select an image to see details
              </p> */}
              </Fragment>
            )}
          </Card>
        )}
        {newImageModalShown && (
          <NewImage
            lastUploaded={images?.allImages[0]?.sequence}
            refetch={refetchImages}
            setSuccess={setSuccess}
            setError={setError}
            isShown={newImageModalShown}
            hideModal={hideModal}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            editMode={editMode}
          />
        )}
        <Toast
          type="error"
          show={error !== null}
          callback={() => {
            setError(null);
          }}
          message={error}
        />
        <Toast
          type="success"
          show={success !== null}
          callback={() => {
            setSuccess(null);
          }}
          message={success}
        />
      </div>
    );
  } else {
    return <NoProject />;
  }
};

export default ImagesPage;
