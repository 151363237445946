import React from "react";
import Checkbox from "./Checkbox";
import Image from "./Image";

const Gallery = (props) => {
  const { images, onImageClick, checkbox, checkboxClickHandler, selectedImage } = props;
  return (
    <div className={`grid ${selectedImage ? "grid-cols-4" : "grid-cols-5"} gap-2`}>
      {images &&
        images.map((image, index) => (
          <div
            onClick={() => {
              onImageClick && onImageClick(image);
            }}
            className={`relative ${
              onImageClick ? "shadow-sm hover:shadow-md hover:scale-[1.03] transition-transform cursor-pointer" : ""
            } ${selectedImage?.id === image.id && " bg-primary/10"} p-1 rounded-lg`}
            key={index}
          >
            {checkbox && <Checkbox
              className="absolute left-2 top-2"
              onClick={(checked) => {
                checkboxClickHandler(checked, image.id);
              }}
            />}
            <Image
              width="w-full"
              height="lg:h-36 h-24"
              skeletonWidth="w-full"
              skeletonHeight="h-14"
              className="object-cover rounded-lg"
              src={image.url}
              alt="Project"
            />
            {image.imageName && (
              <p className={`text-dark-gray mt-2 w-full text-xs text-center ${selectedImage?.id === image.id && " text-gray-700"}`}>
                {image.imageName}
              </p>
            )}
          </div>
        ))}
    </div>
  );
};

export default Gallery;
