import React from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import moment from "moment";
import defaultSrc from "../../assets/illustrations/projects.svg";
const ProjectCard = (props) => {
  const {
    id,
    selectedProject,
    src,
    projectName,
    location,
    logo,
    startDate,
    endDate,
    contractor,
    percentage,
    className,
    onClick,
    contractorsCount,
  } = props;
  let completedClassName;
  if (percentage >= 50) {
    completedClassName = "bg-green-100 text-green-600";
  } else if (percentage >= 40) {
    completedClassName = "bg-yellow-100 text-yellow-600";
  } else {
    completedClassName = "bg-rose-100 text-rose-600";
  }
  return (
    <div
      onClick={onClick}
      className={`${
        selectedProject.id === id
          ? "bg-primary/10 hover:!bg-primary/10"
          : "hover:bg-gray-100"
      } overflow-hidden shadow-md hover:shadow-lg hover:scale-[1.03] transition-transform cursor-pointer rounded-xl h-64 min-w-max ${className}`}
      style={{
        backgroundImage: `url(${src || defaultSrc})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="w-full h-full relative text-gray-500">
        {/* <img
          className="w-full rounded-t-xl h-full object-cover"
          src={src || defaultSrc}
          alt="Project"
        /> */}
        <div className={`absolute bg-white/70 h-full w-full px-2 py-1 top-0`}>
          <div className="flex items-center mt-3">
            {logo && (
              <img
                className="w-10 h-10 object-cover rounded-full"
                src={logo}
                alt="Project logo"
              />
            )}
            <div className="ml-2 flex flex-col">
              <h1 className="text-xl font-semibold">{projectName}</h1>
              <span className="flex items-center text-xs font-medium">
                {location && (
                  <LocationOnOutlinedIcon className="!w-5 !h-5 text-dark-red" />
                )}
                {location}
              </span>
            </div>
          </div>
        </div>
        {/* <span className={`${completedClassName} font-semibold flex flex-col justify-center items-center w-14 h-14 rounded-full p-2 absolute bottom-0 right-2 translate-y-1/2`}>
          <span>{percentage}%</span>
          <span className="text-[8px]">Completed</span>
        </span> */}
        <div className="absolute bottom-0 font-semibold mb-3 w-full overflow-hidden text-xs whitespace-nowrap px-3 space-y-1 text-gray-500">
          <li className="flex w-full justify-between">
            <span className="">Starting date</span>
            <span className="">{moment(startDate).format("DD/MM/YYYY")}</span>
          </li>
          <li className="flex w-full justify-between">
            <span className="">Ending date</span>
            <span className="">{moment(endDate).format("DD/MM/YYYY")}</span>
          </li>
          <li className="flex w-full justify-between">
            <span className="">Contractor</span>
            <span className="flex items-center">
              {contractor.photoURL && (
                <img
                  className="w-6 h-6 rounded-full"
                  src={contractor.photoURL}
                  alt="contractor profile"
                />
              )}
              <span className="ml-1 text-primary font-semibold">
                {contractor.fullName}
                {contractorsCount > 1 && (
                  <span className="flex flex-col items-end">
                    <span className="text-gray-500 text-[10px] -translate-y-1">
                      {" "}
                      and {contractorsCount - 1} more
                    </span>
                  </span>
                )}
              </span>
            </span>
          </li>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
