import React, { useEffect, useRef, useState } from "react";
import Modal from "../UI/Modal";
import Button from "../UI/Button";
import useInput from "../../hooks/use-input";
import { gql, useMutation } from "@apollo/client";
import Textarea from "../UI/Textarea";
import Progress from "../UI/Progress";
import axios from "axios";
import { useProject } from "../../store/project-context";
import Image from "../UI/Image";
import DragAndDrop from "../UI/DragAndDrop";
import FileInfo from "../UI/files/FileInfo";
import { uploadFiles } from "../../functions/api";

const INSERT_FILE = gql`
  mutation MyMutation($objects: [projects_files_insert_input!]!) {
    insert_projects_files(objects: $objects) {
      affected_rows
    }
  }
`;

const UPDATE_IMAGE = gql`
  mutation MyMutation3(
    $url: String!
    $id: uuid!
    $groupId: uuid!
    $description: String!
  ) {
    update_projects_images_by_pk(pk_columns: { id: $id }, _set: { url: $url }) {
      id
    }
    update_projects_images(
      where: { groupId: { _eq: $groupId } }
      _set: { description: $description }
    ) {
      affected_rows
    }
  }
`;

const NewFile = (props) => {
  const { currentProject } = useProject();
  const [files, setFiles] = useState(null); // before upload
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const browseImage = useRef();
  const [
    addFiles,
    {
      // data: newFilesData,
      loading: isFileSubmitting,
      error: insertFileError,
    },
  ] = useMutation(INSERT_FILE);

  const [
    updateFile,
    {
      // data: updatedImagesData,
      loading: isImagesUpdating,
      error: updateFileError,
    },
  ] = useMutation(UPDATE_IMAGE);
  const {
    // setSelectedFile,
    selectedFile,
    selectedFolder,
    editMode,
    setError,
    setSuccess,
    isShown,
    hideModal,
    refetch,
  } = props;
  const {
    value: description,
    isValid: descriptionIsValid,
    isInvalid: descriptionIsInValid,
    inputChangeHandler: descriptionChangeHandler,
    inputBlurHandler: descriptionBlurHandler,
  } = useInput((value) => value.trim() !== "");
  let formIsValid = descriptionIsValid && uploadedFiles.length;
  const submitHandler = async (e) => {
    try {
      const finalFiles = await uploadFiles({
        files,
        setUploadProgress,
        setError,
      });
      console.log(finalFiles);
      if (finalFiles.length === 0) {
        setError("Error uploading files");
        return;
      }
      if (!editMode) {
        if (!currentProject?.id) {
          setError("No project selected");
          return;
        }
        // let imagesData = [];
        console.log(selectedFolder);
        let files = [];
        finalFiles.map((file, index) => {
          files.push({
            url: file.url,
            name: file.name,
            size: file.size,
            type: file.type,
            folderId: selectedFolder.id,
            description,
          });
        });
        await addFiles({ variables: { objects: files } });

        if (!insertFileError && !isFileSubmitting) {
          setSuccess(
            `${uploadedFiles.length} ${
              uploadedFiles.length > 1 ? "files" : "file"
            } uploaded successfully`
          );
        } else {
          setError(insertFileError.message);
        }
      } else {
        if (selectedFile) {
          await updateFile({
            variables: {
              id: selectedFile.id,
              url: uploadedFiles[0],
              description: description,
              groupId: selectedFile.groupId,
            },
          });
        } else if (!updateFileError && !isImagesUpdating) {
          setSuccess("File updated for successfully");
        }
      }
      hideModal();
      refetch();
    } catch (e) {
      setError(e?.message);
      console.log(e);
    }
  };

  useEffect(() => {
    if (selectedFile && editMode) {
      descriptionChangeHandler(selectedFile?.description);
      setUploadedFiles(selectedFile?.url);
    }
  }, [selectedFile, editMode, descriptionChangeHandler]);

  function handleFileUpload(files) {
    let newFiles = [];
    const formData = new FormData();
    Object.values(files).map(async (file) => {
      const arr = file.name.split(".");
      const type = arr[arr.length - 1];
      formData.append("files[]", file, file.name);
      newFiles.push({
        name: file.name,
        size: file.size,
        type,
      });
    });
    setUploadedFiles([...uploadedFiles, ...newFiles]);
    setFiles(formData);
  }
  if (isShown) {
    return (
      <>
        <Modal
          headerIsShown={true}
          modalHeight="h-[85%] sm:h-[97%]"
          isShown={true} //isShown
          hideModal={hideModal}
          modalTitle={editMode ? "Edit File" : "Add New File"}
          modalBottom={
            <div className="flex justify-end mr-4">
              <Button className="mr-3" type="light" onClick={hideModal}>
                Cancel
              </Button>
              <Button
                disabled={!formIsValid}
                isLoading={
                  isFileSubmitting || isImagesUpdating || uploadProgress !== 0
                }
                onClick={submitHandler}
                type="submit"
              >
                {editMode ? "Update" : "Save"}
              </Button>
            </div>
          }
        >
          <p className="text-dark-gray text-sm ">
            {editMode
              ? "Edit basic information of the file you selected"
              : "Add a new file of the current construction site that would be used as a reference."}
          </p>
          <form className="space-y-3 mt-5 flex flex-col justify-between h-fit">
            {!editMode ? (
              <div className="flex flex-col justify-center w-full">
                <label
                  htmlFor="input-group-1"
                  className={`block mb-4 text-start w-fit text-sm font-medium text-gray-600 dark:text-white`}
                >
                  Select File <span className="text-red-500">*</span>
                </label>
                {/* <span className="text-dark-gray mb-2 text-xs">
                  Maximum 10 images
                </span> */}
                <DragAndDrop
                  onDrop={handleFileUpload}
                  htmlFor="dropzone-file"
                  className="flex flex-col items-center justify-center w-full min-h-[12rem] border-2 border-gray-300 border-dashed rounded-lg cursor-pointerdark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">Click to upload</span> or
                      drag and drop
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      Click to select file
                    </p>
                  </div>
                  <input
                    accept="*"
                    multiple
                    onChange={(event) => {
                      handleFileUpload(event.target.files);
                    }}
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                  />
                </DragAndDrop>
                {(uploadedFiles.length || uploadProgress !== 0) && (
                  <div className="bg-gray-50 p-3 rounded-lg mt-3 overflow-auto horizontal-scrollbar">
                    <div className="flex flex-col">
                      {uploadedFiles.length &&
                        uploadedFiles.map((file, index) => (
                          <FileInfo
                            key={index}
                            type={file?.type}
                            name={file?.name}
                            size={file?.size}
                          />
                        ))}
                    </div>
                    {uploadProgress !== 0 && (
                      <Progress
                        className="w-full mt-2"
                        progress={uploadProgress}
                      />
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="group">
                <label className="flex flex-col items-center justify-center w-full min-h-[12rem] rounded-lg bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                  <div
                    className="w-full h-48 rounded-lg "
                    style={{
                      backgroundImage: `url(${
                        uploadedFiles.length || selectedFile.url
                      })`,
                      backgroundSize: "cover",
                    }}
                  >
                    <div
                      className="relative h-full w-full rounded-lg flex items-center justify-center bg-clip-padding bg-opacity-20"
                      style={{ backdropFilter: `blur(5px)` }}
                    >
                      <Button
                        className="hidden group-hover:block absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 border !bg-black/20 hover:!bg-black/40 !ring-white/20 text-white"
                        type="light"
                        onClick={() => browseImage.current.click()}
                      >
                        Choose Image
                      </Button>
                      <Image
                        width="w-4/5"
                        height="h-4/5"
                        skeletonWidth="w-54"
                        skeletonHeight="h-54"
                        src={uploadedFiles.length || selectedFile.url}
                        alt="Project"
                        className="object-cover rounded-lg"
                      />
                    </div>
                    <input
                      accept="*"
                      multiple
                      onChange={(event) => {
                        handleFileUpload(event.target.files);
                      }}
                      id="dropzone-file"
                      type="file"
                      ref={browseImage}
                      className="hidden"
                    />
                  </div>
                </label>
                {uploadProgress !== 0 && (
                  <Progress className="w-full mt-2" progress={uploadProgress} />
                )}
              </div>
            )}
            <Textarea
              label="Description"
              onChange={descriptionChangeHandler}
              onBlur={descriptionBlurHandler}
              value={description}
              error={descriptionIsInValid}
              id="description"
              placeholder="Add description"
              helperText={<>A valid description is required</>}
              required
            />
          </form>
        </Modal>
      </>
    );
  } else {
    return <></>;
  }
};

export default NewFile;
