import { Fragment } from "react";
import ReactDOM from "react-dom";
import { X } from "react-bootstrap-icons";
import { Dialog, Transition } from "@headlessui/react";

const ModalOverlay = (props) => {
  return (
    <Fragment>
      <Transition appear show={props.isShown} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.hideModal}>
          <Transition.Child
            as={Fragment}
            enter="transition-all ease-in-out duration-300 delay-[200ms]"
            enterFrom="opacity-0 translate-x-6"
            enterTo="opacity-100 translate-x-0"
            leave="transition-all ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {/* Backdrop */}
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className={`flex items-center justify-center p-4 text-center`}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-100"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-100"
                leaveFrom="opacity-100 scale-100"
                leaveTo="scale-50"
              >
                <Dialog.Panel
                  className={`${props.modalHeight || "h-full"} ${
                    props.modalWidth || "max-w-md 2xl:max-w-xl"
                  } sm:w-full absolute inset-0 m-auto transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all`}
                >
                  <div className="rounded-lg px-1 w-full flex flex-col bg-white m-auto overflow-x-hidden overflow-y-auto fixed h-modal inset-0 z-40 justify-between items-center">
                    {props.headerIsShown && (
                      <div className="bg-white/80 px-4 w-full flex items-center justify-between font-medium modal-header py-1">
                        <h3 className="w-10/12 text-start font-semibold">
                          {props.modalTitle}
                        </h3>
                        <button
                          onClick={props.hideModal}
                          className="rounded-full w-9 h-9 flex outline-none justify-center items-center hover:text-rose-500 hover:bg-gray-100"
                        >
                          <X className="w-6 h-6" />
                        </button>
                      </div>
                    )}
                    <div
                      className={`px-4 vertical-scrollbar modal-content w-full h-full overflow-auto`}
                    >
                      {props.children}
                    </div>
                    <div className="w-full my-1 modal-bottom">
                      {props.modalBottom}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Fragment>
  );
};

const Modal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalOverlay
          modalHeight={props.modalHeight}
          headerIsShown={props.headerIsShown}
          isShown={props.isShown}
          hideModal={props.hideModal}
          modalTitle={props.modalTitle}
          children={props.children}
          modalBottom={props.modalBottom}
          modalWidth={props.modalWidth}
        />,
        document.getElementById("overlay-root")
      )}
    </Fragment>
  );
};

export default Modal;
