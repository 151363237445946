import { Tabs, Tab } from "../components/UI/Tabs";
import projectIllustration from "../assets/illustrations/projects.svg";
import completeIllustration from "../assets/illustrations/complete.svg";
import Button from "../components/UI/Button";
import AddIcon from "@mui/icons-material/Add";
import CardSkeleton from "../components/UI/skeleton/CardSkeleton";
import NewProject from "../components/Forms/NewProject";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Card from "../components/UI/Card";
import { Warning } from "@mui/icons-material";
import { gql, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { ArrowLeftShort } from "react-bootstrap-icons";
import ProjectCard from "../components/UI/ProjectCard";
import Toast from "../components/UI/Toast";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Confirm from "../components/UI/Confirm";
import Spinner from "../components/UI/Spinner";
import { useProject } from "../store/project-context";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../store/user-context";
import { useParams } from "react-router-dom";
const DELETE_PROJECT = gql`
  mutation MyMutation($id: uuid!, $deleted_at: timestamptz!) {
    update_projects_projects_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: $deleted_at }
    ) {
      id
    }
  }
`;

const ProjectsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const users = useUser();
  const [selectedProject, setSelectedProject] = useState(null);
  const [newProjectModalShown, setNewProjectModalShown] = useState(
    location.state?.addProject
  );
  const { site_id } = useParams();
  const [isConfirmDeleteShown, setIsConfirmDeleteShown] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const {
    sites,
    sitesLoading,
    currentProject,
    refetchProjects,
    refetchSites,
    changeProject,
    projects,
    loading: getProjectsLoading,
    error: getProjectsError,
  } = useProject();
  const cardRef = useRef();
  const tabRef = useRef();
  useEffect(() => {
    if (location?.state?.addProject) {
      setNewProjectModalShown(true);
    }
  });
  const [
    deleteProject,
    { loading: isProjectDeleting, error: deleteProjectError },
  ] = useMutation(DELETE_PROJECT);
  console.log(projects);
  const filteredProjects =
    !getProjectsLoading &&
    projects &&
    projects.filter((project) => project.site.id === site_id);
  console.log(filteredProjects);
  const site = !sitesLoading && sites && sites.find((s) => s.id === site_id);
  const deleteProjectHandler = async () => {
    if (selectedProject === null) {
      return;
    }
    try {
      await deleteProject({
        variables: { id: selectedProject.id, deleted_at: new Date() },
      });
      if (!deleteProjectError) {
        refetchProjects();
        refetchSites();
        if (currentProject.id === selectedProject.id) {
          if (currentProject.id === projects[0].id) {
            changeProject(projects[1]);
          } else {
            changeProject(projects[0]);
          }
        }
        setSelectedProject(null);
        setIsConfirmDeleteShown(false);
        setSuccess("Project deleted successfully");
        users?.refetch();
      }
    } catch (e) {
      console.log(e);
      setError(e.message);
    }
  };
  const showDeleteConfirmation = () => {
    setIsConfirmDeleteShown(true);
  };

  const hideModal = () => {
    console.log(selectedProject);
    setSelectedProject(null);
    setEditMode(false);
    setNewProjectModalShown(false);
    navigate(`/sites/${site_id}/projects`, { state: { addProject: false } });
  };
  const selectProject = (project) => {
    if (project === null || project === selectedProject) {
      setSelectedProject(null);
      return;
    }
    setSelectedProject(project);
    cardRef.current && cardRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      {site && (
        <div className="ml-4 text-sm flex justify-start items-center space-x-2 text-gray-500">
          <Link className="flex items-center hover:underline group" to="/sites">
            <ArrowLeftShort className="text-2xl group-hover:-translate-x-1 mr-1" />
            <span>Sites</span>
          </Link>
          <span className="">/</span>
          <span className="font-bold">{site.name}</span>
        </div>
      )}
      <div ref={cardRef} className="m-3 grid grid-cols-12">
        {isConfirmDeleteShown && !deleteProjectError && (
          <Confirm
            confirmButtonText={
              isProjectDeleting ? <Spinner type="light" /> : "Delete"
            }
            isLoading={isProjectDeleting}
            confirmTitle="Delete"
            onClick={deleteProjectHandler}
            onCancel={() => {
              setIsConfirmDeleteShown(false);
            }}
          >
            Are you sure you want to delete this project?
          </Confirm>
        )}
        <Tabs
          tabRef={tabRef}
          className={`${selectedProject ? "col-span-9" : "col-span-12"}`}
          otherElements={
            <Button
              onClick={() => {
                setNewProjectModalShown(true);
                setSelectedProject(null);
              }}
            >
              <AddIcon />
              New Project
            </Button>
          }
        >
          <Tab label="All Projects">
            <div
              className={`grid grid-cols-12 gap-4 items-start min-h-[60vh] p-2 mb-2`}
            >
              {getProjectsError ? (
                <p className="col-span-full mt-5 flex justify-center items-center">
                  <Warning className="!w-4 !h-4 mr-1" /> {error?.message}
                </p>
              ) : getProjectsLoading && !getProjectsError ? (
                <>
                  {[1, 2, 3, 4, 5, 6].map((i) => (
                    <CardSkeleton
                      key={i}
                      className="col-span-4 2xl:col-span-3"
                    />
                  ))}
                </>
              ) : !filteredProjects?.length ? (
                <div className="space-y-2 self-center col-start-5 col-end-9 flex flex-col justify-center items-center">
                  <img
                    className="w-72 object-cover"
                    src={projectIllustration}
                  />
                  <p className="text-gray-700 text-2xl !mt-5">
                    No project found in this site
                  </p>
                  <p className="text-dark-gray mt-5 text-center text-sm">
                    Start by creating your first project with in few steps
                  </p>
                </div>
              ) : (
                <>
                  {!getProjectsLoading &&
                    !getProjectsError &&
                    filteredProjects?.map((project, index) => (
                      <ProjectCard
                        key={project.id}
                        id={project.id}
                        selectedProject={selectedProject || {}}
                        onClick={() => selectProject(project)}
                        className="col-span-4 2xl:col-span-3"
                        src={project.images && project.images[0]?.url}
                        projectName={project.projectName}
                        location={project.location}
                        logo={project.logo}
                        startDate={project.startDate}
                        endDate={project.endDate}
                        percentage={50}
                        contractor={{
                          fullName: project.contractors[0]?.user.fullName,
                          photoURL: project.contractors[0]?.user.photoURL,
                        }}
                        contractorsCount={project.contractors.length}
                      />
                    ))}
                </>
              )}
            </div>
          </Tab>
          <Tab label="Completed">
            <div className="grid grid-cols-12 gap-4 items-start min-h-[60vh] p-2 mb-2">
              {getProjectsError ? (
                <p className="col-span-full mt-5 flex justify-center items-center">
                  <Warning className="!w-4 !h-4 mr-1" />{" "}
                  {getProjectsError?.message}
                </p>
              ) : getProjectsLoading && !getProjectsError ? (
                <>
                  {[1, 2, 3, 4, 5, 6].map((i) => (
                    <CardSkeleton
                      key={i}
                      className="col-span-4 2xl:col-span-3"
                    />
                  ))}
                </>
              ) : !filteredProjects?.filter(
                  (project) => project.completed_at === null
                ).length ? (
                <div className="space-y-2 self-center col-start-5 col-end-9 flex flex-col justify-center items-center">
                  <img
                    className="w-72 object-cover"
                    src={completeIllustration}
                  />
                  <p className="text-gray-700 text-2xl !mt-5">
                    No completed project found
                  </p>
                  <p className="text-dark-gray mt-5 text-center text-sm">
                    Completed projects will show up here
                  </p>
                </div>
              ) : (
                <>
                  {!getProjectsLoading &&
                    !getProjectsError &&
                    projects &&
                    filteredProjects?.map(
                      (project, index) =>
                        project.completed_at && (
                          <ProjectCard
                            key={project.id}
                            id={project.id}
                            selectedProject={selectedProject || {}}
                            onClick={() => selectProject(project)}
                            className="col-span-4 2xl:col-span-3"
                            src={project.images && project.images[0]?.url}
                            projectName={project.projectName}
                            location={project.location}
                            logo={project.logo}
                            startDate={project.startDate}
                            endDate={project.endDate}
                            percentage={50}
                            contractor={{
                              fullName: project.contractors[0].user.fullName,
                              photoURL: project.contractors[0].user.photoURL,
                            }}
                          />
                        )
                    )}
                </>
              )}
            </div>
          </Tab>
          {/* <Tab label="Site analytics"></Tab> */}
        </Tabs>

        <Card
          // cardRef={cardRef}
          className={`sticky vertical-scrollbar top-0 right-0 flex flex-col items-center overflow-x-hidden overflow-y-auto max-h-screen ml-3 space-y-3 transition-all duration-200 ${
            selectedProject
              ? "translate-x-0 col-span-3"
              : "translate-x-full !bg-transparent"
          }`}
        >
          {selectedProject ? (
            <Fragment>
              <h1 className="text-start w-full text-lg font-medium">
                Project details
              </h1>
              <ul className="w-full text-xs space-y-3 p-2">
                <li className="flex items-center border-b pb-2">
                  {selectedProject.logo && (
                    <img
                      className="w-10 h-10 object-cover rounded-full mr-2"
                      src={selectedProject.logo}
                      alt="Project logo"
                    />
                  )}
                  <span className="flex flex-col">
                    <span className="text-dark-gray text-xl">
                      {selectedProject.projectName}
                    </span>
                    <span className="text-primary flex items-center mt-1">
                      {selectedProject.location && (
                        <LocationOnOutlinedIcon className="!w-4 !h-4" />
                      )}
                      {selectedProject.location}
                    </span>
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="text-dark-gray mr-2">Start date</span>
                  <span>
                    {moment(selectedProject.startDate).format("DD/MM/YYYY")}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="text-dark-gray mr-2">Expected end date</span>
                  <span>
                    {moment(selectedProject.endDate).format("DD/MM/YYYY")}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="text-dark-gray mr-2">Client</span>
                  <span>
                    {selectedProject.clients[0]?.user.fullName || "-"}
                  </span>
                </li>
                <li className="flex flex-col">
                  <span className="text-dark-gray mr-2">Contractor(s)</span>
                  <span className="w-full flex flex-wrap gap-2 justify-start mt-2">
                    {selectedProject.contractors.map((contractor) => (
                      <span
                        className="bg-primary/10 flex items-center text-primary text-xs rounded-lg px-3 py-1"
                        key={contractor.user.id}
                      >
                        {contractor?.user?.photoURL && (
                          <img
                            className="w-4 h-4 mr-1 rounded-full"
                            src={contractor?.user?.photoURL}
                            alt="Contractor"
                          />
                        )}
                        <span className="font-medium">
                          {contractor?.user?.fullName || "-"}
                        </span>
                      </span>
                    ))}
                  </span>
                </li>
                <div className="flex flex-col space-y-3 border-t pt-4">
                  <Button
                    className="shadow-sm hover:shadow-lg !bg-green-100/60 hover:!bg-green-200/80 !text-green-600 focus:!ring-0"
                    onClick={() => {
                      setNewProjectModalShown(true);
                      setEditMode(true);
                    }}
                  >
                    Edit Project
                  </Button>
                  <Button
                    onClick={showDeleteConfirmation}
                    className="shadow-sm hover:shadow-lg !bg-red-100/60 hover:!bg-red-200/80 !text-dark-red focus:!ring-0"
                  >
                    Delete Project
                  </Button>
                </div>
              </ul>
            </Fragment>
          ) : (
            <Fragment>
              {/* <img src={userDetails} alt="User details illustration" />
                  <p className="text-dark-gray text-sm">
                    Select a user to see details
                  </p> */}
            </Fragment>
          )}
        </Card>
        <NewProject
          site_id={site_id}
          refetch={refetchProjects}
          setSuccess={setSuccess}
          setError={setError}
          isShown={newProjectModalShown}
          hideModal={hideModal}
          selectedProject={selectedProject}
          editMode={editMode}
        />
        <Toast
          type="error"
          show={error !== null}
          callback={() => {
            setError(null);
          }}
          message={error}
        />
        <Toast
          type="success"
          show={success !== null}
          callback={() => {
            setSuccess(null);
          }}
          message={success}
        />
      </div>
    </>
  );
};

export default ProjectsPage;
