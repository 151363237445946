import React from "react";

const PhasesCard = (props) => {
  return (
    <div
      className={`block w-44 h-32 p-4 overflow-hidden drop-shadow-md bg-white rounded-xl  dark:bg-gray-800 dark:border-gray-700 ${props.className}`}
    >
      <span className="flex items-center">
        <span
          className={`text-white font-medium rounded-full text-sm p-1 text-center inline-flex items-center ${props.bgColor}`}
        >
          {props.icon}
        </span>
        <h5 className="ml-3 text-sm font-semibold whitespace-nowrap dark:text-white">
          {props.title}
        </h5>
      </span>
      <p className="group relative font-bold text-center text-3xl text-gray-700 dark:text-gray-400 mt-4">
        {props.content}
        {props.tooltip && (
          <span className="hidden group-hover:block rounded-lg text-xs px-2 py-1 font-medium absolute bottom-full left-1/2 -translate-x-1/2 w-full bg-gray-50 shadow-xl">
            {props.tooltip}
          </span>
        )}
      </p>
    </div>
  );
};

export default PhasesCard;
