import { Fragment, useState } from "react";
import Nav from "./Nav";
import Sidebar from "./Sidebar";
import { useMediaQuery } from "react-responsive";
const Layout = (props) => {
  const [sidebarShown, setSidebarShown] = useState(true);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1280px)",
  });
  const setSidebarShownHandler = (isShown) => {
    setSidebarShown(isShown);
    console.log(isShown);
    if (isShown && !isDesktopOrLaptop) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  };
  return (
    <Fragment>
      <div className="grid grid-cols-12">
        <Sidebar
          sidebarShown={sidebarShown}
          setSidebarShown={setSidebarShownHandler}
          className={`transition-transform ${
            sidebarShown ? "col-span-2 translate-x-0" : "-translate-x-full"
          } col-start-1 row-start-1`}
        />
        <main
          className={`col-span-12 ${
            sidebarShown ? "xl:col-span-10" : ""
          } col-start-1 row-start-1 transition-transform overlow-x-auto max-w-full bg-light-gray min-h-screen`}
        >
          <Nav
            sidebarShown={sidebarShown}
            setSidebarShown={setSidebarShownHandler}
          />
          <div className="">{props.children}</div>
        </main>
      </div>
    </Fragment>
  );
};

export default Layout;
