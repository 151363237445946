import React from 'react'
import noProject from "../../assets/illustrations/no-project.svg";
import { useProject } from '../../store/project-context';

const NoProject = () => {
  const { loading } = useProject();
  return (
    <div className='h-[80vh] flex flex-col justify-center items-center w-full'>
        <img className='w-1/3' src={noProject} alt=""/>
        <h1 className='font-medium text-2xl mt-10'>{loading ? "Loading..." : "No project selected"}</h1>
    </div>
  )
}

export default NoProject