import { useEffect, useRef, useState } from "react";
const colors = ["#0088FE", "#00C49F", "#FFBB28"];
const delay = 2500;

const Slideshow = (props) => {
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState("Forwards");
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) => {
          if (direction === "Forwards") {
            if (prevIndex === colors.length - 1) {
              setDirection("Backwards");
              return prevIndex - 1;
            } else {
              return prevIndex + 1;
            }
          }else {
            if (prevIndex === 0) {
              setDirection("Forwards");
              return prevIndex + 1;
            } else {
              return prevIndex - 1;
            }
          }
        }),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div
      className={`mx-auto flex flex-col justify-center h-full my-0 overflow-hidden ${props.className}`}
    >
      <div
        className="whitespace-nowrap w-full transition ease duration-1000"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {props.sections.map((section, index) => (
          <div key={index} className="inline-block w-full h-[400px]">
            <h1 className="text-xl text-center whitespace-pre-line mb-4 font-bold">
              {section.title}
            </h1>
            <img
              className="h-full mx-auto"
              src={section.image}
              alt="Illustration"
            />
          </div>
        ))}
      </div>

      <div className="flex justify-center mt-10 z-10">
        {props.sections.map((_, idx) => (
          <div
            key={idx}
            className={`h-[10px] w-[10px] rounded-full cursor-pointer mt-4 mx-1 bg-primary ${
              index === idx ? "bg-primary !w-[20px]" : ""
            }`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Slideshow;
