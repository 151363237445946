import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  split,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";

import { AuthProvider } from "react-auth-kit";
import { WebSocketLink } from "@apollo/client/link/ws";

console.log(window.location.pathname === "/login");

let headers = {};
// if (window.location.pathname === "/login") {
headers = {
  "x-hasura-admin-secret": process.env.REACT_APP_HASURA_GRAPHQL_ADMIN_SECRET,
};
// } else {
//   headers = {
//     authorization: localStorage.getItem("token")
//       ? `Bearer ${localStorage.getItem("token")}`
//       : undefined,
//     // "x-hasura-role": "CNTR",
//     ...headers,
//   };
// }
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_HASURA_GRAPHQL_ENDPOINT,
  headers,
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_HASURA_GRAPHQL_SUBSCRIPTIONS_ENDPOINT,
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        "x-hasura-admin-secret":
          process.env.REACT_APP_HASURA_GRAPHQL_ADMIN_SECRET,
      },
    },
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <AuthProvider
    authType={"cookie"}
    authName={"_auth"}
    cookieDomain={window.location.hostname}
    cookieSecure={window.location.protocol === "https:"}
  >
    <ApolloProvider client={client}>
      <BrowserRouter>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </BrowserRouter>
    </ApolloProvider>
  </AuthProvider>
);
