// import moment from "moment";
import React, { useEffect, useState } from "react";
import useInput from "../../../hooks/use-input";
import Input from "../../UI/Input";
import IconButton from "../../UI/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Check2, PlusCircleFill, X } from "react-bootstrap-icons";
import { isObjectEmpty } from "../../../functions/validation";
import Dropdown from "../../UI/Dropdown";
import Button from "../../UI/Button";
import AddIcon from "@mui/icons-material/Add";
import NewEquipment from "../NewEquipment";
import { usePhase } from "../../../store/phase-context";
import Radio from "../../UI/Radio";

const Equipments = (props) => {
  const {
    scrollToBottom,
    equipments,
    setEquipments,
    refetch,
    allEquipment: allEquipmentProp,
    setSteps,
    steps,
    step,
    editMode,
    deletedItems,
    setDeletedItems,
    otherData,
  } = props;
  const [editedItem, setEditedItem] = useState(null);
  const [newEquipmentModalShown, setNewEquipmentModalShown] = useState(false);
  const [type, setType] = useState("RENTED");
  const [allEquipment, setAllEquipment] = useState(allEquipmentProp);
  const { storePhaseData, calculateTotalCost } = usePhase();
  const {
    value: equipment,
    isValid: equipmentIsValid,
    inputChangeHandler: equipmentChangeHandler,
    inputBlurHandler: equipmentBlurHandler,
    reset: resetEquipment,
  } = useInput((value) => !isObjectEmpty(value));

  const {
    value: unitPrice,
    isValid: unitPriceIsValid,
    inputChangeHandler: unitPriceChangeHandler,
    inputBlurHandler: unitPriceBlurHandler,
    reset: resetUnitPrice,
  } = useInput((value) => value !== "");

  const {
    value: quantity,
    isValid: quantityIsValid,
    inputChangeHandler: quantityChangeHandler,
    inputBlurHandler: quantityBlurHandler,
    reset: resetQuantity,
  } = useInput((value) => value !== "");

  const {
    value: noOfHours,
    isValid: noOfHoursIsValid,
    inputChangeHandler: noOfHoursChangeHandler,
    inputBlurHandler: noOfHoursBlurHandler,
    reset: resetNoOfHours,
  } = useInput((value) => value !== "");

  const onEquipmentChange = (equipment) => {
    unitPriceChangeHandler(equipment.unitPrice);
    equipmentChangeHandler(equipment);
  };

  const formIsValid = equipments.length;
  useEffect(() => {
    setAllEquipment(allEquipmentProp);
  }, [allEquipmentProp]);
  useEffect(() => {
    if (formIsValid) {
      setSteps(
        steps.map((s, index) => {
          if (index === step - 1) {
            return { ...s, isValid: true };
          } else {
            return s;
          }
        })
      );
    } else {
      setSteps(
        steps.map((s, index) => {
          if (index === step - 1) {
            // return { ...s, isValid: false };
            return { ...s, isValid: true };
          } else {
            return s;
          }
        })
      );
    }
  }, [formIsValid]);

  const removeItem = (index) => {
    if (editMode) {
      setDeletedItems([...deletedItems, equipments[index]?.id]);
    } else {
      storePhaseData({
        equipment: equipments.filter((_, i) => i != index),
      });
    }
    setEquipments(equipments.filter((_, i) => i !== index));
    scrollToBottom();
  };
  const hideModal = () => {
    setNewEquipmentModalShown(false);
  };
  const addItem = () => {
    if (
      type === "RENTED" &&
      !(
        equipmentIsValid &&
        unitPriceIsValid &&
        quantityIsValid &&
        noOfHoursIsValid
      )
    ) {
      return;
    } else if (
      type === "PURCHASED" &&
      !(equipmentIsValid && unitPriceIsValid && quantityIsValid)
    ) {
      return;
    } else if (type === "OWNED" && !(equipmentIsValid && quantityIsValid)) {
      return;
    }
    if (type === "PURCHASED") {
      noOfHoursChangeHandler(0);
    } else if (type === "OWNED") {
      noOfHoursChangeHandler(0);
      unitPriceChangeHandler(0);
    }
    let found = false;
    const newEquipment = equipments.map((eq) => {
      if (eq.equipment.id === equipment.id && eq.equipment.type === type) {
        found = true;
        return {
          ...eq,
          quantity: parseInt(eq.quantity) + parseInt(quantity),
        };
      } else {
        return eq;
      }
    });
    setEquipments(newEquipment);
    if (!editMode) {
      storePhaseData({
        equipment: newEquipment,
      });
    } else {
      calculateTotalCost({ equipment: newEquipment, ...otherData });
    }
    if (!found) {
      const newEquipment = [
        ...equipments,
        {
          equipment,
          unitPrice: type === "RENTED" || type === "PURCHASED" ? unitPrice : 0,
          quantity,
          noOfHours: type === "RENTED" ? noOfHours : 0,
          type,
          unit: equipment.unit,
        },
      ];
      setEquipments(newEquipment);
      if (!editMode) {
        storePhaseData({
          equipment: newEquipment,
        });
      } else {
        calculateTotalCost({ equipment: newEquipment, ...otherData });
      }
    }
    resetEquipment();
    resetUnitPrice();
    resetQuantity();
    resetNoOfHours();
    scrollToBottom();
  };
  const selectItem = (item, index) => {
    if (item) {
      setEditedItem({ item, index });
      equipmentChangeHandler(item?.equipment);
      unitPriceChangeHandler(item?.unitPrice);
      setType(item?.type);
      quantityChangeHandler(item?.quantity);
      noOfHoursChangeHandler(item?.noOfHours);
      scrollToBottom();
    }
  };
  const applyItem = () => {
    const { item, index } = editedItem;
    if (item && formIsValid) {
      const updatedEquipment = equipments.map((item, i) => {
        if (index === i) {
          if (type === "PURCHASED") {
            noOfHoursChangeHandler(0);
          } else if (type === "OWNED") {
            noOfHoursChangeHandler(0);
            unitPriceChangeHandler(0);
          }
          if (editMode) {
            return {
              ...item,
              equipment,
              unitPrice:
                type === "RENTED" || type === "PURCHASED" ? unitPrice : 0,
              quantity,
              noOfHours: type === "RENTED" ? noOfHours : 0,
              type,
            };
          } else {
            return {
              equipment,
              unitPrice:
                type === "RENTED" || type === "PURCHASED" ? unitPrice : 0,
              quantity,
              noOfHours: type === "RENTED" ? noOfHours : 0,
              type,
            };
          }
        } else return item;
      });

      setEquipments(updatedEquipment);
      if (!editMode) {
        storePhaseData({
          equipment: updatedEquipment,
        });
      }
      resetEquipment();
      resetUnitPrice();
      resetQuantity();
      resetNoOfHours();
      setEditedItem(null);
      scrollToBottom();
    }
  };
  return (
    <div className="space-y-3 flex flex-col justify-between h-fit">
      <div className="flex justify-between w-full">
        <h1 className="font-semibold">Equipment</h1>
        <Button
          type="light"
          className="!px-4 !py-2 !text-gray-600 !bg-gray-100 hover:!bg-gray-200"
          onClick={() => {
            setNewEquipmentModalShown(true);
          }}
        >
          <AddIcon />
          New Equipment
        </Button>
      </div>
      <Dropdown
        selectHandler={onEquipmentChange}
        onBlur={equipmentBlurHandler}
        value={equipment.name}
        // error={equipmentIsInValid}
        label="Equipment name"
        helperText={<>Equipment is required</>}
        placeholder="Eg. Mixer"
        items={allEquipment}
        propertyName="name"
        required={true}
        name="equipment"
        type="text"
      />
      <div className="flex space-x-6 pt-2">
        <Radio
          checked={type === "RENTED"}
          id="rented"
          label="To be rented"
          onChange={() => {
            setType("RENTED");
            scrollToBottom();
          }}
        />
        <Radio
          checked={type === "PURCHASED"}
          id="purchased"
          label="To be purchased"
          onChange={() => {
            setType("PURCHASED");
            scrollToBottom();
          }}
        />
        <Radio
          checked={type === "OWNED"}
          id="owned"
          label="Owned"
          onChange={() => {
            setType("OWNED");
            scrollToBottom();
          }}
        />
      </div>
      {type !== "OWNED" && (
        <Input
          value={unitPrice}
          onChange={unitPriceChangeHandler}
          onBlur={unitPriceBlurHandler}
          helperText={<>Unit price is required</>}
          label={`Unit price ${type === "RENTED" ? "per hour" : ""}`}
          placeholder="1000"
          type="number"
        />
      )}
      <Input
        value={quantity}
        onChange={quantityChangeHandler}
        onBlur={quantityBlurHandler}
        // suffix={equipment.unit}
        helperText={<>Quantity is required</>}
        label="Quantity"
        placeholder="15"
        type="number"
      />
      {type === "RENTED" && (
        <Input
          value={noOfHours}
          onChange={noOfHoursChangeHandler}
          onBlur={noOfHoursBlurHandler}
          suffix="hrs"
          helperText={<>Number of hours is required</>}
          label="Number of hours per unit"
          placeholder="40"
          type="number"
        />
      )}
      {editedItem !== null ? (
        <span className="flex justify-between">
          <button
            disabled={
              !(equipmentIsValid && unitPriceIsValid && quantityIsValid)
            }
            type="button"
            className="mt-2 disabled:text-secondary/70 hover:text-primary text-secondary flex items-center p-1 text-sm"
            onClick={() => applyItem()}
          >
            <Check2 className="mr-1 text-[15px]" />
            Update item
          </button>
          <button
            disabled={
              !(
                equipmentIsValid &&
                unitPriceIsValid &&
                quantityIsValid &&
                noOfHoursIsValid
              )
            }
            type="button"
            className="mt-2 disabled:text-red-400 hover:text-red-500 text-red-500/80 flex items-center p-1 text-sm"
            onClick={() => {
              setEditedItem(null);
              resetEquipment();
              resetQuantity();
              resetNoOfHours();
              resetUnitPrice();
            }}
          >
            <X className="mr-1 text-[15px]" />
            Cancel
          </button>
        </span>
      ) : (
        <button
          disabled={
            !(type === "RENTED"
              ? equipmentIsValid &&
                unitPriceIsValid &&
                quantityIsValid &&
                noOfHoursIsValid
              : type === "PURCHASED"
              ? equipmentIsValid && unitPriceIsValid && quantityIsValid
              : type === "OWNED"
              ? equipmentIsValid && quantityIsValid
              : false)
          }
          type="button"
          className="mt-2 disabled:text-secondary/70 hover:text-primary text-secondary flex items-center p-1 text-sm"
          onClick={addItem}
        >
          <PlusCircleFill className="mr-1 text-[15px]" />
          Add item
        </button>
      )}
      <div>
        {equipments.length !== 0 && (
          <table className="max-w-full horizontal-scrollbar block overflow-auto text-xs text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Equipment
                </th>
                <th scope="col" className="px-6 py-3">
                  Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Quantity
                </th>
                <th scope="col" className="px-6 py-3">
                  Unit price
                </th>
                <th scope="col" className="px-6 py-3">
                  No of hours
                </th>
                <th scope="col" className=""></th>
              </tr>
            </thead>
            <tbody>
              {equipments.map((item, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                >
                  <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                    {item.equipment?.name}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                    {item.type === "RENTED"
                      ? "To be rented"
                      : item.type === "PURCHASED"
                      ? "To be purchased"
                      : "Owned"}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                    {item.quantity}
                    {/* {item.unit || item.equipment.unit} */}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                    {item.unitPrice
                      ? parseFloat(item.unitPrice).toLocaleString()
                      : "-"}
                    {item.type === "RENTED" && "/hr"}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                    {item.noOfHours || "-"}
                  </td>
                  <td className="flex">
                    <IconButton
                      className="border-none hover:bg-gray-50 text-gray-400 w-6 h-6"
                      type="rounded"
                      onClick={() => selectItem(item, index)}
                    >
                      <EditIcon className="!w-4 !h-4" />
                    </IconButton>
                    <IconButton
                      className="group-hover:flex border-none bg-white/50 hover:bg-white/70 text-red-500 w-6 h-6"
                      type="rounded"
                      onClick={() => removeItem(index)}
                    >
                      <DeleteIcon className="!w-4 !h-4" />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {newEquipmentModalShown && (
          <NewEquipment
            refetch={refetch}
            setSuccess={() => {}}
            setError={() => {}}
            isShown={newEquipmentModalShown}
            hideModal={hideModal}
            selectedMaterial={null}
            editMode={editMode}
          />
        )}
      </div>
    </div>
  );
};

export default Equipments;
