import React from "react";

<button
  type="button"
  className="text-primary border border-primary hover:bg-primary hover:text-white focus:ring-2 focus:outline-none focus:ring-primary font-medium rounded-full text-sm p-2.5 text-center inline-flex justify-center items-center dark:border-primary dark:text-primary dark:hover:text-white dark:focus:ring-primary dark:hover:bg-primary"
>
  <svg
    aria-hidden="true"
    className="w-5 h-5"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
      clipRule="evenodd"
    ></path>
  </svg>
  <span className="sr-only">Icon description</span>
</button>;

const IconButton = (props) => {
  if (props.type === "rectangle-filled") {
    return (
      <button
        onClick={props.onClick}
        type="button"
        className="text-white bg-primary hover:bg-primary focus:ring-2 focus:outline-none focus:ring-primary font-medium rounded-lg text-sm p-2.5 text-center inline-flex justify-center items-center dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary"
      >
        {props.children}
        <span className="sr-only">Icon description</span>
      </button>
    );
  } else if (props.type === "rounded-filled") {
    return (
      <button
        onClick={props.onClick}
        disabled={props.disabled}
        type="button"
        className={`text-white disabled:cursor-not-allowed disabled:hover:bg-primary/20 focus:ring-2 focus:outline-none bg-primary/20 hover:bg-primary/30 ring-primary/40 font-medium rounded-full text-sm p-2.5 text-center inline-flex justify-center items-center dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary ${props.className}`}
      >
        {props.children}
        <span className="sr-only">Icon description</span>
      </button>
    );
  } else if (props.type === "rectangle") {
    return (
      <button
        onClick={props.onClick}
        type="button"
        className="text-inherit border border-gray-300 hover:bg-primary hover:text-white focus:ring-2 focus:outline-none focus:ring-primary font-medium rounded-lg text-sm p-2.5 text-center inline-flex justify-center items-center dark:border-primary dark:text-primary dark:hover:text-white dark:focus:ring-primary dark:hover:bg-primary"
      >
        {props.children}
        <span className="sr-only">Icon description</span>
      </button>
    );
  } else if (props.type === "rounded") {
    return (
      <button
        onClick={props.onClick}
        type="button"
        className={`border border-gray-300 hover:bg-gray-200 focus:outline-none font-medium rounded-full text-sm p-2.5 text-center inline-flex justify-center items-center dark:border-primary dark:text-primary dark:hover:text-white dark:focus:ring-primary dark:hover:bg-primary ${props.className}`}
      >
        {props.children}
        <span className="sr-only">Icon description</span>
      </button>
    );
  } else {
    return (
      <button
        onClick={props.onClick}
        id="dropdownMenuIconButton"
        data-dropdown-toggle="dropdownDots"
        className={`inline-flex justify-center items-center text-dark-gray p-2 text-sm font-medium text-center rounded-lg hover:bg-gray-100 hover:text-gray-700 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600 ${props.className}`}
        type="button"
      >
        {props.children}
      </button>
    );
  }
};

export default IconButton;
