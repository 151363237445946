import axios from "axios";

const uploadImages = async ({ images, setUploadProgress, setError }) => {
  const newImages = [];
  try {
    const response = await axios.post(
      `${
        process.env.NODE_ENV === "development"
          ? process.env.REACT_APP_DEVELOPMENT_URL
          : process.env.REACT_APP_PRODUCTION_URL
      }/api/upload/upload-images`,
      images,
      {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          console.log(progress);
          setUploadProgress(progress);
        },
      }
    );
    setUploadProgress(0);
    response.data.map((image) => {
      newImages.push(
        `${
          process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_DEVELOPMENT_URL
            : process.env.REACT_APP_PRODUCTION_URL
        }/uploads/images/${image.filename}`
      );
    });
    console.log("Axios response: ", response);
  } catch (error) {
    setUploadProgress(0);
    setError(error.message);
    console.log(error);
  }
  return newImages;
};

const uploadFiles = async ({ files, setUploadProgress, setError }) => {
  console.log(files);
  const newFiles = [];
  try {
    const response = await axios.post(
      `${
        process.env.NODE_ENV === "development"
          ? process.env.REACT_APP_DEVELOPMENT_URL
          : process.env.REACT_APP_PRODUCTION_URL
      }/api/upload/upload-files`,
      files,
      {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          console.log(progress);
          setUploadProgress(progress);
        },
      }
    );
    setUploadProgress(0);
    response.data.map((file) => {
      const arr = file.filename.split(".");
      const type = arr[arr.length - 1];
      newFiles.push({
        url: `${
          process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_DEVELOPMENT_URL
            : process.env.REACT_APP_PRODUCTION_URL
        }/uploads/files/${file.filename}`,
        name: file.originalname,
        size: file.size,
        type,
      });
    });
    console.log("Axios response: ", response);
  } catch (error) {
    setUploadProgress(0);
    setError(error.message);
    console.log(error);
  }
  return newFiles;
};

const uploadImage = async ({ image, setUploadProgress, setError }) => {
  const newImage = null;
  try {
    const response = await axios.post(
      `${
        process.env.NODE_ENV === "development"
          ? process.env.REACT_APP_DEVELOPMENT_URL
          : process.env.REACT_APP_PRODUCTION_URL
      }/api/upload/upload-image`,
      image,
      {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          console.log(progress);
          setUploadProgress(progress);
        },
      }
    );
    setUploadProgress(0);
    console.log("Axios response: ", response);
    return `${
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_URL
        : process.env.REACT_APP_PRODUCTION_URL
    }/uploads/images/${response.data.filename}`;
  } catch (error) {
    setUploadProgress(0);
    setError(error.message);
    console.log(error);
  }
  return newImage;
};
export { uploadImages, uploadImage, uploadFiles };
