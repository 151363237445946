import React from "react";
import {
  FileEarmarkExcel,
  FileEarmarkPdf,
  FileEarmarkRichtext,
  FileEarmarkText,
  FileEarmarkWord,
} from "react-bootstrap-icons";

const File = ({ type }) => {
  if (type === "pdf") {
    return <FileEarmarkPdf className="text-dark-red text-2xl" />;
  } else if (type === "doc" || type === "docx") {
    return <FileEarmarkWord className="text-dark-blue text-2xl" />;
  } else if (type === "xlsx") {
    return <FileEarmarkExcel className="text-secondary text-2xl" />;
  } else if (type === "jpg" || type === "png") {
    return <FileEarmarkRichtext className="text-dark-orange text-2xl" />;
  } else {
    return <FileEarmarkText className="text-dark-blue text-2xl" />;
  }
};

export default File;
