import React, { useEffect, useRef, useState } from "react";
import moment from "moment";

const TimeCounter = ({ date, className }) => {
  const targetTime = moment(date);
  const [currentTime, setCurrentTime] = useState(moment());
  const timeBetween = moment.duration(targetTime.diff(currentTime));
  console.log(timeBetween);
  useEffect(() => {
    if (timeBetween._milliseconds < 0) {
      return;
    }
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, [timeBetween._milliseconds]);
  return (
    <span className={className}>
      <span className="flex w-full space-x-1 justify-evenly">
        {/* {timeBetween.months() !== 0 && ( */}
        <span className="flex flex-col items-center justify-center">
          <span className="text-dark-gray text-[10px]">Months</span>
          <span className="text-gray-700 font-semibold text-sm bg-light-gray rounded-md p-1">
            {timeBetween.months() + timeBetween.years() * 12 > 0
              ? timeBetween.months() + timeBetween.years() * 12 > 9
                ? timeBetween.months() + timeBetween.years() * 12
                : "0" + timeBetween.months()
              : "00"}
          </span>
        </span>
        {/* // )} */}
        <span className="flex flex-col items-center justify-center">
          <span className="text-dark-gray text-[10px]">Days</span>
          <span className="text-gray-700 font-semibold text-sm bg-light-gray rounded-md p-1">
            {timeBetween.days() > 0
              ? timeBetween.days() > 9
                ? timeBetween.days()
                : "0" + timeBetween.days()
              : "00"}
          </span>
        </span>
        <span className="flex flex-col items-center justify-center">
          <span className="text-dark-gray text-[10px]">Hours</span>
          <span className="text-gray-700 font-semibold text-sm bg-light-gray rounded-md p-1">
            {timeBetween.hours() > 0
              ? timeBetween.hours() > 9
                ? timeBetween.hours()
                : "0" + timeBetween.hours()
              : "00"}
          </span>
        </span>
        <span className="flex flex-col items-center justify-center">
          <span className="text-dark-gray text-[10px]">Mins</span>
          <span className="text-gray-700 font-semibold text-sm bg-light-gray rounded-md p-1">
            {timeBetween.minutes() > 0
              ? timeBetween.minutes() > 9
                ? timeBetween.minutes()
                : "0" + timeBetween.minutes()
              : "00"}
          </span>
        </span>
        <span className="flex flex-col items-center justify-center">
          <span className="text-dark-gray text-[10px]">Secs</span>
          <span className="text-gray-700 font-semibold text-sm bg-light-gray rounded-md p-1">
            {timeBetween.seconds() > 0
              ? timeBetween.seconds() > 9
                ? timeBetween.seconds()
                : "0" + timeBetween.seconds()
              : "00"}
          </span>
        </span>
      </span>
    </span>
  );
};

export default TimeCounter;
