import React from "react";
import Card from "../Card";
import Progress from "../Progress";

const MilestoneCard = (props) => {
  return (
    <div className={`shadow-md flex items-center px-2 py-4 rounded-lg`}>
      <div className={`flex flex-col w-full`}>
        <span className="flex justify-between items-center">
          <h1 className="font-semibold">{props.title}</h1>
          <span className="text-dark-gray text-[11px] whitespace-nowrap">
            Due<span className="font-bold ml-1">{props.date}</span>
          </span>
        </span>
        <div className="">
        {/* <span
          className={`${props.bg} ${props.textColor} font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center`}
        >
          {props.icon}
        </span> */}
          <div className="mt-3">
            <Progress className="w-full" progress={props.progress}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MilestoneCard;
