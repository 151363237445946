import React, { useEffect } from "react";
import Input from "../UI/Input";
import Modal from "../UI/Modal";
import Button from "../UI/Button";
import useInput from "../../hooks/use-input";
import { gql, useMutation } from "@apollo/client";
import { useProject } from "../../store/project-context";
import Dropdown from "../UI/Dropdown";
import { useAuthUser } from "react-auth-kit";

const INSERT_EQUIPMENT = gql`
  mutation MyMutation($object: projects_equipments_insert_input!) {
    insert_projects_equipments_one(object: $object) {
      id
    }
  }
`;

const UPDATE_EQUIPMENT = gql`
  mutation MyMutation($id: uuid!, $_set: projects_equipments_set_input!) {
    update_projects_equipments_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;

const NewEquipment = (props) => {
  const { currentProject, company } = useProject();
  const { selectedEquipment, editMode, setError, setSuccess, refetch } = props;

  const [
    updateEquipment,
    { loading: isEquipmentUpdating, error: updateEquipmentError },
  ] = useMutation(UPDATE_EQUIPMENT);
  const [
    addEquipment,
    { loading: isEquipmentSubmitting, error: insertEquipmentError },
  ] = useMutation(INSERT_EQUIPMENT);
  const {
    value: name,
    isValid: nameIsValid,
    isInvalid: nameIsInValid,
    inputChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    reset: resetName,
  } = useInput((value) => value.trim() !== "");

  const {
    value: unit,
    isValid: unitIsValid,
    isInvalid: unitIsInValid,
    inputChangeHandler: unitChangeHandler,
    inputBlurHandler: unitBlurHandler,
    reset: resetUnit,
  } = useInput((value) => value.trim() !== "");

  const {
    value: unitPrice,
    isValid: unitPriceIsValid,
    isInvalid: unitPriceIsInValid,
    inputChangeHandler: unitPriceChangeHandler,
    inputBlurHandler: unitPriceBlurHandler,
    reset: resetUnitPrice,
  } = useInput((value) => value !== "");

  const {
    value: status,
    isValid: statusIsValid,
    isInvalid: statusIsInValid,
    inputChangeHandler: statusChangeHandler,
    inputBlurHandler: statusBlurHandler,
    reset: resetStatus,
  } = useInput((value) => value.trim() !== "");

  const formIsValid =
    nameIsValid && unitIsValid && unitPriceIsValid && statusIsValid;

  const auth = useAuthUser();
  const user = auth();
  const submitHandler = async (e) => {
    if (!currentProject?.id) {
      setError("No project selected");
      return;
    }
    let equipmentData = {};
    if (user?.roles?.map((role) => role.code).includes("SU")) {
      equipmentData = {
        name,
        unit,
        unitPrice,
        status: status.toLocaleUpperCase(),
      };
    } else {
      equipmentData = {
        name,
        unit,
        unitPrice,
        status: status.toLocaleUpperCase(),
        companyId: company.id,
      };
    }
    try {
      if (selectedEquipment) {
        await updateEquipment({
          variables: {
            id: selectedEquipment.id,
            _set: equipmentData,
          },
        });
      } else {
        const response = await addEquipment({
          variables: { object: equipmentData },
        });
      }
      if (!insertEquipmentError && !editMode) {
        setSuccess("Equipment added successfully");
      } else if (!updateEquipmentError) {
        setSuccess("Equipment updated successfully");
      }
      hideModal();
      refetch();
    } catch (e) {
      setError(e?.message);
      console.log(e);
    }
  };
  useEffect(() => {
    if (selectedEquipment && editMode) {
      nameChangeHandler(selectedEquipment?.name);
      unitChangeHandler(selectedEquipment?.unit);
      unitPriceChangeHandler(selectedEquipment?.unitPrice);
      statusChangeHandler(
        selectedEquipment?.status[0] +
          selectedEquipment?.status
            .toLocaleLowerCase()
            .substring(1, selectedEquipment?.status.length)
      );
    }
  }, [
    editMode,
    selectedEquipment,
    nameChangeHandler,
    unitChangeHandler,
    unitPriceChangeHandler,
    statusChangeHandler,
  ]);
  const hideModal = () => {
    resetName();
    resetUnit();
    resetUnitPrice();
    resetStatus();
    props.hideModal();
  };
  if (props.isShown) {
    return (
      <>
        <Modal
          headerIsShown={true}
          modalHeight="h-[600px]"
          isShown={true} //props.isShown
          hideModal={hideModal}
          modalTitle={editMode ? "Edit Equipment" : "Create New Equipment"}
          modalBottom={
            <div className="flex justify-end mr-4">
              <Button className="mr-3" type="light" onClick={hideModal}>
                Cancel
              </Button>
              <Button
                disabled={!formIsValid}
                isLoading={isEquipmentSubmitting || isEquipmentUpdating}
                onClick={submitHandler}
                type="submit"
              >
                {editMode ? "Update" : "Save"}
              </Button>
            </div>
          }
        >
          <p className="text-dark-gray text-sm ">
            {editMode
              ? "Edit this equipment"
              : "Add equipments into this project and use them to track your phases"}
          </p>
          <form className="space-y-3 mt-5 flex flex-col justify-between h-fit">
            <Input
              onChange={nameChangeHandler}
              onBlur={nameBlurHandler}
              value={name}
              error={nameIsInValid}
              label="Equipment name"
              helperText={<>Equipment name is required</>}
              placeholder="Eg. Mixer"
              required={true}
              name="name"
              type="text"
            />
            <Input
              value={unit}
              onChange={unitChangeHandler}
              onBlur={unitBlurHandler}
              error={unitIsInValid}
              helperText={<>Unit is required</>}
              label="Unit"
              placeholder="Eg. Hrs"
            />
            <Input
              value={unitPrice}
              onChange={unitPriceChangeHandler}
              onBlur={unitPriceBlurHandler}
              error={unitPriceIsInValid}
              helperText={<>Unit price is required</>}
              label="Unit price"
              placeholder="Eg. 1000"
              type="number"
            />
            <Dropdown
              selectHandler={statusChangeHandler} // addstatus for multiple
              onBlur={statusBlurHandler}
              value={status}
              error={statusIsInValid}
              id="status"
              label="Status"
              optionsTop={true}
              helperText={<>Status is required</>}
              items={["Working", "Not working", "Out of service"]}
              errorMessage={status?.error?.message}
            />
          </form>
        </Modal>
      </>
    );
  } else {
    return <></>;
  }
};

export default NewEquipment;
