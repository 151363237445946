import React from "react";
import Spinner from "./Spinner";

const Button = (props) => {
  if (props.type === "alternative") {
    return (
      <button
        type="button"
        onClick={props.onClick}
        disabled={props.isLoading || props.disabled}
        className={`py-2.5 px-5 flex items-center justify-center min-w-[6rem] text-sm font-medium text-gray-600 hover:text-gray-700 focus:outline-none bg-gray-100 hover:bg-gray-200 rounded-lg focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 ${props.className}`}
      >
        {props.children}
      </button>
    );
  } else if (props.type === "x-small") {
    return (
      <button
        type="button"
        onClick={props.onClick}
        disabled={props.isLoading || props.disabled}
        className={`px-3 py-2 text-xs font-medium text-center text-white bg-primary rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ${props.className}`}
      >
        {props.children}
      </button>
    );
  } else if (props.type === "light") {
    return (
      <button
        type="button"
        onClick={props.onClick}
        disabled={props.isLoading || props.disabled}
        className={`disabled:bg-primary/60 min-w-[6rem] disabled:cursor-not-allowed text-gray-800 bg-primary/20 hover:bg-primary/30 rounded-lg text-sm px-5 py-2.5 focus:ring-4 focus:outline-none focus:ring-primary/30 dark:bg-primary dark:hover:bg-primary/80 dark:focus:ring-blue-800 ${props.className}`}
      >
        {/* <button type="button" className="">Light</button> */}
        {props.children}
      </button>
    );
  } else {
    return (
      <button
        type="button"
        onClick={props.onClick}
        disabled={props.isLoading || props.disabled}
        className={`disabled:bg-primary/60 min-w-[6rem] disabled:cursor-not-allowed text-white bg-primary hover:bg-primary/80 focus:ring-4 focus:ring-primary/60 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-primary dark:hover:bg-primary/80 focus:outline-none dark:focus:ring-blue-800 ${props.className}`}
      >
        {props.isLoading ? <Spinner type="light" /> : props.children}
      </button>
    );
  }
};

export default Button;

{
  /* <button type="button" className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">Dark</button>

<button type="button" className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Green</button>
<button type="button" className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Red</button>
<button type="button" className="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-yellow-900">Yellow</button>
<button type="button" className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Purple</button> */
}
