import React from "react";
import { FolderFill } from "react-bootstrap-icons";

const CardSkeleton = (props) => {
  return (
    <div
      role="status"
      className={`max-w-sm p-2 h-32 col-span-3 border rounded-lg animate-pulse dark:border-gray-700 ${props.className}`}
    >
      <span className="flex items-center">
        <FolderFill className="text-3xl text-gray-200" />
        <div className="h-4 w-16 ml-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </span>
      <div className="text-xs mt-8">
        <div className="h-2 w-8 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
        <div className="h-2 w-8 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
      </div>
    </div>
  );
};

export default CardSkeleton;
