export function isObjectEmpty(ob) {
  return Object.values(ob).length === 0;
}

export function isValidDate(date) {
  return (
    date &&
    Object.prototype.toString.call(date) === "[object Date]" &&
    !isNaN(date)
  );
}
export function checkRoles(user, requiredRoles) {
  if (!user) return;
  for (let i = 0; i < requiredRoles.length; i++) {
    if (user.roles.find((role) => role.code === requiredRoles[i])) {
      return true;
    }
  }
  return false;
}
