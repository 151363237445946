import { useEffect, useState } from "react";
import IconButton from "./IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import useKeypress from "react-use-keypress";

const FullScreenSlideshow = (props) => {
  const { items, selectedImage, onClose } = props;
  const [index, setIndex] = useState(items.indexOf(selectedImage)!==-1 ? items.indexOf(selectedImage) : 0);
  document.body.style.overflowY = "hidden";
  useKeypress(["ArrowLeft", "ArrowRight"], (event) => {
    if (event.key === "ArrowLeft") {
      slideLeft();
    } else {
      slideRight();
    }
  });
  const slideLeft = () => {
    setIndex((index) => index - 1);
    setTimeout(() => {
      index === 0 && setIndex(0);
    }, 300);
  };
  const slideRight = () => {
    setIndex((index) => index + 1);
    setTimeout(() => {
      index === items.length - 1 && setIndex(items.length - 1);
    }, 300);
  };
  return (
    <div
      className={`z-30 group fixed left-0 top-0 bg-black/70 w-screen h-screen overflow-hidden bg-clip-padding bg-opacity-20 ${props.className}`}
      style={{ backdropFilter: `blur(5px)` }}
    >
      <IconButton
        onClick={() => {
          document.body.style.overflowY = "auto";
          onClose();
        }}
        type="rounded"
        className="hidden group-hover:block text-light-gray hover:text-white hover:bg-gray-50/10 border-none z-30 absolute right-2 top-2"
      >
        <CloseIcon className="!w-6 !h-6" />
      </IconButton>
      <div
        className="absolute left-0 top-0 whitespace-nowrap w-full h-full transition ease duration-500"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {items.map((item, index) => (
          <>
            <div key={index} className="relative inline-block w-full h-full">
              <img
                className="w-4/5 h-full m-auto object-contain"
                src={item.url}
                alt="Project"
                onClick={() => {
                  document.body.style.overflowY = "auto";
                  onClose();
                }}
              />
              <div className="hidden group-hover:block break-words whitespace-break-spaces absolute bottom-5 bg-black/60 text-xs text-light-gray left-1/2 -translate-x-1/2 rounded-md z-30 mx-auto max-w-[50%] p-3">
                <p>{item.description}</p>
              </div>
              <div className="hidden group-hover:block absolute left-2 bottom-2 text-xs text-light-gray rounded-md z-30 mx-auto">
                <p>Image {index + 1 + "/" + items.length}</p>
                <p>{moment(item.created_at).format("LLL")}</p>
              </div>
            </div>
          </>
        ))}
      </div>
      <IconButton
        onClick={slideLeft}
        type="rounded"
        className="hidden group-hover:block -translate-y-1/2 border-none hover:bg-gray-50/10 absolute left-3 top-1/2"
      >
        <ChevronLeftIcon className="text-light-gray hover:text-white text-2xl !w-10 !h-10" />
      </IconButton>
      <IconButton
        onClick={slideRight}
        type="rounded"
        className="hidden group-hover:block -translate-y-1/2 border-none hover:bg-gray-50/10 absolute right-3 top-1/2"
      >
        <ChevronRightIcon className="text-light-gray hover:text-white text-2xl !w-10 !h-10" />
      </IconButton>
    </div>
  );
};

export default FullScreenSlideshow;
