import React from "react";
import { bytesToSize } from "../../../functions/extra";
import FileIcon from "../FileIcon";

const FileInfo = ({ name, size, type }) => {
  return (
    <div className="flex w-full items-center">
      <FileIcon type={type}/>
      <div className="m-2">
        <p className="font-medium text-sm">{name}</p>
        <p className="text-xs text-dark-gray">{bytesToSize(size)}</p>
      </div>
    </div>
  );
};

export default FileInfo;
