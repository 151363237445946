import { Tabs, Tab } from "../components/UI/Tabs";
import sitesIllustration from "../assets/illustrations/sites.svg";
import Button from "../components/UI/Button";
import AddIcon from "@mui/icons-material/Add";
import CardSkeleton from "../components/UI/skeleton/CardSkeleton";
import NewSite from "../components/Forms/NewSite";
import AddUser from "../components/Forms/AddUser";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Card from "../components/UI/Card";
import { Warning } from "@mui/icons-material";
import { gql, useMutation } from "@apollo/client";
import LaunchIcon from "@mui/icons-material/Launch";
import SiteCard from "../components/UI/SiteCard";
import Toast from "../components/UI/Toast";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Confirm from "../components/UI/Confirm";
import Spinner from "../components/UI/Spinner";
import { useProject } from "../store/project-context";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../store/user-context";
import Image from "../components/UI/Image";
import { useAuthUser } from "react-auth-kit";
import { Person, X } from "react-bootstrap-icons";

const DELETE_SITE = gql`
  mutation MyMutation($id: uuid!, $deleted_at: timestamptz!) {
    update_sites_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: $deleted_at }
    ) {
      id
    }
  }
`;

const DELETE_SITE_USER = gql`
  mutation MyMutation($id: uuid!) {
    delete_users_user_sites_by_pk(id: $id) {
      id
    }
  }
`;
const SitesPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const users = useUser();
  const [selectedSite, setSelectedSite] = useState(null);
  const [newSiteModalShown, setNewSiteModalShown] = useState(
    location.state?.addSite
  );
  const [isConfirmSiteDeleteShown, setIsConfirmSiteDeleteShown] =
    useState(false);
  const [isConfirmSiteUserDeleteShown, setIsConfirmSiteUserDeleteShown] =
    useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [addUserModalShown, setAddUserModalShown] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [userSiteToBeDeleted, setUserSiteToBeDeleted] = useState(null);
  const cardRef = useRef();
  const tabRef = useRef();
  const auth = useAuthUser();
  const user = auth();
  useEffect(() => {
    if (location?.state?.addSite) {
      setNewSiteModalShown(true);
    }
  });
  const { sites, sitesLoading, sitesError, refetchSites } = useProject();
  useEffect(() => {
    console.log(sites);
    if (selectedSite) {
      sites.map((site) => {
        if (site.id === selectedSite.id) {
          console.log(site);
          setSelectedSite(site);
          return;
        }
      });
    }
  }, [sites]);
  const [deleteSite, { loading: isSiteDeleting, error: deleteSiteError }] =
    useMutation(DELETE_SITE);

  const [
    deleteSiteUser,
    { loading: isSiteUserDeleting, error: deleteSiteUserError },
  ] = useMutation(DELETE_SITE_USER);
  const deleteSiteHandler = async () => {
    if (selectedSite === null) {
      return;
    }
    try {
      await deleteSite({
        variables: { id: selectedSite.id, deleted_at: new Date() },
      });
      if (!deleteSiteError) {
        refetchSites();
        setSelectedSite(null);
        setIsConfirmSiteDeleteShown(false);
        setSuccess("Site deleted successfully");
        users?.refetch();
      }
    } catch (e) {
      console.log(e);
      setError(e.message);
    }
  };
  const deleteSiteUserHandler = async () => {
    if (!selectedSite || !userSiteToBeDeleted) {
      return;
    }
    try {
      await deleteSiteUser({
        variables: { id: userSiteToBeDeleted.id },
      });
      if (!deleteSiteUserError) {
        refetchSites();
        setIsConfirmSiteUserDeleteShown(false);
        setSuccess("User removed successfully");
        users?.refetch();
      }
    } catch (e) {
      console.log(e);
      setError(e.message);
    }
  };
  const showDeleteSiteConfirmation = () => {
    setIsConfirmSiteDeleteShown(true);
  };
  const showDeleteSiteUserConfirmation = (site_user) => {
    setUserSiteToBeDeleted(site_user);
    setIsConfirmSiteUserDeleteShown(true);
  };
  const hideModal = () => {
    setSelectedSite(null);
    setEditMode(false);
    setNewSiteModalShown(false);
    navigate("/sites", { state: { addSite: false } });
  };
  const selectSite = (site) => {
    if (site === null || site === selectedSite) {
      setSelectedSite(null);
      return;
    }
    setSelectedSite(site);
    cardRef.current && cardRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const refetch = () => {
    refetchSites();
  };
  const showAddUserModal = (role) => {
    setAddUserModalShown(true);
    setSelectedRole(role);
  };
  return (
    <div ref={cardRef} className="m-3 grid grid-cols-12">
      {isConfirmSiteDeleteShown && !deleteSiteError && (
        <Confirm
          confirmButtonText={
            isSiteDeleting ? <Spinner type="light" /> : "Delete"
          }
          isLoading={isSiteDeleting}
          confirmTitle="Delete"
          onClick={deleteSiteHandler}
          onCancel={() => {
            setIsConfirmSiteDeleteShown(false);
          }}
        >
          Are you sure you want to delete this site?
        </Confirm>
      )}
      {isConfirmSiteUserDeleteShown && (
        <Confirm
          confirmButtonText={
            isSiteUserDeleting ? <Spinner type="light" /> : "Delete"
          }
          isLoading={isSiteUserDeleting}
          confirmTitle="Delete"
          onClick={deleteSiteUserHandler}
          onCancel={() => {
            setIsConfirmSiteUserDeleteShown(false);
          }}
        >
          Are you sure you want to remove {userSiteToBeDeleted?.user?.fullName}{" "}
          from the site?
        </Confirm>
      )}
      <Tabs
        tabRef={tabRef}
        className={`${selectedSite ? "col-span-9" : "col-span-12"}`}
        otherElements={
          user.roles.find((role) => role.code === "ADM") && (
            <Button
              onClick={() => {
                setNewSiteModalShown(true);
                setSelectedSite(null);
              }}
            >
              <AddIcon />
              New Site
            </Button>
          )
        }
      >
        <Tab label="All Sites">
          <div
            className={`grid grid-cols-12 gap-4 items-start min-h-[60vh] p-2 mb-2`}
          >
            {sitesError ? (
              <p className="col-span-full mt-5 flex justify-center items-center">
                <Warning className="!w-4 !h-4 mr-1" /> {sitesError?.message}
              </p>
            ) : sitesLoading && !sitesError ? (
              <>
                {[1, 2, 3, 4, 5, 6].map((i) => (
                  <CardSkeleton key={i} className="col-span-4 2xl:col-span-3" />
                ))}
              </>
            ) : !sites?.length ? (
              <div className="space-y-2 self-center col-start-5 col-end-9 flex flex-col justify-center items-center">
                <img className="w-72 object-cover" src={sitesIllustration} />
                <p className="text-gray-700 text-2xl !mt-5">No sites found</p>
                <p className="text-dark-gray mt-5 text-center text-sm">
                  Start by creating your first site with in few steps
                </p>
              </div>
            ) : (
              <>
                {!sitesLoading &&
                  !sitesError &&
                  sites?.map((site, index) => (
                    <SiteCard
                      className="col-span-6 2xl:col-span-4"
                      onClick={() => selectSite(site)}
                      key={site.id}
                      site={site}
                      selectedSite={selectedSite}
                    />
                  ))}
              </>
            )}
          </div>
        </Tab>
        <Tab label=""></Tab>
      </Tabs>

      <Card
        // cardRef={cardRef}
        className={`sticky vertical-scrollbar top-0 right-0 flex flex-col items-center overflow-x-hidden overflow-y-auto max-h-screen ml-3 space-y-3 transition-all duration-200 ${
          selectedSite
            ? "translate-x-0 col-span-3"
            : "translate-x-full !bg-transparent"
        }`}
      >
        {selectedSite ? (
          <Fragment>
            <h1 className="text-start w-full text-lg font-medium">
              Site details
            </h1>
            <ul className="w-full text-xs space-y-3 p-2">
              <li className="flex items-center border-b pb-2">
                <span className="flex flex-col">
                  <span className="text-dark-gray text-xl">
                    {selectedSite.name}
                  </span>
                  <span className="text-primary flex items-center mt-1">
                    <LocationOnOutlinedIcon className="!w-4 !h-4" />
                    {selectedSite.location}
                  </span>
                </span>
              </li>
              {selectedSite.image && (
                <Image
                  width="w-full"
                  // height="h-full"
                  // skeletonWidth="w-10"
                  // skeletonHeight="h-10"
                  src={selectedSite.image}
                  alt="Site"
                  className="object-contain rounded-md"
                />
              )}
              <li className="flex flex-col">
                <p className="mb-3 break-words text-start text-sm font-normal text-gray-500 dark:text-gray-400">
                  {selectedSite.description}
                </p>
              </li>
              <li className="flex flex-col">
                <span className="text-dark-gray mr-2">Project(s)</span>
                <span className="w-full flex gap-2 flex-wrap justify-start mt-2">
                  {selectedSite.projects.length > 0 ? (
                    selectedSite.projects.map((project) => (
                      <span
                        className="bg-primary/10 flex items-center text-primary text-xs rounded-lg px-3 py-1"
                        key={project.id}
                      >
                        <span className="font-medium">
                          {project?.projectName || "-"}
                        </span>
                      </span>
                    ))
                  ) : (
                    <p className="text-center font-medium">No projects found</p>
                  )}
                </span>
              </li>
              <li className="flex flex-col">
                <span className="mr-2">Site admin</span>
                <span className="w-full flex flex-wrap gap-2 justify-start mt-2">
                  {selectedSite.site_admins.length ? (
                    selectedSite.site_admins.map((site_admin) => (
                      <span
                        className="bg-primary/10 flex items-center text-primary text-xs rounded-lg px-3 py-1"
                        key={site_admin.user.id}
                      >
                        {site_admin?.user?.photoURL && (
                          <img
                            className="w-4 h-4 mr-1 rounded-full"
                            src={site_admin?.user?.photoURL}
                            alt="Site admin"
                          />
                        )}
                        <span className="font-medium">
                          {site_admin?.user?.fullName || "-"}
                        </span>
                        <button
                          onClick={() =>
                            showDeleteSiteUserConfirmation(site_admin)
                          }
                          type="button"
                          className="text-lg ml-2 text-gray-500 hover:text-red-500"
                        >
                          <X />
                        </button>
                      </span>
                    ))
                  ) : (
                    <p className="text-dark-gray w-full">No site admin added</p>
                  )}
                  <Button
                    onClick={() => showAddUserModal("SITE_ADM")}
                    type="alternative"
                    className="!py-1 !px-1 focus:!ring-0 text-xs"
                  >
                    <Person className="!w-3 !h-3 mr-1" />
                    Add
                  </Button>
                </span>
              </li>
              <li className="flex flex-col">
                <span className="mr-2">Store keeper(s)</span>
                <span className="w-full flex flex-wrap gap-2 justify-start mt-2">
                  {selectedSite.store_keepers.length ? (
                    selectedSite.store_keepers.map((store_keeper) => (
                      <span
                        className="bg-primary/10 flex items-center text-primary text-xs rounded-lg px-3 py-1"
                        key={store_keeper.user.id}
                      >
                        {store_keeper?.user?.photoURL && (
                          <img
                            className="w-4 h-4 mr-1 rounded-full"
                            src={store_keeper?.user?.photoURL}
                            alt="Site admin"
                          />
                        )}
                        <span className="font-medium">
                          {store_keeper?.user?.fullName || "-"}
                        </span>
                        <button
                          onClick={() =>
                            showDeleteSiteUserConfirmation(store_keeper)
                          }
                          type="button"
                          className="text-lg ml-2 text-gray-500 hover:text-red-500"
                        >
                          <X />
                        </button>
                      </span>
                    ))
                  ) : (
                    <p className="text-dark-gray w-full">
                      No store keepers added
                    </p>
                  )}
                  <Button
                    onClick={() => showAddUserModal("ST_KP")}
                    type="alternative"
                    className="!py-1 !px-1 focus:!ring-0 text-xs"
                  >
                    <Person className="!w-3 !h-3 mr-1" />
                    Add
                  </Button>
                </span>
              </li>
              <div className="flex flex-col space-y-3 border-t pt-4">
                <Link to={`${selectedSite.id}/projects`}>
                  <Button className="shadow-sm w-full hover:shadow-lg !bg-blue-100/60 hover:!bg-blue-200/80 !text-blue-600 focus:!ring-0">
                    Go to Site
                    <LaunchIcon className="!w-4 !h-4 ml-2" />
                  </Button>
                </Link>
                <Button
                  className="shadow-sm hover:shadow-lg !bg-green-100/60 hover:!bg-green-200/80 !text-green-600 focus:!ring-0"
                  onClick={() => {
                    setNewSiteModalShown(true);
                    setEditMode(true);
                  }}
                >
                  Edit Site
                </Button>
                <Button
                  onClick={showDeleteSiteConfirmation}
                  className="shadow-sm hover:shadow-lg !bg-red-100/60 hover:!bg-red-200/80 !text-dark-red focus:!ring-0"
                >
                  Delete Site
                </Button>
              </div>
            </ul>
          </Fragment>
        ) : (
          <Fragment>
            {/* <img src={userDetails} alt="User details illustration" />
                  <p className="text-dark-gray text-sm">
                    Select a user to see details
                  </p> */}
          </Fragment>
        )}
      </Card>
      {user.roles.find(
        (role) => role.code === "ADM" || role.code === "SITE_ADM"
      ) && (
        <AddUser
          refetch={() => {
            refetch();
          }}
          setSuccess={setSuccess}
          setError={setError}
          selectedRole={selectedRole}
          isShown={addUserModalShown}
          hideModal={() => setAddUserModalShown(false)}
          selectedSite={selectedSite}
          editMode={editMode}
        />
      )}
      {user.roles.find((role) => role.code === "ADM") && (
        <NewSite
          refetch={refetchSites}
          setSuccess={setSuccess}
          setError={setError}
          isShown={newSiteModalShown}
          hideModal={hideModal}
          selectedSite={selectedSite}
          editMode={editMode}
        />
      )}
      <Toast
        type="error"
        show={error !== null}
        callback={() => {
          setError(null);
        }}
        message={error}
      />
      <Toast
        type="success"
        show={success !== null}
        callback={() => {
          setSuccess(null);
        }}
        message={success}
      />
    </div>
  );
};

export default SitesPage;
