import React from "react";

const Card = (props) => {
  return (
    <div
      className={`h-[132px] p-2 overflow-hidden drop-shadow-sm bg-white rounded-lg  dark:bg-gray-800 dark:border-gray-700 ${props.className}`}
    >
      <span className="flex items-center">
        <span className="text-white bg-primary font-medium rounded-lg text-sm p-1 text-center inline-flex items-center dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary">
          {props.icon}
        </span>
        <h5 className="ml-1.5 text-sm font-semibold whitespace-nowrap dark:text-white">
          {props.title}
        </h5>
      </span>
      <span>
        <p className="font-normal text-gray-700 dark:text-gray-400 mt-4">
          {props.content}
        </p>
        <p className="text-primary text-xs mt-2">{props.bottom}</p>
      </span>
    </div>
  );
};

export default Card;
