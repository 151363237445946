import React from "react";
import Page404 from "../assets/illustrations/page-not-found.svg";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="w-[400px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
      <img className="w-full" src={Page404} alt="Page not found" />
      <p className="text-center text-3xl mt-10">Page not found</p>
      <Link to='/' className="block text-primary hover:underline text-center mt-5 w-full">Return home</Link>
    </div>
  );
};

export default PageNotFound;
