import React from "react";

const DragAndDrop = (props) => {
  const [dragActive, setDragActive] = React.useState(false);
  const { onDrop, className, children } = props;
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        console.log(e.dataTransfer.files);
      // at least one file has been dropped so do something
      // handleFiles(e.dataTransfer.files);
      onDrop(e.dataTransfer.files);
    }
  };

  // handle drag events
  const handleDrag = function (e) {
    console.log("Dragged");
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  return (
    <label
      onDragEnter={handleDrag}
      className={`${dragActive ? "bg-gray-200" : "bg-slate-10"} ${className}`}
    >
      {children}
      {dragActive && (
        <div
          className="absolute w-full h-full top-0 left-0"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </label>
  );
};

export default DragAndDrop;
