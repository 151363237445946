import { useState, useEffect, Fragment } from "react";
import ReactDOM from "react-dom";
import { XCircle, CheckCircle } from "react-bootstrap-icons";
import { Transition } from "@headlessui/react";

const ToastMessage = (props) => {
  const callback = props.callback;
  useEffect(() => {
    const timeout = setTimeout(() => {
      callback();
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [props.show]);
  return (
    <Transition
      className="z-50 flex fixed right-5 top-5 items-center m-auto w-80 bg-gray-800"
      show={props.show}
      enter="transition-all ease-in-out duration-300 delay-[200ms]"
      enterFrom="opacity-0 translate-x-6"
      enterTo="opacity-100 translate-x-0"
      leave="transition-all ease-in-out duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={`rounded-sm border-l-2 ${
          props.type === "error" ? "border-l-rose-500" : "border-l-emerald-500"
        } flex justify-start items-center max-h-[100px] w-80 bg-neutral-800 p-3 text-white`}
      >
        {props.type === "success" && (
          <CheckCircle className="min-w-[2rem] h-8 text-emerald-500 mr-2" />
        )}
        {props.type === "error" && (
          <XCircle className="min-w-[2rem] h-8 text-rose-500 mr-2" />
        )}
        <p className="text-sm">{props.message}</p>
      </div>
    </Transition>
  );
};
const Toast = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ToastMessage
          type={props.type}
          callback={props.callback}
          show={props.show}
          message={props.message}
        />,
        document.getElementById("toast-root")
      )}
    </Fragment>
  );
};

export default Toast;
