import inactive from "../../assets/illustrations/engineer.svg";

const Inactive = () => {
  return (
    <div className="w-1/2 col-span-8 self-center justify-self-center">
      <img src={inactive} alt="No work" />
      <p className="text-2xl text-center mt-4">No work done</p>
    </div>
  );
};

export default Inactive;
