import React, { useEffect, useState } from "react";
import useInput from "../../../hooks/use-input";
import Input from "../../UI/Input";
import IconButton from "../../UI/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Dropdown from "../../UI/Dropdown";
import { Check2, PlusCircleFill, X } from "react-bootstrap-icons";
import { isObjectEmpty } from "../../../functions/validation";
import AddIcon from "@mui/icons-material/Add";
import NewLabor from "../NewLabor";
import Button from "../../UI/Button";
import { usePhase } from "../../../store/phase-context";

const Labor = (props) => {
  const {
    scrollToBottom,
    labors,
    setLabor,
    refetch,
    allLabor: allLaborProp,
    setSteps,
    steps,
    step,
    editMode,
    deletedItems,
    setDeletedItems,
    otherData,
  } = props;
  const [editedItem, setEditedItem] = useState(null);
  const [newLaborModalShown, setNewLaborModalShown] = useState(false);
  const [allLabor, setAllLabor] = useState(allLaborProp);
  const { storePhaseData, calculateTotalCost } = usePhase();
  const {
    value: labor,
    isValid: laborIsValid,
    // isInvalid: typeIsInValid,
    inputChangeHandler: laborChangeHandler,
    inputBlurHandler: laborBlurHandler,
    reset: resetLabor,
  } = useInput((value) => !isObjectEmpty(value));

  const {
    value: payPerWorker,
    isValid: payPerWorkerIsValid,
    // isInvalid: typeIsInValid,
    inputChangeHandler: payPerWorkerChangeHandler,
    inputBlurHandler: payPerWorkerBlurHandler,
    reset: resetPayPerWorker,
  } = useInput((value) => value !== "");

  const {
    value: quantity,
    isValid: quantityIsValid,
    // isInvalid: quantityIsInValid,
    inputChangeHandler: quantityChangeHandler,
    inputBlurHandler: quantityBlurHandler,
    reset: resetQuantity,
  } = useInput((value) => value !== "");

  const {
    value: noOfHours,
    isValid: noOfHoursIsValid,
    // isInvalid: quantityIsInValid,
    inputChangeHandler: noOfHoursChangeHandler,
    inputBlurHandler: noOfHoursBlurHandler,
    reset: resetNoOfHours,
  } = useInput((value) => value !== "");
  const onLaborChange = (labor) => {
    payPerWorkerChangeHandler(labor.payPerWorker);
    laborChangeHandler(labor);
  };

  const formIsValid = labors.length > 0;
  useEffect(() => {
    setAllLabor(allLaborProp);
  }, [allLaborProp]);
  useEffect(() => {
    if (formIsValid) {
      setSteps(
        steps.map((s, index) => {
          if (index === step - 1) {
            console.log(step);
            return { ...s, isValid: true };
          } else {
            return s;
          }
        })
      );
    } else {
      setSteps(
        steps.map((s, index) => {
          if (index === step - 1) {
            // return { ...s, isValid: false };
            return { ...s, isValid: true };
          } else {
            return s;
          }
        })
      );
    }
  }, [formIsValid]);

  const removeItem = (index) => {
    if (editMode) {
      setDeletedItems(
        [...deletedItems, labors[index]?.id].filter(
          (item) => item != undefined && item !== null
        )
      );
    } else {
      storePhaseData({
        labor: labors.filter((_, i) => i != index),
      });
    }
    setLabor(labors.filter((_, i) => i != index));
    scrollToBottom();
  };
  console.log(labors);
  const addItem = () => {
    if (
      laborIsValid &&
      quantityIsValid &&
      payPerWorkerIsValid &&
      noOfHoursIsValid
    ) {
      let found = false;
      console.log(labor);
      const newLabor = labors.map((lab) => {
        if (lab.labor.id === labor.id) {
          found = true;
          return {
            ...lab,
            unitPrice: payPerWorker,
            noOfHours,
            quantity: parseInt(lab.quantity) + parseInt(quantity),
          };
        } else {
          return lab;
        }
      });
      setLabor(newLabor);
      if (!editMode) {
        storePhaseData({
          labor: newLabor,
        });
      } else {
        calculateTotalCost({ labor: newLabor, ...otherData });
      }
      if (!found) {
        const newLabor = [
          ...labors,
          { labor, unitPrice: payPerWorker, noOfHours, quantity: quantity },
        ];
        setLabor(newLabor);
        if (!editMode) {
          storePhaseData({
            labor: newLabor,
          });
        } else {
          calculateTotalCost({ labor: newLabor, ...otherData });
        }
      }
      resetLabor();
      resetQuantity();
      resetNoOfHours();
      resetPayPerWorker();
      scrollToBottom();
    }
  };
  const selectItem = (item, index) => {
    if (item) {
      console.log(item);
      setEditedItem({ item, index });
      laborChangeHandler(item?.labor);
      payPerWorkerChangeHandler(item?.unitPrice);
      quantityChangeHandler(item?.quantity);
      scrollToBottom();
    }
  };
  const hideModal = () => {
    setNewLaborModalShown(false);
  };
  const applyItem = () => {
    const { item, index } = editedItem;
    if (item && formIsValid) {
      const updatedLabor = labors.map((item, i) => {
        if (index === i) {
          if (editMode) {
            return { ...item, labor, unitPrice: payPerWorker, quantity };
          } else {
            return { labor, unitPrice: payPerWorker, quantity };
          }
        } else return item;
      });
      setLabor(updatedLabor);
      if (!editMode) {
        storePhaseData({
          labor: updatedLabor,
        });
      }
      resetLabor();
      resetQuantity();
      resetNoOfHours();
      resetPayPerWorker();
      setEditedItem(null);
      scrollToBottom();
    }
  };
  return (
    <div className="space-y-3 flex flex-col justify-between h-fit">
      <div className="flex justify-between w-full">
        <h1 className="font-semibold">Labor</h1>
        <Button
          type="light"
          className="!px-4 !py-2 !text-gray-600 !bg-gray-100 hover:!bg-gray-200"
          onClick={() => {
            setNewLaborModalShown(true);
          }}
        >
          <AddIcon />
          New Labor
        </Button>
      </div>
      <Dropdown
        selectHandler={onLaborChange}
        onBlur={laborBlurHandler}
        value={labor.type}
        // error={typeIsInValid}
        label="Labor type"
        helperText={<>Labor type is required</>}
        placeholder="Eg. Electricians"
        items={allLabor}
        propertyName="type"
        required={true}
        name="labor"
      />

      <Input
        value={payPerWorker}
        onChange={payPerWorkerChangeHandler}
        onBlur={payPerWorkerBlurHandler}
        // error={quantityIsInValid}
        helperText={<>Hourly pay is required</>}
        label="Hourly pay"
        placeholder="1500"
        type="number"
      />
      <Input
        value={quantity}
        onChange={quantityChangeHandler}
        onBlur={quantityBlurHandler}
        // error={quantityIsInValid}
        helperText={<>Number of workers is required</>}
        label="Number of workers"
        placeholder="10"
        type="number"
      />

      <Input
        value={noOfHours}
        onChange={noOfHoursChangeHandler}
        onBlur={noOfHoursBlurHandler}
        // error={quantityIsInValid}
        helperText={<>Number of hours is required</>}
        label="Number of hours per worker"
        placeholder="40"
        type="number"
      />
      {editedItem !== null ? (
        <span className="flex justify-between">
          <button
            disabled={!(laborIsValid && quantityIsValid && payPerWorkerIsValid)}
            type="button"
            className="mt-2 disabled:text-secondary/70 hover:text-primary text-secondary flex items-center p-1 text-sm"
            onClick={() => applyItem()}
          >
            <Check2 className="mr-1 text-[15px]" />
            Update item
          </button>
          <button
            disabled={!(laborIsValid && quantityIsValid && payPerWorkerIsValid)}
            type="button"
            className="mt-2 disabled:text-red-400 hover:text-red-500 text-red-500/80 flex items-center p-1 text-sm"
            onClick={() => {
              setEditedItem(null);
              resetLabor();
              resetQuantity();
              resetNoOfHours();
              resetPayPerWorker();
            }}
          >
            <X className="mr-1 text-[15px]" />
            Cancel
          </button>
        </span>
      ) : (
        <button
          disabled={!(laborIsValid && quantityIsValid && payPerWorkerIsValid)}
          type="button"
          className="mt-2 disabled:text-secondary/70 hover:text-primary text-secondary flex items-center p-1 text-sm"
          onClick={addItem}
        >
          <PlusCircleFill className="mr-1 text-[15px]" />
          Add item
        </button>
      )}
      <div>
        {labors.length !== 0 && (
          <table className="max-w-full horizontal-scrollbar block overflow-auto text-xs text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Labor Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Hourly Pay
                </th>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Number of Workers
                </th>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Number of Hours
                </th>
                <th scope="col" className=""></th>
              </tr>
            </thead>
            <tbody>
              {labors.map((item, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                >
                  <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                    {item.labor.type}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                    {parseFloat(item.unitPrice).toLocaleString()}/hr
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                    {item.quantity}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                    {item.noOfHours || "-"}
                  </td>
                  <td className="flex">
                    <IconButton
                      className="border-none hover:bg-gray-50 text-gray-400 w-6 h-6"
                      type="rounded"
                      onClick={() => selectItem(item, index)}
                    >
                      <EditIcon className="!w-4 !h-4" />
                    </IconButton>
                    <IconButton
                      className="group-hover:flex border-none bg-white/50 hover:bg-white/70 text-red-500 w-6 h-6"
                      type="rounded"
                      onClick={() => removeItem(index)}
                    >
                      <DeleteIcon className="!w-4 !h-4" />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {newLaborModalShown && (
          <NewLabor
            refetch={refetch}
            setSuccess={() => {}}
            setError={() => {}}
            isShown={newLaborModalShown}
            hideModal={hideModal}
            selectedMaterial={null}
            editMode={editMode}
          />
        )}
      </div>
    </div>
  );
};

export default Labor;
