import React from "react";

const Card = (props) => {
  return (
    <div
      ref={props.cardRef}
      style={props.style}
      className={`block p-4 bg-white rounded-lg dark:bg-gray-800 dark:border-gray-700 ${props.className}`}
    >
      { props.children }
    </div>
  );
};

export default Card;
