import React, { Fragment, useRef, useState } from "react";
import Button from "../components/UI/Button";
import AddIcon from "@mui/icons-material/Add";
import { Tabs, Tab } from "../components/UI/Tabs";
import Card from "../components/UI/Card";
import UserCard from "../components/UI/UserCard";
import NewUser from "../components/Forms/NewUser";
import Table from "../components/UI/Table";
import ListSkeleton from "../components/UI/skeleton/ListSkeleton";
import Confirm from "../components/UI/Confirm";
import { gql, useMutation } from "@apollo/client";
import moment from "moment";
import { Warning } from "@mui/icons-material";
import Toast from "../components/UI/Toast";
import { useUser } from "../store/user-context";
import { useAuthUser } from "react-auth-kit";

const DELETE_USER = gql`
  mutation MyMutation2($id: uuid!) {
    delete_users_users_by_pk(id: $id) {
      id
    }
  }
`;

const DELETE_MANY_USERS = gql`
  mutation MyMutation($_in: [uuid!]!) {
    delete_users_users(where: { id: { _in: $_in } }) {
      affected_rows
    }
  }
`;

const UsersPage = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [newUserModalShown, setNewUserModalShown] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isConfirmDeleteOneShown, setIsConfirmDeleteOneShown] = useState(false);
  const [isConfirmDeleteManyShown, setIsConfirmDeleteManyShown] =
    useState(false);
  const cardRef = useRef();

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const users = useUser();
  const [deleteUser, { loading: isDeleting, error: deleteError }] =
    useMutation(DELETE_USER);

  const [deleteManyUsers, { loading: isDeletingMany, error: deleteManyError }] =
    useMutation(DELETE_MANY_USERS);
  const auth = useAuthUser();
  const user = auth();

  let clients = [];
  let contractors = [];
  let company_admins = [];
  let super_admins = [];
  let site_admins = [];
  let store_keepers = [];
  users?.users?.map((user) => {
    const roleCodes = user.roles.map((el) => el.code);
    const roles = user.roles.map((el) => el.name);
    if (roleCodes.includes("CLNT")) {
      clients.push({
        id: user.id,
        name: user.fullName,
        roles: roles.join(", "),
        email: user.email,
        phone: user.phone,
        joinedAt: moment(user.created_at).format("LL"),
        projects:
          user.user_projects.map((el) => el.project.projectName).join(", ") ||
          "-",
      });
    } else if (roleCodes.includes("CNTR")) {
      contractors.push({
        id: user.id,
        name: user.fullName,
        roles: roles.join(", "),
        email: user.email,
        phone: user.phone,
        joinedAt: moment(user.created_at).format("LL"),
        projects:
          user.user_projects.map((el) => el.project.projectName).join(", ") ||
          "-",
      });
    } else if (roleCodes.includes("ADM")) {
      company_admins.push({
        id: user.id,
        name: user.fullName,
        roles: roles.join(", "),
        email: user.email,
        phone: user.phone,
        joinedAt: moment(user.created_at).format("LL"),
        // projects:
        //   user.user_projects.map((el) => el.project.projectName).join(", ") ||
        //   "-",
      });
    } else if (roleCodes.includes("SITE_ADM")) {
      site_admins.push({
        id: user.id,
        name: user.fullName,
        roles: roles.join(", "),
        email: user.email,
        phone: user.phone,
        joinedAt: moment(user.created_at).format("LL"),
        site: user.user_sites.map((el) => el.site.name).join(", ") || "-",
      });
    } else if (roleCodes.includes("SU")) {
      super_admins.push({
        id: user.id,
        name: user.fullName,
        roles: roles.join(", "),
        email: user.email,
        phone: user.phone,
        joinedAt: moment(user.created_at).format("LL"),
        // projects:
        //   user.user_projects.map((el) => el.project.projectName).join(", ") ||
        //   "-",
      });
    } else if (roleCodes.includes("ST_KP")) {
      store_keepers.push({
        id: user.id,
        name: user.fullName,
        roles: roles.join(", "),
        email: user.email,
        phone: user.phone,
        joinedAt: moment(user.created_at).format("LL"),
        site: user.user_sites.map((el) => el.site.name).join(", ") || "-",
      });
    }
  });
  const selectUser = (id) => {
    if (id === null) {
      setSelectedUser(null);
      return;
    }
    users?.users.map((user) => {
      if (user.id === id) {
        setSelectedUser(user);
        return user;
      } else {
        return;
      }
    });
    cardRef.current && cardRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const contractor_and_client_headers = [
    "Name",
    "Role(s)",
    "Email",
    "Phone",
    "Date Joined",
    "Project(s)",
  ];
  const site_admin_shop_keeper_headers = [
    "Name",
    "Role(s)",
    "Email",
    "Phone",
    "Date Joined",
    "Site",
  ];
  const super_admin_headers = [
    "Name",
    "Role(s)",
    "Email",
    "Phone",
    "Date Joined",
    // "Project(s)",
  ];
  const company_admin_headers = [
    "Name",
    "Role(s)",
    "Email",
    "Phone",
    "Date Joined",
    // "Project(s)",
  ];
  const hideModal = () => {
    setSelectedUser(null);
    setEditMode(false);
    setNewUserModalShown(false);
  };
  const deleteManyHandler = async () => {
    if (selectedUsers.length === 0) {
      return;
    }
    try {
      await deleteManyUsers({ variables: { _in: selectedUsers } });
      if (!deleteManyError) {
        users.refetch();
        setIsConfirmDeleteManyShown(false);
        setSelectedUser(null);
        setSelectedUsers([]);
        setSuccess(
          selectedUsers.length +
            `${selectedUsers.length > 1 ? " users" : " user"}` +
            " deleted successfully"
        );
      }
    } catch (err) {
      setError(err.message);
      console.log(err);
    }
  };
  const deleteOneHandler = async () => {
    if (selectedUser === null) {
      return;
    }
    try {
      await deleteUser({ variables: { id: selectedUser.id } });
      if (!deleteError) {
        users.refetch();
        setIsConfirmDeleteOneShown(false);
        setSelectedUser(null);
        setSelectedUsers([]);
        setSuccess("User deleted successfully");
      }
    } catch (err) {
      setError(err.message);
      console.log(err);
    }
  };
  const showDeleteOneConfirmation = () => {
    setIsConfirmDeleteOneShown(true);
  };
  const showDeleteManyConfirmation = (selectedList) => {
    if (selectedList.length === 0) {
      return;
    }
    setSelectedUsers(selectedList);
    setIsConfirmDeleteManyShown(true);
  };
  return (
    <div ref={cardRef} className="m-3 grid grid-cols-12">
      {isConfirmDeleteOneShown && (
        <Confirm
          confirmButtonText="Delete"
          isLoading={isDeleting}
          confirmTitle="Delete"
          onClick={deleteOneHandler}
          onCancel={() => {
            setIsConfirmDeleteOneShown(false);
          }}
        >
          Are you sure you want to delete this user?
        </Confirm>
      )}
      {isConfirmDeleteManyShown && (
        <Confirm
          confirmButtonText="Delete"
          isLoading={isDeletingMany}
          confirmTitle="Delete"
          onClick={deleteManyHandler}
          onCancel={() => {
            setIsConfirmDeleteManyShown(false);
          }}
        >
          Are you sure you want to delete {selectedUsers.length}
          {selectedUsers.length === 1 ? " user" : " users"}?
        </Confirm>
      )}
      <Tabs
        className={`${selectedUser ? "col-span-9" : "col-span-12"}`}
        otherElements={
          <Button
            onClick={() => {
              setNewUserModalShown(true);
              setSelectedUser(null);
            }}
          >
            <AddIcon />
            Add New User
          </Button>
        }
      >
        {user?.roles?.map((role) => role.code).includes("SU") && (
          <Tab label="Super admins">
            <div className="">
              {users?.loading && !users?.error ? (
                <ListSkeleton />
              ) : users?.error ? (
                <p className="flex justify-center items-center">
                  <Warning className="!w-4 !h-4 mr-1" />
                  {users?.error?.message}
                </p>
              ) : (
                <Table
                  title={`Super admins(${super_admins?.length || 0})`}
                  search={true}
                  searchList={super_admins}
                  searchPropertiesKey="name"
                  searchPlaceholder="Search a super admin"
                  delete={true}
                  checkbox={true}
                  showDeleteManyConfirmation={showDeleteManyConfirmation}
                  checkboxClickHandler={selectUser}
                  headers={super_admin_headers}
                  rows={super_admins}
                  errorMessage={
                    users?.error && (
                      <p className="flex justify-center items-center">
                        <Warning className="!w-4 !h-4 mr-1" />
                        {users?.error?.message}
                      </p>
                    )
                  }
                />
              )}
            </div>
          </Tab>
        )}
        <Tab label="Company admins">
          <div className="">
            {users?.loading && !users?.error ? (
              <ListSkeleton />
            ) : users?.error ? (
              <p className="flex justify-center items-center">
                <Warning className="!w-4 !h-4 mr-1" />
                {users?.error?.message}
              </p>
            ) : (
              <Table
                title={`Company admins(${company_admins?.length || 0})`}
                search={true}
                searchList={company_admins}
                searchPropertiesKey="name"
                searchPlaceholder="Search a company admin"
                delete={true}
                checkbox={true}
                showDeleteManyConfirmation={showDeleteManyConfirmation}
                checkboxClickHandler={selectUser}
                headers={company_admin_headers}
                rows={company_admins}
                errorMessage={
                  users?.error && (
                    <p className="flex justify-center items-center">
                      <Warning className="!w-4 !h-4 mr-1" />
                      {users?.error?.message}
                    </p>
                  )
                }
              />
            )}
          </div>
        </Tab>
        <Tab label="Site admins">
          <div className="">
            {users?.loading && !users?.error ? (
              <ListSkeleton />
            ) : users?.error ? (
              <p className="flex justify-center items-center">
                <Warning className="!w-4 !h-4 mr-1" />
                {users?.error?.message}
              </p>
            ) : (
              <Table
                title={`Site admins(${site_admins?.length || 0})`}
                search={true}
                searchList={site_admins}
                searchPropertiesKey="name"
                searchPlaceholder="Search a site admin"
                delete={true}
                checkbox={true}
                showDeleteManyConfirmation={showDeleteManyConfirmation}
                checkboxClickHandler={selectUser}
                headers={site_admin_shop_keeper_headers}
                rows={site_admins}
                errorMessage={
                  users?.error && (
                    <p className="flex justify-center items-center">
                      <Warning className="!w-4 !h-4 mr-1" />
                      {users?.error?.message}
                    </p>
                  )
                }
              />
            )}
          </div>
        </Tab>
        <Tab label="Contractors">
          <div className="">
            {users?.loading && !users?.error ? (
              <ListSkeleton />
            ) : users?.error ? (
              <p className="flex justify-center items-center">
                <Warning className="!w-4 !h-4 mr-1" />
                {users?.error?.message}
              </p>
            ) : (
              <Table
                title={`Contractors(${contractors?.length || 0})`}
                search={true}
                searchList={contractors}
                searchPropertiesKey="name"
                searchPlaceholder="Search a contractor"
                delete={true}
                checkbox={true}
                showDeleteManyConfirmation={showDeleteManyConfirmation}
                checkboxClickHandler={selectUser}
                headers={contractor_and_client_headers}
                rows={contractors}
                errorMessage={
                  users?.error && (
                    <p className="flex justify-center items-center">
                      <Warning className="!w-4 !h-4 mr-1" />
                      {users?.error?.message}
                    </p>
                  )
                }
              />
            )}
          </div>
        </Tab>
        <Tab label="Clients">
          <div className="">
            {users?.loading && !users?.error ? (
              <ListSkeleton />
            ) : users?.error ? (
              <p className="flex justify-center items-center">
                <Warning className="!w-4 !h-4 mr-1" />
                {users?.error?.message}
              </p>
            ) : (
              <Table
                title={`Clients(${clients?.length || 0})`}
                search={true}
                searchList={clients}
                searchPropertiesKey="name"
                searchPlaceholder="Search a client"
                delete={true}
                checkbox={true}
                showDeleteManyConfirmation={showDeleteManyConfirmation}
                checkboxClickHandler={selectUser}
                headers={contractor_and_client_headers}
                rows={clients}
                errorMessage={
                  users?.error && (
                    <p className="flex justify-center items-center">
                      <Warning className="!w-4 !h-4 mr-1" />
                      {users?.error?.message}
                    </p>
                  )
                }
              />
            )}
          </div>
        </Tab>
        <Tab label="Store keepers">
          <div className="">
            {users?.loading && !users?.error ? (
              <ListSkeleton />
            ) : users?.error ? (
              <p className="flex justify-center items-center">
                <Warning className="!w-4 !h-4 mr-1" />
                {users?.error?.message}
              </p>
            ) : (
              <Table
                title={`Company admins(${store_keepers?.length || 0})`}
                search={true}
                searchList={store_keepers}
                searchPropertiesKey="name"
                searchPlaceholder="Search a store keeper"
                delete={true}
                checkbox={true}
                showDeleteManyConfirmation={showDeleteManyConfirmation}
                checkboxClickHandler={selectUser}
                headers={site_admin_shop_keeper_headers}
                rows={store_keepers}
                errorMessage={
                  users?.error && (
                    <p className="flex justify-center items-center">
                      <Warning className="!w-4 !h-4 mr-1" />
                      {users?.error?.message}
                    </p>
                  )
                }
              />
            )}
          </div>
        </Tab>
      </Tabs>
      <Card
        // cardRef={cardRef}
        className={`sticky vertical-scrollbar top-0 right-0 flex flex-col items-center overflow-x-hidden overflow-y-auto max-h-screen ml-3 space-y-3 transition-all duration-200 ${
          selectedUser
            ? "translate-x-0 col-span-3"
            : "translate-x-full !bg-transparent"
        }`}
      >
        {selectedUser ? (
          <Fragment>
            <h1 className="text-start w-full text-lg font-medium">
              User details
            </h1>
            <UserCard client={selectedUser} />
            <ul className="w-full text-xs space-y-3 p-2">
              <li className="flex justify-between">
                <span className="text-dark-gray">Date joined</span>
                <span>{moment(selectedUser.created_at).format("LL")}</span>
              </li>
              <li className="flex justify-between">
                <span className="text-dark-gray">Email</span>
                <span>{selectedUser.email}</span>
              </li>
              <li className="flex justify-between">
                <span className="text-dark-gray">Phone</span>
                <span>{selectedUser.phone}</span>
              </li>
              <li className="flex justify-between">
                <span className="text-dark-gray">Title</span>
                <span>
                  {selectedUser.roles.map((el) => el.name).join(", ")}
                </span>
              </li>
              {selectedUser.user_projects.length > 0 && (
                <li className="flex flex-col">
                  <span className="text-dark-gray mr-2">Project(s)</span>
                  <div className="w-full flex flex-wrap justify-start mt-2">
                    {selectedUser.user_projects.length !== 0 &&
                      selectedUser.user_projects.map((user_project, index) => (
                        <span
                          key={index}
                          className="bg-primary/10 mb-2 mr-3 font-medium whitespace-nowrap flex items-center text-primary text-xs rounded-md px-3 py-1"
                        >
                          {user_project.project.projectName}
                        </span>
                      ))}
                  </div>
                </li>
              )}
              {selectedUser.user_sites.length > 0 && (
                <li className="flex flex-col">
                  <span className="text-dark-gray mr-2">Site</span>
                  <div className="w-full flex flex-wrap justify-start mt-2">
                    {selectedUser.user_sites.length !== 0 &&
                      selectedUser.user_sites.map((user_site, index) => (
                        <span
                          key={index}
                          className="bg-primary/10 mb-2 mr-3 font-medium whitespace-nowrap flex items-center text-primary text-xs rounded-md px-3 py-1"
                        >
                          {user_site.site.name}
                        </span>
                      ))}
                  </div>
                </li>
              )}
              <div className="flex flex-col space-y-2 ">
                <Button
                  className="shadow-sm hover:shadow-lg !bg-green-100/60 hover:!bg-green-200/80 !text-green-600 focus:!ring-0"
                  onClick={() => {
                    setNewUserModalShown(true);
                    setEditMode(true);
                  }}
                >
                  Edit user
                </Button>
                <Button
                  onClick={showDeleteOneConfirmation}
                  className="shadow-sm hover:shadow-lg !bg-red-100/60 hover:!bg-red-200/80 !text-dark-red focus:!ring-0"
                >
                  Delete user
                </Button>
              </div>
            </ul>
          </Fragment>
        ) : (
          <Fragment>
            {/* <img src={userDetails} alt="User details illustration" />
            <p className="text-dark-gray text-sm">
              Select a user to see details
            </p> */}
          </Fragment>
        )}
      </Card>
      {newUserModalShown && (
        <NewUser
          refetch={users.refetch}
          setSuccess={setSuccess}
          setError={setError}
          isShown={newUserModalShown}
          hideModal={hideModal}
          selectedUser={selectedUser}
          editMode={editMode}
        />
      )}
      <Toast
        type="error"
        show={error !== null}
        callback={() => {
          setError(null);
        }}
        message={error}
      />
      <Toast
        type="success"
        show={success !== null}
        callback={() => {
          setSuccess(null);
        }}
        message={success}
      />
    </div>
  );
};

export default UsersPage;
