import React from "react";
import Card from "../Card";
import CallEndIcon from "@mui/icons-material/CallEnd";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SmallCard from "./SmallCard";
import MilestoneCard from "./MilestoneCard";
import UserCard from "../UserCard";
import moment from "moment";
import RectangleSkeleton from "../skeleton/RectangleSkeleton";

const colors = ["dark-orange", "dark-blue", "dark-red"];
function formatNumber(num, precision = 1) {
  const map = [
    { suffix: "T", threshold: 1e12 },
    { suffix: "B", threshold: 1e9 },
    { suffix: "M", threshold: 1e6 },
    { suffix: "K", threshold: 1e3 },
    { suffix: "", threshold: 1 },
  ];

  const found = map.find((x) => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
    return formatted;
  }

  return num;
}
const RightSidebar = (props) => {
  const {
    loading,
    milestones,
    className,
    overallCompleted,
    laborExpenses,
    idleDays,
    clients,
  } = props;
  console.log(clients);
  // const user = clients && clients; // only show the first client
  return (
    <Card
      className={`vertical-scrollbar text-sm p-2 ml-3 min-w-[300px] max-h-screen sticky top-0 space-y-3 ${className}`}
    >
      <span className="flex justify-between items-center">
        <h1 className="text-lg font-medium">Current Phases</h1>
        {/* Dropdown */}
        {/* <Dropdown selectHandler={() => {}} items={["Upcoming", "Past", "Current"]}/> */}
      </span>
      <div className="flex flex-col space-y-5">
        {loading ? (
          <Card className="shadow-md">
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </Card>
        ) : milestones.length === 0 ? (
          <p className="text-center text-dark-gray">No milestone found</p>
        ) : (
          milestones.map((milestone, index) => (
            <MilestoneCard
              key={index}
              color={colors[index]}
              icon={milestone.icon}
              title={milestone.name}
              progress={milestone.percentageCompleted}
              date={moment(milestone.endDate).format("ll")}
            />
          ))
        )}
        {/* <MilestoneCard bg="bg-[#D2E8C1]" textColor="text-[#64B128]" icon={<PlaylistAddCheckIcon/>} title="Finishes and Interior Work" items={["Applying wall finishes, such as", "Installing flooring materials, till setting up plumbing systems and fixtures", "Fixing cabinetry, counter tops"]} date="25/05/2023"/>
        <MilestoneCard bg="bg-[#B8C9EB]" textColor="text-[#164297]" icon={<PlumbingIcon/>} title="Plumbing" items={["Framing interior walls and partitions", "Installing electrical wiring and lighting", "Setting up plumbing systems and fixtures"]} date="05/06/2023"/> */}
      </div>
      <h1 className="text-lg font-medium">Construction Status</h1>
      <div className="flex space-x-2 my-4">
        {loading ? (
          <>
            <RectangleSkeleton className="w-24 !h-32" />
            <RectangleSkeleton className="w-24 !h-32" />
            <RectangleSkeleton className="w-24 !h-32" />
          </>
        ) : (
          <>
            <SmallCard
              title={overallCompleted + "%"}
              subtitle="Completed"
              textColor="text-dark-red"
              bg="bg-dark-red/20"
            />
            <SmallCard
              title={formatNumber(laborExpenses)}
              subtitle="Labor Expense"
              textColor="text-dark-blue"
              bg="bg-dark-blue/20"
            />
            <SmallCard
              title={idleDays}
              subtitle="Idle days"
              textColor="text-dark-orange"
              bg="bg-dark-orange/20"
            />
          </>
        )}
      </div>
      <h1 className="text-lg font-medium">Client(s)</h1>
      {clients && clients.length > 0 ? (
        // clients.map(({ user }, index) => (
        <Card>
          <div className="flex flex-col items-center pb-10">
            <UserCard client={clients[0].user} />
            <div className="flex flex-col mt-4 space-y-3 md:mt-6">
              <p className="flex text-sm space-x-2">
                <MailOutlineIcon className="text-gray-500" />
                <span className="text-dark-gray">{clients[0].user?.email}</span>
              </p>
              <p className="flex text-sm space-x-2">
                <CallEndIcon className="text-gray-500" />
                <span className="text-dark-gray">{clients[0].user?.phone}</span>
              </p>
            </div>
          </div>
        </Card>
      ) : (
        // ))
        <p className="text-center text-dark-gray">No client found</p>
      )}
    </Card>
  );
};

export default RightSidebar;
