import React from "react";

const CardSkeleton = (props) => {
  return (
    <div
      role="status"
      className={`rounded-xl animate-pulse h-64 dark:border-gray-700 ${props.className} ${props.width ? props.width : "max-w-sm"}`}
    >
      <div className={`flex items-center justify-center mb-4 bg-gray-200 rounded-xl dark:bg-gray-700 ${props.innerHeight ? props.innerHeight : "h-[60%]"}`}></div>
    </div>
  );
};

export default CardSkeleton;
