import React from "react";

const Textarea = (props) => {
  const successInputClasses =
    "bg-green-50 border border-green-500 text-green-900 placeholder-green-700 text-sm rounded-lg focus:ring-green-500 block w-full p-2.5 dark:bg-green-100 dark:border-green-400";
  const errorInputClasses =
    "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-300 block w-full p-2.5 dark:bg-red-100 dark:border-red-400";
  const successLabelClasses =
    "block mb-2 text-sm font-medium text-green-600 dark:text-green-500";
  const errorLabelClasses =
    "block mb-2 text-sm font-medium text-red-600 dark:text-red-500";
  return (
    <div>
      <label
        htmlFor="input-group-1"
        className={`block mb-2 w-fit text-sm font-medium text-gray-600 dark:text-white ${
          props.success && successLabelClasses
        } ${props.error && errorLabelClasses}`}
      >
        {props.label}
      </label>
      <textarea
        id={props.placeholder}
        rows="4"
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        onBlur={props.onBlur}
        value={props.value}
        type={props.type || "text"}
        className={`vertical-scrollbar resize-none bg-gray-50 border-none text-gray-900 text-sm rounded-lg focus:ring-4 focus:ring-primary/30 block w-full ${
          props.icon && "pl-10"
        } p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder:text-gray-400 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary ${
          props.success && successInputClasses
        } ${props.error && errorInputClasses}`}
        placeholder={props.placeholder || undefined}
      ></textarea>
      {props.success && (
        <p className="mt-2 text-sm text-green-600 dark:text-green-500">
          {props.success}
        </p>
      )}
      {props.error && (
        <p className="mt-2 text-xs tracking-wide text-red-600 dark:text-red-500">
          {props.helperText}
        </p>
      )}
    </div>
  );
};

export default Textarea;
