import React, { useState } from "react";
import ImageSkeleton from "./skeleton/ImageSkeleton";

const Image = (props) => {
  const [loaded, setLoaded] = useState(false);
  const { width, height, skeletonWidth, skeletonHeight, className, src, alt, onClick } = props;
  return (
    <>
      <img
        style={loaded ? {} : { display: "none" }}
        className={`${width} ${height} ${className} object-cover`}
        src={src}
        alt={alt}
        onLoad={() => {setLoaded(true);}}
      />
      {loaded ? null : (
        <div className={`flex justify-center items-center p-1 space-x-2 ${width} ${height} bg-gray-100`}>
          <ImageSkeleton className={`${skeletonWidth} ${skeletonHeight}`}/>
        </div>
      )}
    </>
  );
};

export default Image;
