import React, { useEffect, useState } from "react";

const CurrentPhase = ({ currentPhases }) => {
  const [currentPhase, setCurrentPhase] = useState(currentPhases[0]);
  let index = 0;
  useEffect(() => {
    let timer = null;
    const printPhases = () => {
      if (currentPhases) {
        setCurrentPhase(currentPhases[index]);
        if (index === currentPhases.length - 1) {
          index = 0; // Cancel the timer
        } else {
          index++;
        }
      }
    };
    timer = setInterval(printPhases, 3000);
  }, [currentPhases]);
  return currentPhase;
};

export default CurrentPhase;
