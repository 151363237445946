import React, { useEffect, useState } from "react";
import Dropdown from "../UI/Dropdown";
import Input from "../UI/Input";
import Modal from "../UI/Modal";
import Button from "../UI/Button";
import useInput from "../../hooks/use-input";
import { gql, useMutation } from "@apollo/client";
import DatePicker from "../UI/DatePicker";
import Textarea from "../UI/Textarea";
import moment from "moment";
import { isValidDate } from "../../functions/validation";
import { useUser } from "../../store/user-context";
import { useProject } from "../../store/project-context";
import { X } from "react-bootstrap-icons";

const INSERT_PROJECT = gql`
  mutation MyMutation($object: projects_projects_insert_input!) {
    insert_projects_projects_one(object: $object) {
      id
    }
  }
`;

const INSERT_USER_PROJECTS = gql`
  mutation MyMutation($objects: [users_user_projects_insert_input!]!) {
    insert_users_user_projects(objects: $objects) {
      affected_rows
    }
  }
`;

const UPDATE_PROJECT = gql`
  mutation MyMutation(
    $id: uuid!
    $oldUserIds: [uuid!]
    $objects: [users_user_projects_insert_input!]!
    $_set: projects_projects_set_input!
  ) {
    update_projects_projects_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
    delete_users_user_projects(
      where: { _and: { projectId: { _eq: $id }, userId: { _in: $oldUserIds } } }
    ) {
      affected_rows
    }
    insert_users_user_projects(objects: $objects) {
      affected_rows
    }
  }
`;

const NewProject = (props) => {
  const users = useUser();
  const { refetchProjects, refetchSites } = useProject();
  // const [uploadProgress, setUploadProgress] = useState(0);
  // const [uploadedImage, setUploadedImage] = useState(null);
  // const [image, setImage] = useState(null);
  // const [coordinates, setCoordinates] = useState({});
  const [contractors, setContractors] = useState([]);
  const [selectedContractors, setSelectedContractors] = useState([]);
  const { selectedProject, editMode, setError, setSuccess } = props;

  const [
    updateProject,
    { loading: isProjectUpdating, error: updateProjectError },
  ] = useMutation(UPDATE_PROJECT);
  const [
    addProject,
    { loading: isProjectSubmitting, error: insertProjectError },
  ] = useMutation(INSERT_PROJECT);
  const [insertUserProjects] = useMutation(INSERT_USER_PROJECTS);
  const {
    value: projectName,
    isValid: projectNameIsValid,
    isInvalid: projectNameIsInValid,
    inputChangeHandler: projectNameChangeHandler,
    inputBlurHandler: projectNameBlurHandler,
    reset: resetProjectName,
  } = useInput((value) => value.trim() !== "");

  const {
    value: startDate,
    isValid: startDateIsValid,
    isInvalid: startDateIsInValid,
    inputChangeHandler: startDateChangeHandler,
    inputBlurHandler: startDateBlurHandler,
    reset: resetStartDate,
  } = useInput((value) => isValidDate(value));

  const {
    value: endDate,
    isValid: endDateIsValid,
    isInvalid: endDateIsInValid,
    inputChangeHandler: endDateChangeHandler,
    inputBlurHandler: endDateBlurHandler,
    reset: resetEndDate,
  } = useInput((value) => isValidDate(value));

  // const {
  //   value: location,
  //   isValid: locationIsValid,
  //   isInvalid: locationIsInValid,
  //   inputChangeHandler: locationChangeHandler,
  //   inputBlurHandler: locationBlurHandler,
  //   reset: resetLocation,
  // } = useInput((value) => value.trim() !== "");

  const {
    value: description,
    isValid: descriptionIsValid,
    isInvalid: descriptionIsInValid,
    inputChangeHandler: descriptionChangeHandler,
    inputBlurHandler: descriptionBlurHandler,
    reset: resetDescription,
  } = useInput((value) => value.trim() !== "");

  const formIsValid =
    projectNameIsValid &&
    selectedContractors.length &&
    startDateIsValid &&
    endDateIsValid &&
    descriptionIsValid;
  const submitHandler = async (e) => {
    if (!formIsValid) {
      return;
    }
    if (!props.site_id) {
      setError("No site selected");
      return;
    }
    let projectData = {
      projectName,
      startDate,
      endDate,
      site_id: props.site_id,
      description,
    };
    try {
      if (new Date(startDate) > new Date(endDate)) {
        setError("Project end date must be later than the start date");
        return;
      }
      if (selectedProject) {
        console.log(selectedProject);
        const objects = selectedContractors.map((contractor) => {
          return {
            userId: contractor?.id,
            projectId: selectedProject.id,
          };
        });
        await updateProject({
          variables: {
            id: selectedProject.id,
            oldUserIds: selectedProject?.contractors?.map(
              (contractor) => contractor.user.id
            ),
            objects,
            _set: projectData,
          },
        });
      } else {
        const response = await addProject({
          variables: { object: projectData },
        });
        if (
          response.data?.insert_projects_projects_one?.id &&
          !isProjectSubmitting
        ) {
          const objects = selectedContractors.map((contractor) => {
            return {
              userId: contractor?.id,
              projectId: response.data?.insert_projects_projects_one?.id,
            };
          });
          await insertUserProjects({
            variables: { objects },
          });
        }
      }
      if (!insertProjectError && !editMode) {
        setSuccess("Project added successfully");
      } else if (!updateProjectError) {
        setSuccess("Project updated successfully");
      }
      hideModal();
      users?.refetch();
      refetchProjects();
      refetchSites();
      props.refetch();
    } catch (e) {
      setError(e?.message);
      console.log(e);
    }
  };
  useEffect(() => {
    if (selectedProject && editMode) {
      projectNameChangeHandler(selectedProject?.projectName);
      setSelectedContractors(
        selectedProject?.contractors?.map((contractor) => contractor.user)
      );
      startDateChangeHandler(new Date(selectedProject?.startDate));
      endDateChangeHandler(new Date(selectedProject?.endDate));
      // locationChangeHandler(selectedProject?.location);
      descriptionChangeHandler(selectedProject?.description);
      // setImage(selectedProject?.logo);
    }
  }, [
    editMode,
    selectedProject,
    projectNameChangeHandler,
    setSelectedContractors,
    startDateChangeHandler,
    // locationChangeHandler,
    endDateChangeHandler,
    descriptionChangeHandler,
    // setImage,
  ]);
  const removeContractor = (i) => {
    setSelectedContractors(
      selectedContractors.filter((_, index) => i !== index)
    );
  };
  const addContractor = (item, index) => {
    console.log(item);
    console.log(selectedContractors);
    if (selectedContractors.indexOf(item.fullName) > -1) {
      return;
    }
    let found = false;
    selectedContractors.map((contractor) => {
      if (item.id === contractor.id) {
        found = true;
        return;
      }
    });
    if (found) {
      return;
    }
    setSelectedContractors((contractors) => [
      { id: item.id, fullName: item.fullName },
      ...contractors,
    ]);
  };
  useEffect(() => {
    if (!users.loading && users?.users?.length) {
      setContractors(
        users?.users.filter((user) =>
          user.roles.find((role) => role.code === "CNTR")
        )
      );
    }
  }, [users]);
  const hideModal = () => {
    resetProjectName();
    resetStartDate();
    resetEndDate();
    resetDescription();
    setSelectedContractors([]);
    // setUploadedImage(null);
    // setImage(null);
    props.hideModal();
  };
  if (props.isShown) {
    return (
      <>
        <Modal
          headerIsShown={true}
          modalHeight="h-[85%] sm:h-[97%]"
          isShown={true} //props.isShown
          hideModal={hideModal}
          modalTitle={editMode ? "Edit Project" : "Create New Project"}
          modalBottom={
            <div className="flex justify-end mr-4">
              <Button className="mr-3" type="light" onClick={hideModal}>
                Cancel
              </Button>
              <Button
                disabled={!formIsValid}
                isLoading={isProjectSubmitting || isProjectUpdating}
                onClick={submitHandler}
                type="submit"
              >
                {editMode ? "Update" : "Save"}
              </Button>
            </div>
          }
        >
          <p className="text-dark-gray text-sm ">
            {editMode
              ? "Edit this project with few steps and start tracking your progress"
              : "Create a new project with few steps and start tracking your progress"}
          </p>
          <form className="space-y-3 mt-5 flex flex-col justify-between h-fit">
            <Input
              onChange={projectNameChangeHandler}
              onBlur={projectNameBlurHandler}
              value={projectName}
              error={projectNameIsInValid}
              id="projectName"
              label="Project name"
              helperText={<>Project name is required</>}
              placeholder="Enter project name"
              required
              name="name"
              type="text"
            />
            <Dropdown
              selectHandler={addContractor}
              // onBlur={contractorBlurHandler}
              // value={contractors[0]?.fullName}
              // error={contractorIsInValid}
              id="contractor"
              label="Contractor"
              helperText={<>Contractor is required</>}
              loading={users?.loading}
              errorMessage={users?.error?.message}
              items={contractors}
              propertyName="fullName"
            />
            <div className="w-first flex space-x-3">
              {selectedContractors.length !== 0 &&
                selectedContractors.map((contractor, index) => (
                  <span
                    key={index}
                    className="bg-primary/10 flex items-center text-primary text-xs rounded-md px-3 py-1"
                  >
                    {contractor.fullName}
                    <button
                      onClick={() => removeContractor(index)}
                      type="button"
                      className="text-lg ml-2 hover:text-red-500"
                    >
                      <X />
                    </button>
                  </span>
                ))}
            </div>
            {/* <div className="flex space-x-2 w-full"> */}
            <DatePicker
              value={startDate}
              onChange={startDateChangeHandler}
              className="w-full"
              onBlur={startDateBlurHandler}
              error={startDateIsInValid}
              dateFormat="dd/MM/yyyy"
              id="start-date"
              helperText={<>Start date is required</>}
              label="Start date"
              placeholderText={moment().format("L")}
            />
            <DatePicker
              value={endDate}
              onChange={endDateChangeHandler}
              className="w-full"
              onBlur={endDateBlurHandler}
              error={endDateIsInValid}
              dateFormat="dd/MM/yyyy"
              id="end-date"
              helperText={<>End date is required</>}
              label="End date"
              placeholderText={moment().add(1, "years").calendar()}
            />
            {/* </div> */}
            <Textarea
              label="Description"
              onChange={descriptionChangeHandler}
              onBlur={descriptionBlurHandler}
              value={description}
              error={descriptionIsInValid}
              id="description"
              placeholder="Add description"
              helperText={<>A valid description is required</>}
              required
            />
          </form>
        </Modal>
      </>
    );
  } else {
    return <></>;
  }
};

export default NewProject;
