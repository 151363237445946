import React from "react";

const Checkbox = (props) => {
  const selectHandler = (e) => {
    e.stopPropagation();
    props.onClick(e.target.checked);
  };

  if (props.selectedList) {
    // Checkboxes under select all
    const selected = props.selectedList.includes(props.id);
    return (
      <div className={`flex items-center ${props.className}`}>
        <input
          ref={props.chbxRef}
          checked={selected}
          onClick={selectHandler}
          onChange={selectHandler}
          disabled={props.disabled}
          id={props.id}
          type="checkbox"
          value=""
          className={`w-4 h-4 disabled:text-primary/30 disabled:cursor-not-allowed cursor-pointer text-primary bg-gray-100 border-gray-300 rounded focus:ring-primary dark:focus:ring-primary dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 ${props.className}`}
        />
        {props.label && (
          <label
            htmlFor={props.id}
            className="ml-2 text-sm font-medium text-gray-600 dark:text-gray-300"
          >
            {props.label}
          </label>
        )}
      </div>
    );
  } else if (props.allSelected) {
    // Select all checkbox
    return (
      <div className={`flex items-center ${props.className}`}>
        <input
          ref={props.chbxRef}
          checked={props.allSelected}
          disabled={props.disabled}
          onClick={selectHandler}
          onChange={selectHandler}
          id="default-checkbox"
          type="checkbox"
          value=""
          className="w-4 h-4 disabled:text-primary/30 disabled:cursor-not-allowed cursor-pointer text-primary bg-gray-100 border-gray-300 rounded focus:ring-primary dark:focus:ring-primary dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        {props.label && (
          <label
            htmlFor="default-checkbox"
            className="ml-2 text-sm font-medium text-gray-600 dark:text-gray-300"
          >
            {props.label}
          </label>
        )}
      </div>
    );
  } else {
    // normal checkbox
    return (
      <div className={`flex items-center ${props.className}`}>
        <input
          ref={props.chbxRef}
          checked={props.checked}
          disabled={props.disabled}
          onClick={selectHandler}
          onChange={selectHandler}
          id={props.id}
          type="checkbox"
          value=""
          className="w-4 h-4 disabled:text-primary/30 disabled:cursor-not-allowed cursor-pointer text-primary bg-gray-100 border-gray-300 rounded focus:ring-primary dark:focus:ring-primary dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        {props.label && (
          <label
            htmlFor={props.id}
            className="ml-2 cursor-default text-sm font-medium text-gray-600 dark:text-gray-300"
          >
            {props.label}
          </label>
        )}
      </div>
    );
  }
};

export default Checkbox;
