import React from "react";
import { Link } from "react-router-dom";

const NotificationMessage = ({
  id,
  title,
  description,
  time,
  tag,
  image,
  link,
  read,
  // actions,
  onClick,
}) => {

  console.log(tag)
  return (
    <div
      onClick={() => {
        onClick(id);
      }}
      className={`flex items-center w-full border-y cursor-pointer hover:bg-gray-100 border-gray-100 px-2 py-3 ${
        read ? "bg-white" : "bg-gray-50"
      }`}
    >
      {!read && (
        <span className="w-1 h-1 p-0.5 mr-2 bg-primary rounded-full"></span>
      )}
      {image && <img src={image} alt="" className="rounded-full h-10 w-10" />}
      <Link to={link} className="flex w-full flex-col">
        <div className="flex w-full text-xs justify-between">
          <div className="flex flex-col">
            <span className="font-semibold text-[13px]">{title}</span>
            <span className="font-medium text-dark-gray mt-1">{description}</span>
          </div>
          <span className="ml-2 text-[10px] text-dark-gray">{time}</span>
        </div>
        {/* {actions && <span className="text-xs mt-2">{actions}</span>} */}
      </Link>
    </div>
  );
};

export default NotificationMessage;
