import React from 'react'

const SmallCard = (props) => {
  return (
    <div className={`rounded-lg p-2 w-24 flex flex-col items-center text-center justify-center ${props.bg}`}>
        <span className={`text-2xl font-semibold ${props.textColor}`}>{ props.title }</span>
        <span className='text-sm'>{ props.subtitle }</span>
    </div>
  )
}

export default SmallCard