import React, { useEffect, useState } from "react";
import useInput from "../../../hooks/use-input";
import Input from "../../UI/Input";
import IconButton from "../../UI/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { CheckCircle, Check2, PlusCircleFill, X } from "react-bootstrap-icons";
import Checkbox from "../../UI/Checkbox";
import { usePhase } from "../../../store/phase-context";

const Tests = (props) => {
  const {
    scrollToBottom,
    tests,
    setTests,
    setSteps,
    steps,
    step,
    editMode,
    deletedItems,
    setDeletedItems,
  } = props;
  const [editedItem, setEditedItem] = useState(null);
  const { storePhaseData } = usePhase();
  const {
    value: name,
    isValid: nameIsValid,
    isInvalid: nameIsInValid,
    inputChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    reset: resetName,
  } = useInput((value) => value.trim() !== "");

  // const formIsValid = tests.length > 0;

  useEffect(() => {
    //   console.log(tests);
    //   if (formIsValid) {
    setSteps(
      steps.map((s, index) => {
        if (index === step - 1) {
          console.log(step);
          return { ...s, isValid: true };
        } else {
          return s;
        }
      })
    );
    //   } else {
    //     setSteps(
    //       steps.map((s, index) => {
    //         if (index === step - 1) {
    //           return { ...s, isValid: false };
    //         } else {
    //           return s;
    //         }
    //       })
    //     );
    //   }
  }, []);

  const removeItem = (index) => {
    if (editMode) {
      setDeletedItems([...deletedItems, tests[index]?.id]);
    }
    setTests(tests.filter((test, i) => i != index));
    scrollToBottom();
  };
  const addItem = () => {
    if (nameIsValid) {
      const newTest = [...tests, { name, isChecked: false }];
      setTests(newTest);
      if (!editMode) {
        storePhaseData({
          tests: newTest,
        });
      }
      resetName();
      scrollToBottom();
    }
  };
  const selectItem = (item, index) => {
    if (item) {
      setEditedItem({ item, index });
      nameChangeHandler(item);
      scrollToBottom();
    }
  };
  const applyItem = () => {
    const { item, index } = editedItem;
    if (item) {
      const updatedTests = tests.map((item, i) => {
        if (index === i) {
          if (editMode) {
            return { ...item, name, isChecked: false };
          } else {
            return { name };
          }
        } else return item;
      });
      setTests(updatedTests);
      if (!editMode) {
        storePhaseData({
          tests: updatedTests,
        });
      }
      resetName();
      setEditedItem(null);
      scrollToBottom();
    }
  };
  return (
    <div className="space-y-3 flex flex-col justify-between h-fit">
      <div>
        <h1 className="font-semibold">Tests</h1>
        <p className="text-dark-gray text-xs">
          What tests need to be conducted for the phase to be confirmed complete
        </p>
      </div>
      <Input
        onChange={nameChangeHandler}
        onBlur={nameBlurHandler}
        value={name}
        // error={nameIsInValid}
        label="Test name"
        helperText={<>Test name is required</>}
        placeholder="Concrete test"
        required={true}
        name="name"
        type="text"
      />
      {editedItem !== null ? (
        <span className="flex justify-between">
          <button
            disabled={!nameIsValid}
            type="button"
            className="mt-2 disabled:text-secondary/70 hover:text-primary text-secondary flex items-center p-1 text-sm"
            onClick={() => applyItem()}
          >
            <Check2 className="mr-1 text-[15px]" />
            Update item
          </button>
          <button
            disabled={!nameIsValid}
            type="button"
            className="mt-2 disabled:text-red-400 hover:text-red-500 text-red-500/80 flex items-center p-1 text-sm"
            onClick={() => {
              setEditedItem(null);
              resetName();
            }}
          >
            <X className="mr-1 text-[15px]" />
            Cancel
          </button>
        </span>
      ) : (
        <button
          disabled={!nameIsValid}
          type="button"
          className="mt-2 disabled:text-secondary/70 hover:text-primary text-secondary flex items-center p-1 text-sm"
          onClick={addItem}
        >
          <PlusCircleFill className="mr-1 text-[15px]" />
          Add item
        </button>
      )}
      <div>
        {tests?.length !== 0 && (
          <ul className="list-disc">
            {tests.map((item, index) => (
              <li
                key={index}
                className="flex justify-between text-gray-500 text-sm"
              >
                <span className="flex items-center">
                  <>
                    <CheckCircle className="mr-2" />
                    {item.name || item}
                  </>
                </span>
                <span>
                  <IconButton
                    className="border-none hover:bg-gray-50 text-gray-400 w-6 h-6"
                    type="rounded"
                    onClick={() => selectItem(item.name || item, index)}
                  >
                    <EditIcon className="!w-4 !h-4" />
                  </IconButton>
                  <IconButton
                    className="border-none hover:bg-gray-50 text-red-500 w-6 h-6"
                    type="rounded"
                    onClick={() => removeItem(index)}
                  >
                    <DeleteIcon className="!w-4 !h-4" />
                  </IconButton>
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Tests;
