import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState, useRef, useEffect } from "react";
import { PlusLg, X } from "react-bootstrap-icons";
import Button from "../components/UI/Button";
import FileItem from "../components/UI/files/FileItem";
import FilesCard from "../components/UI/files/FilesCard";
import Input from "../components/UI/Input";
import { Tab, Tabs } from "../components/UI/Tabs";
import Toast from "../components/UI/Toast";
import useInput from "../hooks/use-input";
import { useProject } from "../store/project-context";
import FileCardSkeleton from "../components/UI/skeleton/FileCardSkeleton";
import { Warning } from "@mui/icons-material";
import noFolders from "../assets/illustrations/folders.svg";
import NewFile from "../components/Forms/NewFile";
import Confirm from "../components/UI/Confirm";
import Modal from "../components/UI/Modal";
import SearchFromList from "../components/UI/SearchFromList";
import NoProject from "../components/UI/NoProject";
import FileViewer from "react-file-viewer";
import "./fileViewer.css";
// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const GET_FOLDERS = gql`
  query MyQuery($projectId: uuid!) {
    projects_folders(
      where: { projectId: { _eq: $projectId } }
      order_by: { created_at: desc }
    ) {
      id
      name
      projectId
      created_at
      files_aggregate {
        aggregate {
          count
          sum {
            size
          }
        }
      }
      files(order_by: { created_at: desc, name: asc }) {
        id
        is_favourite
        name
        type
        url
        created_at
        size
        description
      }
    }
  }
`;

const INSERT_FOLDER = gql`
  mutation MyMutation($object: projects_folders_insert_input!) {
    insert_projects_folders_one(object: $object) {
      id
    }
  }
`;

const DELETE_FOLDER = gql`
  mutation MyMutation($id: uuid!) {
    delete_projects_folders_by_pk(id: $id) {
      id
    }
  }
`;

const UPDATE_FILE = gql`
  mutation MyMutation($id: uuid!, $is_favourite: Boolean = false) {
    update_projects_files_by_pk(
      pk_columns: { id: $id }
      _set: { is_favourite: $is_favourite }
    ) {
      is_favourite
    }
  }
`;

const UPDATE_FOLDER = gql`
  mutation MyMutation($id: uuid!, $name: String!) {
    update_projects_folders_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name }
    ) {
      id
    }
  }
`;

const DELETE_FILE = gql`
  mutation MyMutation($id: uuid!) {
    delete_projects_files_by_pk(id: $id) {
      id
    }
  }
`;

const FilesPage = () => {
  const [addFolderModalShown, setAddFolderModalShown] = useState(false);
  const [error, setError] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [editedFolder, setEditedFolder] = useState(null);
  const [editedFile, setEditedFile] = useState(null);
  const [success, setSuccess] = useState(null);
  const [newFileModalShown, setNewFileModalShown] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isConfirmDeleteFolderShown, setIsConfirmDeleteFolderShown] =
    useState(false);
  const [isConfirmDeleteFileShown, setIsConfirmDeleteFileShown] =
    useState(false);
  const [allFiles, setAllFiles] = useState([]);
  const [openedFile, setOpenedFile] = useState(null);
  const { currentProject } = useProject();
  const [
    insertFolder,
    { loading: isFolderSubmitting, error: insertFolderError },
  ] = useMutation(INSERT_FOLDER);
  const [
    deleteOneFolder,
    { loading: isFolderDeleting, error: deleteFolderError },
  ] = useMutation(DELETE_FOLDER);

  const [markFavorite, { loading: isFileUpdating, error: updateFileError }] =
    useMutation(UPDATE_FILE);

  const [
    updateFolder,
    { loading: isFolderUpdating, error: updateFolderError },
  ] = useMutation(UPDATE_FOLDER);

  const [deleteOneFile, { loading: isFileDeleting, error: deleteFileError }] =
    useMutation(DELETE_FILE);

  const {
    data: folders,
    loading: getFoldersLoading,
    error: getFoldersError,
    refetch: refetchFolders,
  } = useQuery(GET_FOLDERS, {
    variables: { projectId: currentProject?.id },
  });

  const {
    value: folderName,
    isValid: folderNameIsValid,
    inputChangeHandler: folderNameChangeHandler,
    reset: resetFolderName,
  } = useInput((value) => value.trim() !== "");
  useEffect(() => {
    if (!getFoldersLoading && !getFoldersError) {
      let files = [];
      folders.projects_folders.map((folder) => {
        files.push(...folder.files);
      });
      console.log(files);
      setAllFiles(files);
    }
  }, [folders]);
  useEffect(() => {
    setSelectedFolder(null);
  }, [currentProject]);
  const addFolderHandler = async () => {
    try {
      if (folderName !== "") {
        if (!editMode) {
          const res = await insertFolder({
            variables: {
              object: { name: folderName, projectId: currentProject?.id },
            },
          });
          if (res.data.insert_projects_folders_one?.id) {
            setAddFolderModalShown(false);
            folderNameChangeHandler("");
            refetchFolders();
            setSuccess("New folder created successfully");
          }
        } else {
          const res = await updateFolder({
            variables: {
              name: folderName,
              id: editedFolder?.id,
            },
          });
          if (res.data.update_projects_folders_by_pk?.id) {
            setAddFolderModalShown(false);
            folderNameChangeHandler("");
            refetchFolders();
            setSuccess("Folder updated successfully");
          }
        }
      } else {
        setError("Folder name cannot be empty");
      }
      resetFolderName();
    } catch (error) {
      setError(error?.message);
    }
  };
  const selectFolder = (folder) => {
    if (selectedFolder?.id === folder.id) {
      setSelectedFolder(null);
    } else {
      setSelectedFolder(folder);
    }
  };
  const editFolder = (folder) => {
    setAddFolderModalShown(true);
    setEditMode(true);
    setEditedFolder(folder);
    folderNameChangeHandler(folder.name);
  };
  const deleteFileHandler = async () => {
    if (editedFile === null) {
      return;
    }
    try {
      await deleteOneFile({ variables: { id: editedFile.id } });
      if (!deleteFileError) {
        refetch();
        setIsConfirmDeleteFileShown(false);
        setEditedFile(null);
        setSuccess("File deleted successfully");
      } else {
        setError(deleteFileError.message);
      }
    } catch (err) {
      setError(err.message);
      console.log(err);
    }
  };
  const deleteFile = async (file) => {
    setIsConfirmDeleteFileShown(true);
    setEditedFile(file);
  };
  const hideModal = () => {
    setEditMode(false);
    setEditedFolder(null);
    setNewFileModalShown(false);
    resetFolderName();
    setAddFolderModalShown(false);
  };
  const deleteFolderHandler = async () => {
    if (editedFolder === null) {
      return;
    }
    try {
      await deleteOneFolder({ variables: { id: editedFolder.id } });
      if (!deleteFolderError) {
        if (editedFolder.id === selectedFolder.id) {
          setSelectedFolder(null);
        }
        refetchFolders();
        setIsConfirmDeleteFolderShown(false);
        setEditedFolder(null);
        setSuccess("Folder deleted successfully");
      }
    } catch (err) {
      setError(err.message);
      console.log(err);
    }
  };
  const deleteFolder = (folder) => {
    setIsConfirmDeleteFolderShown(true);
    setEditedFolder(folder);
  };
  const refetch = async () => {
    const response = await refetchFolders();
    response.data.projects_folders.map((folder) => {
      if (folder.id === selectedFolder.id) {
        setSelectedFolder(folder);
      }
    });
  };
  const markFavoriteHandler = async (file) => {
    await markFavorite({
      variables: {
        id: file.id,
        is_favourite: !file.is_favourite,
      },
    });
    refetch();
    if (file.is_favourite) {
      setAllFiles(allFiles.filter((f) => f.id !== file.id));
    } else {
      setAllFiles([{ ...file, is_favourite: !file.is_favourite }, ...allFiles]);
    }
  };
  if (currentProject) {
    return (
      <div className="m-3 grid grid-cols-12">
        {addFolderModalShown && (
          <Modal
            headerIsShown={true}
            modalHeight="h-40 sm:h-48"
            isShown={true} //isShown
            hideModal={hideModal}
            modalTitle={editMode ? "Edit Folder" : "Create Folder"}
            modalBottom={
              <div className="flex justify-end mr-4">
                <Button
                  isLoading={isFolderSubmitting || isFolderUpdating}
                  onClick={addFolderHandler}
                  disabled={!folderNameIsValid}
                  className="!p-2 !min-w-[6em]"
                >
                  Save
                </Button>
              </div>
            }
          >
            <Input
              value={folderName}
              onChange={folderNameChangeHandler}
              placeholder="Folder name"
            />
            <div className="mt-3 flex w-full justify-end space-x-4"></div>
          </Modal>
        )}
        {isConfirmDeleteFolderShown && (
          <Confirm
            confirmButtonText="Delete"
            isLoading={isFolderDeleting}
            confirmTitle="Delete"
            onClick={deleteFolderHandler}
            onCancel={() => {
              setEditedFolder(null);
              setIsConfirmDeleteFolderShown(false);
            }}
          >
            {editedFolder.files_aggregate.aggregate.count > 0
              ? `Are you sure you want to delete this folder and its content?`
              : "Are you sure you want to delete this folder?"}
          </Confirm>
        )}
        {isConfirmDeleteFileShown && (
          <Confirm
            confirmButtonText="Delete"
            isLoading={isFileDeleting}
            confirmTitle="Delete"
            onClick={deleteFileHandler}
            onCancel={() => {
              setEditedFile(null);
              setIsConfirmDeleteFileShown(false);
            }}
          >
            Are you sure you want to delete this file
          </Confirm>
        )}
        <Tabs className={`col-span-12`}>
          <Tab label="All Files">
            <div>
              {!getFoldersError && (
                <div className="relative w-full flex items-center justify-between">
                  <Button
                    type="light"
                    onClick={() => {
                      setAddFolderModalShown(true);
                    }}
                    className="flex items-center !bg-gray-100 hover:!bg-gray-200 !ring-gray-200 !px-3 !py-1.5"
                  >
                    Folders <PlusLg className="ml-2" />
                  </Button>
                  <SearchFromList
                    onClick={(item) => {}}
                    list={allFiles}
                    propertiesKey="name"
                    placeholder="Search for a file"
                  />
                </div>
              )}
              <div className="mt-4 grid grid-cols-12 gap-4">
                {getFoldersLoading && !getFoldersError ? (
                  [1, 2, 3, 4].map((el, index) => (
                    <FileCardSkeleton key={index} />
                  ))
                ) : getFoldersError ? (
                  <p className="col-span-full flex justify-center items-center">
                    <Warning className="!w-4 !h-4 mr-1" />
                    {getFoldersError?.message}
                  </p>
                ) : folders.projects_folders.length === 0 ? (
                  <div className="w-full h-full col-span-full space-y-3 mt-4 flex flex-col items-center justify-center">
                    <img
                      className="w-72 mt-4 object-cover"
                      src={noFolders}
                      alt="Material details illustration"
                    />
                    <p className="text-gray-700 text-2xl mt-5">
                      No folders found
                    </p>
                    <p className="text-dark-gray text-sm">
                      Folders for this project will show up here once created
                    </p>
                  </div>
                ) : (
                  folders.projects_folders?.map((folder, index) => (
                    <FilesCard
                      onClick={() => selectFolder(folder)}
                      className={`${
                        selectedFolder?.id === folder.id
                          ? " bg-secondary/10 hover:!bg-secondary/10 border-none"
                          : ""
                      } hover:bg-secondary/5`}
                      key={index}
                      folder={folder}
                      deleteFolder={deleteFolder}
                      editFolder={editFolder}
                    />
                  ))
                )}
              </div>
              {!getFoldersError && folders?.projects_folders?.length > 0 && (
                <hr className="my-5" />
              )}
              {selectedFolder && (
                <div className="flex justify-between mt-4">
                  <h4 className="font-semibold">Files</h4>
                  <Button
                    onClick={() => {
                      setNewFileModalShown(true);
                      // setSelectedFolder(null);
                    }}
                    className="flex items-center"
                  >
                    <PlusLg className="text-xl mr-1" /> Upload
                  </Button>
                </div>
              )}
              <div className="mt-4 space-y-4">
                {!selectedFolder &&
                !getFoldersError &&
                folders?.projects_folders.length > 0 ? (
                  <p className="text-center text-sm mt-10 font-medium">
                    Select a folder
                  </p>
                ) : selectedFolder?.files?.length === 0 ? (
                  <p className="col-span-full text-sm flex justify-center items-center">
                    This folder is empty
                  </p>
                ) : (
                  selectedFolder?.files?.map((file, index) => (
                    <FileItem
                      onClick={() => {
                        document.body.style.overflowY = "hidden";
                        console.log(file);
                        setOpenedFile(file);
                      }}
                      key={index}
                      file={file}
                      markFavoriteHandler={markFavoriteHandler}
                      deleteFile={deleteFile}
                    />
                  ))
                )}
              </div>
            </div>
          </Tab>
          <Tab label="Favorites">
            <div className="mt-4 space-y-4">
              {getFoldersLoading ? (
                <p className="text-center text-sm mt-10 font-medium">
                  Loading...
                </p>
              ) : getFoldersError ? (
                <p className="col-span-full flex justify-center items-center">
                  <Warning className="!w-4 !h-4 mr-1" />
                  {getFoldersError?.message}
                </p>
              ) : allFiles.map((file) => file.is_favourite).length === 0 ? (
                <p className="col-span-full flex justify-center items-center">
                  No files found in favourites
                </p>
              ) : (
                allFiles.map(
                  (file, index) =>
                    file.is_favourite && (
                      <FileItem
                        onClick={() => {
                          document.body.style.overflowY = "hidden";
                          setOpenedFile(file);
                        }}
                        key={index}
                        file={file}
                        markFavoriteHandler={markFavoriteHandler}
                        deleteFile={deleteFile}
                      />
                    )
                )
              )}
            </div>
          </Tab>
        </Tabs>
        {newFileModalShown && (
          <NewFile
            refetch={refetch}
            setSuccess={setSuccess}
            setError={setError}
            isShown={newFileModalShown}
            hideModal={hideModal}
            selectedFolder={selectedFolder}
          />
        )}
        <Toast
          type="error"
          show={error !== null}
          callback={() => {
            setError(null);
          }}
          message={error}
        />
        <Toast
          type="success"
          show={success !== null}
          callback={() => {
            setSuccess(null);
          }}
          message={success}
        />
        {openedFile && (
          <div
            onClick={() => {
              document.body.style.overflowY = "auto";
              setOpenedFile(null);
            }}
            className="z-20 fixed top-0 left-0 w-screen h-screen bg-black/50"
          >
            <button
              onClick={() => {
                document.body.style.overflowY = "auto";
                setOpenedFile(null);
              }}
              className="text-gray-200 hover:text-white z-20 border-none absolute top-4 right-6"
            >
              <X className="!w-8 !h-8" />
            </button>
            <FileViewer
              key={openedFile.id}
              fileType={openedFile.type}
              filePath={openedFile.url}
              // errorComponent={CustomErrorComponent}
              // onError={this.onError}
            />

            {/* <DocViewer
              documents={[
                {
                  uri: openedFile.url,
                  fileType: openedFile.type,
                  fileName: openedFile.name,
                },
              ]}
              prefetchMethod="GET"
              pluginRenderers={DocViewerRenderers}
            /> */}
          </div>
        )}
      </div>
    );
  } else {
    return <NoProject />;
  }
};

export default FilesPage;
