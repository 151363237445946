import React, { useEffect, useRef } from "react";
import CheckIcon from "@mui/icons-material/Check";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DoneAllIcon from "@mui/icons-material/DoneAll";

const ChatItem = (props) => {
  const { message, currentUser } = props;
  const ref = useRef();
  let hours = new Date(message.created_at).getHours();
  let minutes = new Date(message.created_at).getMinutes();
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  const AMPM = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  const date = hours + ":" + minutes + " " + AMPM;
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);
  return (
    <div
      ref={ref}
      className={`message-item overflow-hidden break-all text-white flex flex-col mt-2 max-w-full py-1 pt-2 px-3 rounded-xl text-sm ${
        message.sender_id === currentUser.id
          ? "owner rounded-br-none bg-gradient-to-r from-primary/70 from-20% to-primary to-90% self-end"
          : "reciever rounded-bl-none bg-[#243449] bg-opacity-80 self-start"
      }`}
    >
      <span className="mb-1 font-medium whitespace-break-spaces">
        {message.message}
      </span>
      {message.img && <img src={message.img} alt="" />}
      <span className="text-xs min-w-[70px] font-medium flex items-center ml-2 self-end">
        <span className="mr-1">{date}</span>
        {message.sender_id === currentUser.id &&
          (message.isLoaded ? (
            message.seen ? (
              <DoneAllIcon className="!text-sm" />
            ) : (
              <CheckIcon className="!text-sm" />
            )
          ) : (
            <AccessTimeIcon className="!text-sm animate-spin duration-200" />
          ))}
      </span>
    </div>
  );
};
export default ChatItem;
