import React from "react";
import Chart from "react-apexcharts";
import { useAuthUser } from "react-auth-kit";
import { useNotification } from "../store/notification-context";

const Test = () => {
  // function handleImageUpload(files) {
  //   Object.values(files).map(async (file) => {
  //     const formData = new FormData();
  //     const config = {
  //       onUploadProgress: (e) => {
  //         const { loaded, total } = e;
  //         setUploadProgress(Math.round((loaded / total) * 100));
  //       },
  //     };
  //     new Compressor(file, {
  //       quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
  //       success: (compressedResult) => {
  //         formData.append("file", compressedResult);
  //         formData.append("upload_preset", "zf2nsooi");
  //         axios
  //           .post(`http://localhost:6051/upload`, formData, config)
  //           .then((res) => {
  //             setUploadProgress(0);
  //             if (editMode) {
  //               setUploadedImages(res.data.secure_url);
  //             } else {
  //               setUploadedImages((images) => [res.data.secure_url, ...images]);
  //             }
  //           })
  //           .catch((err) => {
  //             setUploadProgress(0);
  //             console.log(err.message);
  //             setError(err.message);
  //           });
  //       },
  //     });
  //   });
  // }
  return (
    <form
      action="http://localhost:6051/upload"
      method="post"
      encType="multipart/form-data"
    >
      <input type="file" accept="image/*" name="photo" />
      <input type="submit" value="upload" />
    </form>
  );
};

export default Test;
