import React, { useState } from 'react'
import Messages from '../components/Messages/Messages';
import { useLocation } from 'react-router-dom';

const MessagesPage = () => {
  const location = useLocation();
  console.log(location.state);
  const [user, setUser] = useState(location.state?.userId ? location.state?.userId : null);
  console.log(user);
  // const { userId } = location.state;
  return (
    user ? <Messages userId={user} setUser={setUser}/> : <Messages/>
  )
}

export default MessagesPage