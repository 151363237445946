import { Route, Routes, useLocation } from "react-router-dom";
import { Fragment, useEffect } from "react";
import Layout from "./components/Layout/Layout";
import { RequireAuth } from "react-auth-kit";
import LoginPage from "./pages/login";
import HomePage from "./pages/home";
import UsersPage from "./pages/users";
import ProjectsPage from "./pages/projects";
import ImagesPage from "./pages/images";
import StoragePage from "./pages/storage";
import PhasesPage from "./pages/phases";
import NewPhasesPage from "./pages/phases/new";
import BudgetPage from "./pages/budget";
import ReportPage from "./pages/report";
import ResourcesPage from "./pages/resources";
import FilesPage from "./pages/files";
import MessagesPage from "./pages/message";
import TestPage from "./pages/test";
import PageNotFound from "./pages/page-not-found";
import NewReportPage from "./pages/report/new";
import CompaniesPage from "./pages/companies";
import { useAuthUser } from "react-auth-kit";
import unauthorized from "./assets/illustrations/unauthorized.svg";

import { ProjectContextProvider } from "./store/project-context";
import { UserContextProvider } from "./store/user-context";
import { ChatContextProvider } from "./store/chat-context";
import { NotificationContextProvider } from "./store/notification-context";
import { PhaseContextProvider } from "./store/phase-context";
import SitesPage from "./pages/sites";
function App() {
  const auth = useAuthUser();
  const user = auth();
  console.log(user);
  // const location = useLocation();

  // useEffect(() => {
  //   // execute on location change
  //   console.log("Location changed!", location.pathname);
  // }, [location.pathname]);
  const Authorize = ({ children, requiredRoles }) => {
    for (let i = 0; i < requiredRoles.length; i++) {
      // if (requiredRoles[i] !== "SU" && user.company_id) {
      if (user.roles.find((role) => role.code === requiredRoles[i])) {
        return children;
      }
      // }
    }
    return (
      <div className="h-[80vh] flex flex-col justify-center items-center w-full">
        <img className="w-1/3" src={unauthorized} alt="" />
        <h1 className="font-medium text-2xl mt-10">Unauthorized</h1>
      </div>
    );
  };
  return (
    <Fragment>
      <Routes>
        <Route path="/login" element={<LoginPage />}></Route>
        <Route
          path="/"
          element={
            <RequireAuth loginPath="/login">
              <UserContextProvider>
                <ProjectContextProvider>
                  <NotificationContextProvider>
                    <ChatContextProvider>
                      <PhaseContextProvider>
                        <Layout>
                          <Authorize
                            requiredRoles={["SU", "ADM", "CNTR", "SITE_ADM"]}
                          >
                            <HomePage />
                          </Authorize>
                        </Layout>
                      </PhaseContextProvider>
                    </ChatContextProvider>
                  </NotificationContextProvider>
                </ProjectContextProvider>
              </UserContextProvider>
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/phases"
          element={
            <RequireAuth loginPath="/login">
              <UserContextProvider>
                <ProjectContextProvider>
                  <NotificationContextProvider>
                    <ChatContextProvider>
                      <PhaseContextProvider>
                        <Layout>
                          <Authorize
                            requiredRoles={["SU", "ADM", "CNTR", "SITE_ADM"]}
                          >
                            <PhasesPage />
                          </Authorize>
                        </Layout>
                      </PhaseContextProvider>
                    </ChatContextProvider>
                  </NotificationContextProvider>
                </ProjectContextProvider>
              </UserContextProvider>
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/phases/new"
          element={
            <RequireAuth loginPath="/login">
              <UserContextProvider>
                <ProjectContextProvider>
                  <NotificationContextProvider>
                    <ChatContextProvider>
                      <PhaseContextProvider>
                        <Layout>
                          <Authorize
                            requiredRoles={["SU", "ADM", "CNTR", "SITE_ADM"]}
                          >
                            <NewPhasesPage />
                          </Authorize>
                        </Layout>
                      </PhaseContextProvider>
                    </ChatContextProvider>
                  </NotificationContextProvider>
                </ProjectContextProvider>
              </UserContextProvider>
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/images"
          element={
            <RequireAuth loginPath="/login">
              <UserContextProvider>
                <ProjectContextProvider>
                  <NotificationContextProvider>
                    <ChatContextProvider>
                      <PhaseContextProvider>
                        <Layout>
                          <Authorize
                            requiredRoles={["SU", "ADM", "CNTR", "SITE_ADM"]}
                          >
                            <ImagesPage />
                          </Authorize>
                        </Layout>
                      </PhaseContextProvider>
                    </ChatContextProvider>
                  </NotificationContextProvider>
                </ProjectContextProvider>
              </UserContextProvider>
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/storage"
          element={
            <RequireAuth loginPath="/login">
              <UserContextProvider>
                <ProjectContextProvider>
                  <NotificationContextProvider>
                    <ChatContextProvider>
                      <PhaseContextProvider>
                        <Layout>
                          <Authorize
                            requiredRoles={[
                              "SU",
                              "ADM",
                              "CNTR",
                              "ST_KP",
                              "SITE_ADM",
                            ]}
                          >
                            <StoragePage />
                          </Authorize>
                        </Layout>
                      </PhaseContextProvider>
                    </ChatContextProvider>
                  </NotificationContextProvider>
                </ProjectContextProvider>
              </UserContextProvider>
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/resources"
          element={
            <RequireAuth loginPath="/login">
              <UserContextProvider>
                <ProjectContextProvider>
                  <NotificationContextProvider>
                    <ChatContextProvider>
                      <PhaseContextProvider>
                        <Layout>
                          <Authorize
                            requiredRoles={[
                              "SU",
                              "ADM",
                              "CNTR",
                              "ST_KP",
                              "SITE_ADM",
                            ]}
                          >
                            <ResourcesPage />
                          </Authorize>
                        </Layout>
                      </PhaseContextProvider>
                    </ChatContextProvider>
                  </NotificationContextProvider>
                </ProjectContextProvider>
              </UserContextProvider>
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/budget"
          element={
            <RequireAuth loginPath="/login">
              <UserContextProvider>
                <ProjectContextProvider>
                  <NotificationContextProvider>
                    <ChatContextProvider>
                      <PhaseContextProvider>
                        <Layout>
                          <Authorize
                            requiredRoles={["SU", "ADM", "CNTR", "SITE_ADM"]}
                          >
                            <BudgetPage />
                          </Authorize>
                        </Layout>
                      </PhaseContextProvider>
                    </ChatContextProvider>
                  </NotificationContextProvider>
                </ProjectContextProvider>
              </UserContextProvider>
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/report/new"
          element={
            <RequireAuth loginPath="/login">
              <UserContextProvider>
                <ProjectContextProvider>
                  <NotificationContextProvider>
                    <ChatContextProvider>
                      <PhaseContextProvider>
                        <Layout>
                          <Authorize
                            requiredRoles={["SU", "ADM", "CNTR", "SITE_ADM"]}
                          >
                            <NewReportPage />
                          </Authorize>
                        </Layout>
                      </PhaseContextProvider>
                    </ChatContextProvider>
                  </NotificationContextProvider>
                </ProjectContextProvider>
              </UserContextProvider>
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/messaging"
          element={
            <RequireAuth loginPath="/login">
              <UserContextProvider>
                <ProjectContextProvider>
                  <NotificationContextProvider>
                    <ChatContextProvider>
                      <PhaseContextProvider>
                        <Layout>
                          <Authorize
                            requiredRoles={[
                              "SU",
                              "ADM",
                              "CNTR",
                              "SITE_ADM",
                              "ST_KP",
                            ]}
                          >
                            <MessagesPage />
                          </Authorize>
                        </Layout>
                      </PhaseContextProvider>
                    </ChatContextProvider>
                  </NotificationContextProvider>
                </ProjectContextProvider>
              </UserContextProvider>
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/test"
          element={
            <UserContextProvider>
              <ProjectContextProvider>
                <ChatContextProvider>
                  <NotificationContextProvider>
                    <Layout>
                      <Authorize requiredRoles={["SU", "ADM", "CNTR"]}>
                        <TestPage />
                      </Authorize>
                    </Layout>
                  </NotificationContextProvider>
                </ChatContextProvider>
              </ProjectContextProvider>
            </UserContextProvider>
          }
        ></Route>
        <Route
          path="/report"
          element={
            <RequireAuth loginPath="/login">
              <UserContextProvider>
                <ProjectContextProvider>
                  <NotificationContextProvider>
                    <ChatContextProvider>
                      <PhaseContextProvider>
                        <Layout>
                          <Authorize
                            requiredRoles={["SU", "ADM", "CNTR", "SITE_ADM"]}
                          >
                            <ReportPage />
                          </Authorize>
                        </Layout>
                      </PhaseContextProvider>
                    </ChatContextProvider>
                  </NotificationContextProvider>
                </ProjectContextProvider>
              </UserContextProvider>
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/files"
          element={
            <RequireAuth loginPath="/login">
              <UserContextProvider>
                <ProjectContextProvider>
                  <NotificationContextProvider>
                    <ChatContextProvider>
                      <PhaseContextProvider>
                        <Layout>
                          <Authorize
                            requiredRoles={["SU", "ADM", "CNTR", "SITE_ADM"]}
                          >
                            <FilesPage />
                          </Authorize>
                        </Layout>
                      </PhaseContextProvider>
                    </ChatContextProvider>
                  </NotificationContextProvider>
                </ProjectContextProvider>
              </UserContextProvider>
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/sites"
          element={
            <RequireAuth loginPath="/login">
              <UserContextProvider>
                <ProjectContextProvider>
                  <NotificationContextProvider>
                    <ChatContextProvider>
                      <PhaseContextProvider>
                        <Layout>
                          <Authorize requiredRoles={["SU", "ADM", "SITE_ADM"]}>
                            <SitesPage />
                          </Authorize>
                        </Layout>
                      </PhaseContextProvider>
                    </ChatContextProvider>
                  </NotificationContextProvider>
                </ProjectContextProvider>
              </UserContextProvider>
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/sites/:site_id/projects"
          element={
            <RequireAuth loginPath="/login">
              <UserContextProvider>
                <ProjectContextProvider>
                  <NotificationContextProvider>
                    <ChatContextProvider>
                      <PhaseContextProvider>
                        <Layout>
                          <Authorize requiredRoles={["SU", "ADM", "SITE_ADM"]}>
                            <ProjectsPage />
                          </Authorize>
                        </Layout>
                      </PhaseContextProvider>
                    </ChatContextProvider>
                  </NotificationContextProvider>
                </ProjectContextProvider>
              </UserContextProvider>
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/users"
          element={
            <RequireAuth loginPath="/login">
              <UserContextProvider>
                <ProjectContextProvider>
                  <NotificationContextProvider>
                    <ChatContextProvider>
                      <PhaseContextProvider>
                        <Layout>
                          <Authorize requiredRoles={["SU", "ADM"]}>
                            <UsersPage />
                          </Authorize>
                        </Layout>
                      </PhaseContextProvider>
                    </ChatContextProvider>
                  </NotificationContextProvider>
                </ProjectContextProvider>
              </UserContextProvider>
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/companies"
          element={
            <RequireAuth loginPath="/login">
              <UserContextProvider>
                <ProjectContextProvider>
                  <NotificationContextProvider>
                    <ChatContextProvider>
                      <PhaseContextProvider>
                        <Layout>
                          <Authorize requiredRoles={["SU"]}>
                            <CompaniesPage />
                          </Authorize>
                        </Layout>
                      </PhaseContextProvider>
                    </ChatContextProvider>
                  </NotificationContextProvider>
                </ProjectContextProvider>
              </UserContextProvider>
            </RequireAuth>
          }
        ></Route>
        <Route
          path="*"
          element={
            <RequireAuth loginPath="/login">
              <PageNotFound />
            </RequireAuth>
          }
        ></Route>
      </Routes>
    </Fragment>
  );
}

export default App;
