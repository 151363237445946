import React from "react";

const Progress = (props) => {
  const { progress, className, color } = props;
  return (
    <div className={`flex items-center ${className}`}>
      <div
        className="w-full bg-gray-200 rounded-full dark:bg-gray-700"
      >
        <div
          className={`bg-progress/80 duration-300 font-semibold py-1 text-blue-100 text-center leading-none rounded-full ${color}`}
          style={{ width: progress > 100 ? "100%" : progress + "%" }}
        ></div>
      </div>
      <span className="text-xs text-end ml-1 w-[35px]">{progress}%</span>
    </div>
  );
};

export default Progress;
