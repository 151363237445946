import moment from "moment";
import React, { useState } from "react";
import { Download, Star, StarFill, Trash3 } from "react-bootstrap-icons";
import IconButton from "../../../components/UI/IconButton";
import { bytesToSize } from "../../../functions/extra";
import FileIcon from "../../UI/FileIcon";
const FileItem = ({ file, markFavoriteHandler, deleteFile, onClick }) => {
  const [is_favourite, set_is_favourite] = useState(file.is_favourite);
  return (
    <div
      onClick={onClick}
      className="cursor-pointer hover:bg-gray-100 flex items-center text-sm w-full rounded-lg border p-2"
    >
      <FileIcon type={file.type} />
      <div className="ml-2 flex w-full justify-between">
        <div className="w-3/5">
          <p className="font-medium">{file.name}</p>
          <p className="text-xs text-dark-gray">{bytesToSize(file.size)}</p>
        </div>
        <span className="flex w-1/5 justify-center items-center">
          {moment(file.created_at).format("L")}
        </span>
        <span className="flex w-1/5 justify-center items-center">
          {file.description}
        </span>
        <div className="w-1/5 flex justify-end">
          <a href={file.url} target="_blank" download>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="border-none"
              type="rounded"
            >
              <Download className={`text-lg`} />
            </IconButton>
          </a>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              set_is_favourite(!is_favourite);
              markFavoriteHandler(file);
            }}
            className="border-none"
            type="rounded"
          >
            {is_favourite ? (
              <StarFill className="text-lg text-[#FCC200]" />
            ) : (
              <Star className={`text-lg`} />
            )}
          </IconButton>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              deleteFile(file);
            }}
            className="border-none hover:text-dark-red"
            type="rounded"
          >
            <Trash3 />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default FileItem;
